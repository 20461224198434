<template>
    <form
        class="flex flex-col gap-2 rounded-md border border-iel-gray bg-white p-2"
        @submit.prevent="submit">
        <AppSelect
            v-model="form.service"
            name="tracking_service"
            label="Customer Tracking"
            permission="update_external"
            empty-option="None"
            :options="servicesOptions"
            :disabled="isInherited || isReadOnly" />
        <template v-if="form.service">
            <AppInput
                v-model="form.id"
                name="tracking_service_id"
                label="Customer ID"
                permission="update_external"
                required
                :disabled="isInherited || isReadOnly" />
            <AppInput
                v-model="form.lead_minutes"
                name="lead_minutes"
                label="Tracking Start Date/Time (minutes)"
                permission="update_external"
                required
                type="number"
                :min="timingDefaults[form.service]?.lead_minutes"
                :disabled="isInherited || isReadOnly" />
            <AppInput
                v-model="form.post_hours"
                name="post_hours"
                label="Tracking End Date/Time (hours)"
                permission="update_external"
                required
                type="number"
                :min="timingDefaults[form.service]?.post_hours"
                :disabled="isInherited || isReadOnly" />
        </template>
        <div>
            <AppButton
                v-if="isReadOnly"
                permission="update_external"
                @click="isReadOnly = false">
                {{ $t('Edit') }}
            </AppButton>
            <AppButton
                v-else
                type="submit"
                :disabled="form.processing">
                {{ $t('Save') }}
            </AppButton>
        </div>
    </form>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const props = defineProps({
    customer: Object,
    url: String
});

const isReadOnly = ref(true);

const isInherited = computed(() => !!props.customer.customer_group?.id);

const servicesOptions = {
    fourkites: 'FourKites',
    freight_verify: 'Freight Verify',
    project44: 'Project 44'
};

const timingDefaults = {
    freight_verify: { lead_minutes: 60, post_hours: 0 },
    project44: { lead_minutes: 90, post_hours: 48 },
    fourkites: { lead_minutes: 0, post_hours: 0 }
};

const form = useForm({
    service: undefined,
    id: null,
    lead_minutes: 0,
    post_hours: 0
});

onMounted(() => {
    setDefaults();
});

watch(
    () => form.service,
    service => {
        if (service !== props.customer.tracking_service) {
            form.lead_minutes = timingDefaults[service].lead_minutes;
            form.post_hours = timingDefaults[service].post_hours;
        }
    }
);

function setDefaults() {
    if (props.customer.tracking_service) {
        form.service = props.customer.tracking_service;
        form.id = props.customer.tracking_service_id;
        form.lead_minutes = props.customer.tracking_lead_minutes;
        form.post_hours = props.customer.tracking_post_minutes / 60;
    }
}

function submit() {
    form.transform(data => {
        return {
            tracking_service: data.service || null,
            tracking_service_id: data.id,
            tracking_lead_minutes: data.lead_minutes,
            tracking_post_minutes: data.post_hours * 60
        };
    }).patch(route(props.url, props.customer), {
        preserveScroll: true,
        onSuccess() {
            isReadOnly.value = true;
        }
    });
}
</script>
