<template>
    <CustomerContractRequestModal />
    <CustomerInsuranceRequestModal />
    <CarrierAdvanceTermsModal />
    <AppErrorNotification />
    <CarrierContactModal />
    <CarrierCreateModal />
    <CarrierTicketModal />
    <CarrierVehicleCreateModal />
    <CarrierVehicleEditModal />
    <CustomerActivationModal />
    <CustomerContactModal />
    <CustomerCreateModal />
    <CustomerCreditRequestModal />
    <CustomerGroupAddCustomerModal />
    <CustomerGroupCreateModal />
    <LoadCarrierBookModal />
    <LoadCheckcallModal />
    <LoadComcheckModal />
    <LoadStopCreateModal />
    <LoadCopyModal />
    <LoadCreateModal />
    <LoadDispatchSheetSendModal />
    <LoadFlagCreateModal />
    <LoadMacropointModal />
    <AppNoteCreateModal />
    <LoadRateConSendModal />
    <LoadTaskModal />
    <LoadTonuModal />
    <LocationContactModal />
    <LocationCreateModal />
    <RoleUsersAddModal />
    <RoleModal />
    <ReportModal />
    <LoadSnoozeModal />
    <LoadComcheckTransferModal />
</template>
