<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <div class="relative">
            <textarea
                v-bind="attributes"
                v-model="value"
                class="block w-full rounded border-iel-gray/40 px-2 py-1 placeholder:text-iel-gray sm:text-sm"
                :class="inputClass"
                :rows="rows"
                @input="handleInput"
                @blur="handleBlur"></textarea>
            <AppRequestStatus
                v-if="hasAutoSave"
                v-bind="{ recentlySuccessful, processing, error }"
                @clear="resetField" />
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: String,
    error: String,
    scope: [String, Number],
    inputClass: String,
    placeholder: String,
    id: {
        type: String,
        default: null
    },
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    url: String,
    permission: String,
    rows: {
        type: [Number, String],
        default: 4
    }
});

const emit = defineEmits(['blur', 'inputCountChanged']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const modelValue = defineModel({ type: [String, Number] });

const value = hasAutoSave ? autoSaveValue : modelValue;

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

function handleInput() {
    if (hasAutoSave) {
        clearError();
    } else {
        form?.clearErrors(props.name);
    }

    nextTick(() => {
        emit('inputCountChanged', value.value?.length ?? 0);
    });
}

function handleBlur() {
    emit('blur');
    if (hasAutoSave) submit();
}

function resetField() {
    value.value = null;
    clearError();
}
</script>
