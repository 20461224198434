export function formatDate(date) {
    if (!date) return '-';
    return new Date(date).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
}

export function formatStopDate(date) {
    if (!date) return '-';
    return (
        new Date(date).toLocaleDateString('en-us', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        }) +
        ' ' +
        new Date(date).toLocaleTimeString('en-us', {
            hour: '2-digit',
            minute: '2-digit'
        })
    );
}

export function useLoadDisabled(load) {
    return computed(() => load?.is_canceled || load?.is_tonu);
}

export function getId(template, objects) {
    if (!template) return '';
    if (!objects) return template;

    let index = 0;
    return template.replace(/\*/g, () => objects[index++].id);
}

export function formatMoney(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    }).format(amount);
}
