<template>
    <AppCard
        :id="getId('load-*-summary-details', [load])"
        title="Load Details">
        <template #title>
            <button
                class="cursor-pointer px-4 py-2 text-lg font-semibold text-white underline"
                @click="goToSetupLoadDetails">
                Load Details
            </button>
        </template>

        <div class="flex flex-col gap-4 p-4">
            <div class="flex gap-4">
                <div class="flex flex-1">
                    <div
                        :id="getId('load-*-summary-last-checkcall', [load])"
                        class="flex-1">
                        <AppHeading size="md">Last Checkcall Note</AppHeading>
                        <div v-if="lastCheckcall">
                            <div class="mt-2 rounded-t-lg bg-iel-dark-gray px-4 py-2 text-white">
                                {{ lastCheckcall.event }}
                            </div>
                            <div class="rounded-b-lg border border-t-0 border-iel-mercury px-4 py-2">
                                <div>{{ lastCheckcall.internal_note }}</div>
                                <div class="text-xs text-iel-gray">
                                    {{ lastCheckcall.user_name }} &middot;{{ lastCheckcall.created_at }}
                                </div>
                            </div>
                        </div>
                        <div v-else>No checkcalls on this load.</div>
                    </div>
                    <div>
                        <button
                            :id="getId('load-*-summary-checkcalls-shortcut', [load])"
                            class="rounded-full border border-iel-gray p-2"
                            @click="goToSetupCheckcalls">
                            <AppIcon
                                name="far fa-phone"
                                class="block size-5" />
                        </button>
                    </div>
                </div>
                <div class="flex flex-1">
                    <div
                        :id="getId('load-*-summary-primary-note', [load])"
                        class="flex-1">
                        <AppHeading size="md">Primary Note</AppHeading>
                        <div v-if="primaryNote">
                            <strong>{{ primaryNote.title }}</strong>
                            <div>{{ primaryNote.content }}</div>
                            <div class="text-xs text-iel-gray">
                                {{ primaryNote.user.name }} &middot;{{ primaryNote.created_at }}
                            </div>
                        </div>
                        <div v-else>No primary note on this load.</div>
                    </div>
                    <div>
                        <button
                            :id="getId('load-*-summary-notes-shortcut', [load])"
                            class="rounded-full border border-iel-gray p-2"
                            @click="goToSetupNotes">
                            <AppIcon
                                name="far fa-notebook"
                                class="block size-5" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex gap-4">
                <div class="flex flex-1 flex-col gap-4 rounded-md bg-iel-light-gray p-4 text-center">
                    <div class="text-2xl font-bold">{{ load.required_equipment_type ?? 'No equipment defined' }}</div>
                    <div class="text-xl font-bold">{{ equipmentLength }} {{ temperature }}</div>
                    <div class="flex w-full justify-center gap-8">
                        <div>
                            <div class="font-bold">Tractor #</div>
                            <div>{{ load.tractorNumber?.identifier ?? '-' }}</div>
                        </div>
                        <div>
                            <div class="font-bold">Trailer #</div>
                            <div>{{ load.trailerNumber?.identifier ?? '-' }}</div>
                        </div>
                        <div>
                            <div class="font-bold">Plate #</div>
                            <div>{{ load.plateNumber?.identifier ?? '-' }}</div>
                        </div>
                    </div>
                    <div class="flex w-full justify-center gap-8">
                        <div>
                            <div class="font-bold">Transportation Mode</div>
                            <div>{{ load.transportation_mode ?? '-' }}</div>
                        </div>
                        <div>
                            <div class="font-bold">Reference Information</div>
                            <div>
                                <strong>PO#</strong>
                                {{ load.po_number ?? '-' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col gap-4 rounded-md bg-iel-light-gray p-4 text-center">
                    <div>
                        <div class="text-sm font-bold">Commodity</div>
                        <div class="text-2xl font-bold">-</div>
                    </div>
                    <div class="flex justify-center gap-8">
                        <div>
                            <div class="font-bold">Pallets</div>
                            <div class="text-2xl font-bold">{{ load.total_pallets }}</div>
                        </div>
                        <div>
                            <div class="font-bold">Pieces</div>
                            <div class="text-2xl font-bold">{{ load.total_pieces }}</div>
                        </div>
                        <div>
                            <div class="font-bold">Total Load Weight</div>
                            <div class="text-2xl font-bold">
                                {{ load.total_load_weight }}
                                {{ load.total_load_weight_description }}
                            </div>
                        </div>
                    </div>
                    <div class="flex w-full justify-center gap-8">
                        <div>
                            <div class="font-bold">Value</div>
                            <div>-</div>
                        </div>
                        <div>
                            <div
                                v-for="{ description, number } in load.references"
                                :key="number">
                                <div class="font-bold">{{ description }}</div>
                                <div>{{ number }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { getId } from '@/helpers';

const props = defineProps({
    load: Object
});

const lastCheckcall = computed(() => {
    const checkcall = props.load.trackings.filter(tracking => tracking.type === 'CC').at(0);
    if (!checkcall) return null;

    return {
        ...checkcall,
        event: checkcall.stop ? `Stop #${checkcall.stop.order} - ${checkcall.event}` : checkcall.event
    };
});

const primaryNote = computed(() => {
    const note = props.load.notes.filter(note => note.is_primary).at(0);
    return note;
});

const equipmentLength = computed(() => {
    return props.load.equipment_length ? props.load.equipment_length + "'" : 'No equipment defined';
});

const temperature = computed(() => {
    return props.load.commodity_type !== 'dry' && props.load.temperature
        ? ' • ' + props.load.temperature + ' ' + props.load.temperature_units?.toUpperCase()
        : '';
});

function goToSetupLoadDetails() {
    useLoadStepsStore(props.load.id).setStep('setup');
}

function goToSetupNotes() {
    useLoadStepsStore(props.load.id).setStep('setup');
    setTimeout(() => {
        const element = document.getElementById('load-notes-detail-section');
        element.scrollIntoView({ behavior: 'smooth' });
    });
}

function goToSetupCheckcalls() {
    useLoadStepsStore(props.load.id).setStep('setup');
    setTimeout(() => {
        const element = document.getElementById('load-Checkcalls-section-button');
        element.scrollIntoView({ behavior: 'smooth' });
    });
}
</script>
