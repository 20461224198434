<template>
    <div class="flex flex-col gap-8">
        <div class="flex gap-8">
            <div>Stop {{ stop.order }}</div>
            <AppRadioGroup
                name="type"
                :url="url"
                label=""
                :scope="stop.id"
                :model-value="stop.type"
                permission="update_stops"
                inline
                :options="[
                    { value: 'pickup', label: 'Pickup' },
                    { value: 'dropoff', label: 'Dropoff' }
                ]"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                class="col-span-2 my-auto" />
        </div>
        <div class="grid grid-cols-3 gap-8">
            <div>
                <div class="flex flex-col gap-4">
                    <div>
                        <AppAutocomplete
                            v-model="stop.location.id"
                            name="location_id"
                            label="Stop Location"
                            permission="update_stops"
                            :scope="stop.id"
                            :url="url"
                            :initial-options="[{ value: stop.location.id, display: stop.location.name }]"
                            :options-url="route('locations.autocomplete')"
                            :placeholder="$t('Search for a location...')"
                            :disabled="loadDisabled"
                            :confirmation="confirmation" />
                        <Link
                            :href="route('locations.show', stop.location.id)"
                            class="group mt-1 inline-flex items-center gap-2 text-sm underline transition hover:text-iel-gray/90">
                            View {{ stop.location.name }}
                            <AppIcon
                                name="far fa-arrow-right"
                                class="h-3 w-3 transition group-hover:translate-x-2" />
                        </Link>
                    </div>
                    <AppDisplayField
                        label="Stop Address"
                        :value="fullAddress"
                        class="col-span-4"
                        :error="!stop.location.address.validated_at && 'Invalid address'" />
                </div>
                <div class="mt-8">
                    <AppHeading>References</AppHeading>
                    <div
                        v-for="(reference, i) in form.references"
                        :id="`load-${load.id}-stop-${stop.id}-references`"
                        :key="i"
                        class="flex items-end gap-2">
                        <div class="flex-1">
                            <AppInput
                                v-model="reference.number"
                                permission="update_stops"
                                name="reference_number"
                                label="Number"
                                :disabled="loadDisabled"
                                :scope="stop.id"
                                :error="form.errors[`references.${i}.number`]"
                                @blur="submitReferences(`${i}.number`)" />
                            <AppRequestStatus
                                v-if="lastSubmittedReferenceField === `${i}.number`"
                                class="!right-2 -bottom-6"
                                :processing="form.processing"
                                :recently-successful="form.recentlySuccessful"
                                :error="form.errors[`references.${i}.number`]" />
                        </div>
                        <div class="flex-1">
                            <AppSelect
                                :id="`load-${load.id}-stop-${stop.id}-reference-description-${i}`"
                                v-model="reference.description"
                                permission="update_stops"
                                name="reference_description"
                                label="Description"
                                :scope="stop.id"
                                :options="{
                                    BOL: 'BOL',
                                    'Delivery Number': 'Delivery Number',
                                    PRO: 'PRO',
                                    SAP: 'SAP',
                                    'Customer Reference': 'Customer Reference',
                                    'Freight Agreement Number': 'Freight Agreement Number',
                                    'Purchase Order Number': 'Purchase Order Number',
                                    'Release Number': 'Release Number',
                                    Other: 'Other'
                                }"
                                :error="form.errors[`references.${i}.description`]"
                                :disabled="loadDisabled"
                                @change="submitReferences(`${i}.description`)" />
                            <AppRequestStatus
                                v-if="lastSubmittedReferenceField === `${i}.description`"
                                class="!right-2 -bottom-6"
                                :processing="form.processing"
                                :recently-successful="form.recentlySuccessful"
                                :error="form.errors[`references.${i}.description`]" />
                        </div>
                        <div
                            v-if="reference.description === 'Other'"
                            class="flex-1">
                            <AppInput
                                :id="`load-${load.id}-stop-${stop.id}-other-reference-description-${i}`"
                                v-model="reference.other"
                                permission="update_stops"
                                name="reference_description_other"
                                label="Other"
                                :disabled="loadDisabled"
                                :error="form.errors[`references.${i}.other`]"
                                @blur="submitReferences(`${i}.other`)" />
                            <AppRequestStatus
                                v-if="lastSubmittedReferenceField === `${i}.other`"
                                class="!right-2 -bottom-6"
                                :processing="form.processing"
                                :recently-successful="form.recentlySuccessful"
                                :error="form.errors[`references.${i}.other`]" />
                        </div>
                        <AppButton
                            v-if="form.references.length > 1"
                            id="stop-reference-delete-button"
                            icon="far fa-trash-can"
                            variant="outline"
                            class="inline-block transition hover:bg-iel-light-gray"
                            :disabled="loadDisabled"
                            @click="removeReference(i)" />
                    </div>
                    <AppButton
                        :id="`load-${load.id}-stop-${stop.id}-add-stop-reference-button`"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        class="mt-2"
                        @click="addReference()">
                        {{ $t('New Reference') }}
                    </AppButton>
                </div>
            </div>
            <div>
                <div class="grid grid-cols-2 gap-4">
                    <div class="flex items-end gap-2">
                        <AppInput
                            v-model="stop.weight"
                            permission="update_stops"
                            :scope="stop.id"
                            name="weight"
                            :disabled="loadDisabled"
                            :url="url"
                            class="w-1/3" />
                        <AppSelect
                            name="weight_unit"
                            label="Unit"
                            permission="update_stops"
                            :scope="stop.id"
                            :model-value="stop.weight_unit"
                            :disabled="loadDisabled"
                            :url="url"
                            :options="$page.props.units.weight"
                            class="w-2/3" />
                    </div>
                    <div class="flex items-end gap-2">
                        <AppInput
                            v-model="stop.volume"
                            permission="update_stops"
                            :scope="stop.id"
                            name="volume"
                            :disabled="loadDisabled"
                            :url="url"
                            class="w-1/3" />
                        <AppSelect
                            name="volume_unit"
                            label="Unit"
                            permission="update_stops"
                            :model-value="stop.volume_unit"
                            :disabled="loadDisabled"
                            :url="url"
                            :scope="stop.id"
                            :options="$page.props.units.volume"
                            class="w-2/3" />
                    </div>
                    <div class="flex items-end gap-2">
                        <AppInput
                            :id="`load-${load.id}-stop-${stop.id}-pieces`"
                            v-model="stop.pieces"
                            permission="update_stops"
                            :name="`load-${load.id}-stop-${stop.id}-pieces`"
                            label="Pieces"
                            :disabled="loadDisabled"
                            :url="url"
                            class="w-1/3" />
                        <AppSelect
                            name="pieces_unit"
                            label="Unit"
                            :model-value="stop.pieces_unit"
                            permission="update_stops"
                            :disabled="loadDisabled"
                            :url="url"
                            :scope="stop.id"
                            :options="$page.props.units.pieces"
                            class="w-2/3" />
                    </div>
                    <div>
                        <AppInput
                            v-model="stop.pallets"
                            permission="update_stops"
                            :scope="stop.id"
                            name="pallets"
                            :disabled="loadDisabled"
                            :url="url" />
                    </div>
                    <div>
                        <AppDisplayField
                            :value="stop.appointment_number"
                            label="Appointment #" />
                    </div>
                    <div>
                        <AppDisplayField
                            label="Next Stop (Miles)"
                            :value="stop.miles_to_next_stop?.toLocaleString()"
                            :error="milesToNextStopError"
                            class="col-span-2" />
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-2">
                <AppHeading>
                    {{ $t('Carrier Responsible For:') }}
                </AppHeading>
                <AppRadioGroup
                    name="is_pallet_exchange"
                    label="Pallet Exchange"
                    :scope="stop.id"
                    :model-value="stop.is_pallet_exchange"
                    permission="update_stops"
                    :url="url"
                    :disabled="loadDisabled"
                    :confirmation="confirmation"
                    :options="[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                    ]"
                    inline />
                <AppRadioGroup
                    name="has_unloading_charges"
                    label="Unloading Charges"
                    :scope="stop.id"
                    :model-value="Number(stop.has_unloading_charges)"
                    :url="url"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :confirmation="confirmation"
                    :options="[
                        { value: 1, label: 'Yes' },
                        { value: 0, label: 'No' }
                    ]"
                    inline />
            </div>
        </div>
    </div>
</template>

<script setup>
import { usePage, useForm } from '@inertiajs/vue3';

import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    stop: Object
});

const page = usePage();

const load = computed(() => page.props.load);

const url = route('stops.update', props.stop);

const ratecon = useRateCon(load);

const confirmation = ratecon.confirmChange;

const lastSubmittedReferenceField = ref();

const milesToNextStopError = computed(() => {
    if (props.stop.miles_to_next_stop) return null;
    const { address } = props.stop.location;
    const stops = page.props.load.stops;
    if (stops[stops.length - 1].id === props.stop.id) {
        return address.validated_at ? null : 'We are unable to calculate because this stop has an invalid address.';
    } else {
        return `We are unable to calculate because ${
            address.validated_at ? 'one of the other stops' : 'this stop'
        } has an invalid address.`;
    }
});

const fullAddress = computed(() => {
    const { address } = props.stop.location;
    const address2 = address.address_2 ? `, ${address.address_2}` : '';
    return `${address.address_1}${address2}, ${address.city}, ${address.region} ${address.postal_code}`;
});

const form = useForm({
    references:
        props.stop.references?.length > 0 ? props.stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);

function submitReferences(field) {
    lastSubmittedReferenceField.value = field;
    form.patch(url, {
        preserveScroll: true
    });
}

function removeReference(i) {
    form.references.splice(i, 1);
    submitReferences();
}

function addReference() {
    form.references.push({
        number: null,
        description: null,
        other: null
    });
}

const loadDisabled = useLoadDisabled(page.props.load);
</script>
