<template>
    <AppTableSimple
        v-if="checkcalls.length > 0"
        :id="`load-${page.props.load.id}_checkcalls-table`"
        :columns="[
            { name: 'event', label: 'Event' },
            { name: 'user_name', label: 'User' },
            { name: 'source', label: 'Source' },
            { name: 'created_at', label: 'User Action Date/Time' },
            { name: 'event_happened_at', label: 'Event Date/Time' },
            { name: 'location', label: 'Location' },
            { name: 'temperature', label: 'Temp' },
            { name: 'miles_to_next_stop', label: 'Miles to Next Stop' },
            { name: 'internal_note', label: 'Internal Note' },
            { name: 'external_note', label: 'External Note' }
        ]"
        :data="checkcalls"
        th-class="bg-iel-light-gray"
        :row-class="row => ({ trashed: row.deleted_at })"
        :parent-id="page.props.load.id" />
    <div v-else>{{ $t('No Checkcalls found on this load.') }}</div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const checkcalls = computed(() =>
    page.props.load.trackings
        .filter(tracking => tracking.type === 'CC')
        .map(checkcall => ({
            ...checkcall,
            event: checkcall.stop ? `Stop #${checkcall.stop.order} - ${checkcall.event}` : checkcall.event,
            location: checkcall.location,
            miles_to_next_stop: checkcall.miles_to_next_stop?.toLocaleString()
        }))
);
</script>
