<template>
    <AppCard
        title="Information"
        theme="white"
        content-class="p-2 @container">
        <div class="grid gap-2 @lg:grid-cols-2">
            <div class="space-y-2">
                <AppInput
                    id="carrier-name"
                    v-model="carrier.name"
                    permission="update_carrier_info"
                    :url="url"
                    name="name"
                    :disabled="carrier.verification_data" />
                <AppInput
                    id="carrier-legal-name"
                    v-model="carrier.legal_name"
                    permission="update_carrier_info"
                    :url="url"
                    name="legal_name"
                    :disabled="carrier.verification_data" />

                <CarrierAddressCard class="my-2" />

                <div class="grid grid-cols-2 gap-2">
                    <AppInput
                        id="carrier-phone-number"
                        permission="update_carrier_info"
                        :url="primaryContactUrl"
                        name="phone_number"
                        :model-value="carrier.primary_contact?.phone_number"
                        :disabled="carrier.verification_data" />
                    <AppInput
                        id="carrier-fax-number"
                        permission="update_carrier_info"
                        :url="primaryContactUrl"
                        name="fax_number"
                        :model-value="carrier.primary_contact?.fax_number"
                        :disabled="carrier.verification_data" />
                </div>

                <AppInput
                    id="carrier-marketing-email"
                    v-model="carrier.marketing_email"
                    permission="update_carrier_info"
                    :url="url"
                    name="marketing_email"
                    :disabled="carrier.verification_data" />
            </div>
            <div class="space-y-2">
                <AppInput
                    id="carrier-mc-number"
                    v-model="carrier.mc_number"
                    permission="update_carrier_info"
                    :url="url"
                    name="mc_number"
                    label="MC"
                    :disabled="carrier.verification_data || carrier.canada_only" />
                <AppInput
                    id="carrier-mx-number"
                    v-model="carrier.mx_number"
                    permission="update_carrier_info"
                    :url="url"
                    name="mx_number"
                    label="MX"
                    :disabled="carrier.verification_data || carrier.canada_only" />
                <AppInput
                    id="carrier-ff-number"
                    v-model="carrier.ff_number"
                    permission="update_carrier_info"
                    :url="url"
                    name="ff_number"
                    label="FF"
                    :disabled="true" />
                <AppInput
                    id="carrier-dot-number"
                    v-model="carrier.dot_number"
                    permission="update_carrier_info"
                    :url="url"
                    name="dot_number"
                    label="DOT Number"
                    :disabled="carrier.verification_data || carrier.canada_only" />
                <AppInput
                    id="carrier-permit-number"
                    v-model="carrier.permit_number"
                    permission="update_carrier_info"
                    :url="url"
                    name="permit_number"
                    :disabled="!carrier.canada_only" />
                <AppInput
                    id="carrier-federal-id"
                    v-model="carrier.federal_id"
                    permission="update_carrier_info"
                    :url="url"
                    name="federal_id"
                    label="Federal ID"
                    :disabled="carrier.verification_data" />
                <div class="space-y-2 pt-2">
                    <AppCheckbox
                        id="carrier-canada-only"
                        v-model="carrier.canada_only"
                        permission="update_carrier_info"
                        :url="url"
                        name="canada_only"
                        label="Canada Only - Permit"
                        disabled />
                    <AppCheckbox
                        id="carrier-intrastate-only"
                        v-model="carrier.intrastate_only"
                        permission="update_carrier_info"
                        :url="url"
                        name="intrastate_only"
                        label="Intrastate Only - DOT"
                        disabled />
                    <AppCheckbox
                        id="mexico-authority"
                        v-model="carrier.mexico_authority"
                        permission="update_carrier_info"
                        :url="url"
                        name="mexico_authority"
                        label="Mexico Authority"
                        disabled />
                </div>
                <CarrierMpPartnerStatusChecker />
                <div
                    v-if="!carrier.is_active"
                    class="bottom-2 right-2 lg:absolute">
                    <AppButton
                        v-if="!carrier.is_active"
                        id="activate-button"
                        icon="far fa-truck"
                        :disabled="carrier.has_open_ticket"
                        @click="openActivationModal(carrier)">
                        {{ $t('Activate Carrier') }}
                    </AppButton>
                </div>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';

const page = usePage();

const url = route('carriers.update', [page.props.carrier]);
const primaryContactUrl = route('carriers.primary-contact.update', [page.props.carrier]);

const carrier = computed(() => page.props.carrier);

onMounted(() => {
    if (page.props.carrier.needs_activation) {
        openActivationModal(page.props.carrier);
    }
});

const form = useForm({
    name: page.props.carrier.name,
    legal_name: page.props.carrier.legal_name,
    phone: page.props.carrier.primary_contact?.phone_number,
    fax: page.props.carrier.primary_contact?.fax_number,
    contact: page.props.carrier.contact,
    marketing_email: page.props.carrier.marketing_email,
    mc_number: page.props.carrier.mc_number,
    permit_number: page.props.carrier.permit_number,
    dot_number: page.props.carrier.dot_number
});

provide('form', form);

const { open: CarrierTicketModal } = useModal('CarrierTicketModal');

const openActivationModal = carrier => {
    CarrierTicketModal({
        modalId: 'carrier-activate-modal',
        title: 'Activate Carrier',
        ticketType: 'Carrier Activation',
        carrier: carrier
    });
};
</script>
