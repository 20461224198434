<template>
    <AppModal
        id="carrier-contact-modal"
        :show="isOpen"
        :title="context.title ?? 'Create Contact'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="onConfirm">
            <AppInput
                id="new-carrier-contact-name"
                v-model="form.name"
                label="Name"
                name="new-carrier-contact-name"
                required
                maxlength="50"
                :error="form.errors.name" />
            <div class="grid grid-cols-2 gap-2">
                <AppInput
                    id="new-carrier-contact-phone"
                    v-model="form.phone_number"
                    label="Phone Number"
                    name="new-carrier-contact-phone"
                    required
                    :error="form.errors.phone_number" />
                <AppInput
                    id="new-carrier-contact-email"
                    v-model="form.email_address"
                    label="Email"
                    name="new-carrier-contact-email"
                    type="email"
                    :error="form.errors.email_address" />
            </div>
            <AppCheckbox
                v-model="form.is_dispatcher"
                name="is_dispatcher"
                label="Dispatcher" />
            <AppCheckbox
                v-model="form.is_driver"
                name="is_driver"
                label="Driver" />
            <div
                v-if="form.errors.is_driver_or_dispatcher"
                class="text-sm text-iel-red">
                {{ form.errors.is_driver_or_dispatcher }}
            </div>
            <AppErrorMessage :error="errorMessage" />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="save-carrier-contact"
                    type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';

const { isOpen, close, onClosed, context } = useModal('CarrierContactModal');

const form = useForm({
    name: null,
    phone_number: null,
    email_address: null,
    is_dispatcher: false,
    is_driver: false,
    load_id: undefined,
    load_relationship: undefined
});

const errorMessage = ref(null);

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    errorMessage.value = null;
    if (!value) return;

    if (context.value.mode === 'create') {
        form.name = context.value.query;
        form.is_dispatcher = context.value.role === 'dispatcher';
        form.is_driver = context.value.role === 'driver';
        form.load_id = context.value.load_id;
        form.load_relationship = context.value.load_relationship;
    }

    if (context.value.mode === 'edit') {
        form.name = context.value.contact.name;
        form.phone_number = context.value.contact.phone_number;
        form.email_address = context.value.contact.email_address;
        form.is_dispatcher = context.value.contact.is_dispatcher;
        form.is_driver = context.value.contact.is_driver;
        form.load_id = context.value.load_id;
        form.load_relationship = context.value.load_relationship;
    }
});

async function onConfirm() {
    errorMessage.value = null;
    try {
        if (context.value.mode === 'create') {
            await form.post(
                route('carriers.contacts.store', {
                    carrier: context.value.carrier
                }),
                {
                    preserveScroll: true,
                    onSuccess() {
                        close();
                    }
                }
            );
        }

        if (context.value.mode === 'edit') {
            await form.put(
                route('carriers.contacts.update', {
                    carrier: context.value.carrier,
                    contact: context.value.contact
                }),
                {
                    preserveScroll: true,
                    onSuccess() {
                        close();
                    }
                }
            );
        }
    } catch (error) {
        errorMessage.value = error.response?.data?.message || 'An unexpected error occurred.';
    }
}
</script>
