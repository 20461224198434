<template>
    <div class="overflow-visible">
        <form class="grid gap-2 @lg:grid-cols-2">
            <div class="space-y-2 rounded-md border border-iel-gray/40 p-1">
                <AppHeading
                    size="sm"
                    class="text-iel-dark-gray">
                    {{ $t('Auto Liability') }}
                </AppHeading>
                <AppCheckbox
                    id="additional_insured"
                    v-model="insurance.additional_insured"
                    :url="url"
                    name="additional_insured"
                    disabled />
                <AppCheckbox
                    id="auto_liability_trailer_interchange_included"
                    v-model="insurance.auto_liability_trailer_interchange_included"
                    :url="url"
                    name="auto_liability_trailer_interchange_included"
                    disabled />
                <AppDisplayField
                    label="Auto Liability Expiration Date"
                    :value="insurance.auto_liability_expiration_date" />
                <AppDisplayField
                    label="Auto Liability Amount"
                    :value="insurance.auto_liability_amount" />
                <AppDisplayField
                    label="Auto Liability Provider"
                    :value="insurance.auto_liability_provider" />
                <AppDisplayField
                    label="Auto Liability Trailer Interchange Amount"
                    :value="insurance.auto_liability_trailer_interchange_amount" />
                <AppSelectTags
                    id="auto_liability_coverage_types"
                    v-model="insurance.auto_liability_coverage_types"
                    name="auto_liability_coverage_types"
                    label="Auto Liability Coverage Types"
                    :placeholder="$t('Select...')"
                    :options="autoLiabilityTypes"
                    :url="url"
                    :disabled="true" />
            </div>
            <div class="space-y-2 rounded-md border border-iel-gray/40 p-1">
                <AppHeading
                    size="sm"
                    class="text-iel-dark-gray">
                    {{ $t('Cargo Liability') }}
                </AppHeading>
                <AppCheckbox
                    id="cargo_trailer_interchange_included"
                    v-model="insurance.cargo_trailer_interchange_included"
                    :url="url"
                    name="cargo_trailer_interchange_included"
                    disabled />
                <AppCheckbox
                    id="cargo_reefer_breakdown_coverage"
                    v-model="insurance.cargo_reefer_breakdown_coverage"
                    :url="url"
                    name="cargo_reefer_breakdown_coverage"
                    label="Cargo Reefer Breakdown Coverage"
                    disabled />
                <AppDisplayField
                    label="Cargo Expiration Date"
                    :value="insurance.cargo_expiration_date" />
                <AppDisplayField
                    label="Cargo Amount"
                    :value="insurance.cargo_amount" />
                <AppDisplayField
                    label="Cargo Provider"
                    :value="insurance.cargo_provider" />
                <AppDisplayField
                    label="Cargo Trailer Interchange Amount"
                    :value="insurance.cargo_trailer_interchange_amount" />
            </div>
        </form>
    </div>
</template>

<script setup>
const props = defineProps({
    request: Object
});

const url = route('carriers.insurance.update', [props.request.entity.id]);

const insurance = computed(() => props.request.entity.insurance ?? {});

const autoLiabilityTypes = Object.values(props.request.entity.insurance.auto_liability_coverage_types ?? {});
</script>
