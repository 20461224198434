<template>
    <AppCard
        title="Carrier Pay Details"
        content-class="p-4">
        <template #nav>
            <div class="flex gap-2">
                <AppButton
                    icon="far fa-arrow-up-right-from-square"
                    @click="comingSoon">
                    Dat Rates
                </AppButton>
                <AppButton
                    icon="far fa-arrow-up-right-from-square"
                    @click="comingSoon">
                    Greenscreen Rates
                </AppButton>
            </div>
        </template>

        <LoadCarrierBudget :load="load" />
    </AppCard>
</template>

<script setup>
defineProps({
    load: {
        type: Object,
        required: true
    }
});

function comingSoon() {
    alert('Coming Soon');
}
</script>
