<template>
    <div class="min-h-screen bg-iel-lightest-gray/80 p-8">
        <div class="mx-auto max-w-screen-md">
            <img
                src="/images/arrow-IEL.png"
                alt="Logo"
                class="mb-8 h-20" />
            <template v-if="success">
                <h1 class="text-2xl font-bold">Your updates have been received.</h1>
                <p>You will receive an email confirmation.</p>
            </template>
            <template v-else>
                <div class="overflow-hidden rounded-lg border border-iel-light-gray bg-white shadow">
                    <div class="border-b border-iel-light-gray bg-white px-6 py-5">
                        <h1 class="text-base font-semibold text-iel-darkest-gray">Advance Terms Change</h1>
                    </div>
                    <div>
                        <form
                            class="grid grid-cols-12 gap-x-2 gap-y-3 p-4"
                            @submit.prevent="submit">
                            <AppInput
                                id="name"
                                :model-value="carrier.name"
                                readonly
                                name="name"
                                type="text"
                                label="Carrier Name"
                                class="col-span-6" />

                            <AppInput
                                id="representative"
                                v-model="form.representative"
                                required
                                name="representative"
                                type="text"
                                label="Carrier representative"
                                :error="form.errors.representative"
                                class="col-span-6" />

                            <AppInput
                                id="name"
                                :model-value="carrier.mc_number"
                                readonly
                                name="mc_number"
                                type="text"
                                label="MC#"
                                class="col-span-6 mb-10" />

                            <AppInput
                                id="name"
                                :model-value="date"
                                readonly
                                name="date"
                                type="text"
                                label="Date"
                                class="col-span-6 mb-10" />

                            <div class="col-span-6 space-y-4">
                                <AppLabel
                                    :label="$t('Dispatcher')"
                                    text-classes="text-base font-semibold" />
                                <AppRadioGroup
                                    v-model="form.has_dispatcher_accessorials"
                                    name="has_dispatcher_accessorials"
                                    label="Accessorial Charges"
                                    :options="[
                                        { value: 1, label: 'Yes' },
                                        { value: 0, label: 'No' }
                                    ]"
                                    :error="form.errors.has_dispatcher_accessorials"
                                    inline />
                                <AppRadioGroup
                                    v-model="form.has_dispatcher_fuel_advance"
                                    name="has_dispatcher_fuel_advance"
                                    label="Fuel Advances"
                                    :options="[
                                        { value: 1, label: 'Yes' },
                                        { value: 0, label: 'No' }
                                    ]"
                                    :error="form.errors.has_dispatcher_fuel_advance"
                                    inline />
                            </div>

                            <div class="col-span-6 space-y-4">
                                <AppLabel
                                    :label="$t('Driver')"
                                    text-classes="text-base font-semibold" />
                                <AppRadioGroup
                                    v-model="form.has_driver_accessorials"
                                    name="has_driver_accessorials"
                                    label="Accessorial Charges"
                                    :options="[
                                        { value: 1, label: 'Yes' },
                                        { value: 0, label: 'No' }
                                    ]"
                                    :error="form.errors.has_driver_accessorials"
                                    inline />
                                <AppRadioGroup
                                    v-model="form.has_driver_fuel_advance"
                                    name="has_driver_fuel_advance"
                                    label="Fuel Advances"
                                    :options="[
                                        { value: 1, label: 'Yes' },
                                        { value: 0, label: 'No' }
                                    ]"
                                    :error="form.errors.has_driver_fuel_advance"
                                    inline />
                            </div>

                            <div class="col-span-12">
                                <AppButton
                                    type="submit"
                                    class="ml-auto mt-4">
                                    Save Changes
                                </AppButton>
                            </div>
                        </form>
                    </div>
                </div>
            </template>
            <div class="border-t border-iel-lightest-gray pt-6 text-center text-sm text-iel-dark-gray">
                Copyright © 2007-{{ new Date().getFullYear() }} Integrity Express Logistics
            </div>
        </div>
    </div>
</template>

<script setup>
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import { useForm } from '@inertiajs/vue3';

defineOptions({ layout: DefaultLayout });

const props = defineProps({
    carrier: Object,
    ticket: Object,
    date: String,
    ticket_id: Number
});

const form = useForm({
    ticket_id: props.ticket_id,
    representative: '',
    has_driver_fuel_advance: Number(props.carrier.has_driver_fuel_advance),
    has_driver_accessorials: Number(props.carrier.has_driver_accessorials),
    has_dispatcher_fuel_advance: Number(props.carrier.has_dispatcher_fuel_advance),
    has_dispatcher_accessorials: Number(props.carrier.has_dispatcher_accessorials)
});

const success = ref(false);

function submit() {
    form.post(route('carriers.advance-terms-change.store', props.carrier.id), {
        onSuccess() {
            success.value = true;
        }
    });
}
</script>
