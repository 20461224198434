<template>
    <div>
        <div class="flex justify-end pr-1">
            <button
                v-if="regular"
                :id="`load-card-${load.id}-actions-create-note-button-secondary`"
                class="mb-2 inline-block rounded bg-iel-blue px-2 py-0.5 text-left text-xs font-semibold leading-5 text-iel-light-gray transition hover:bg-iel-light-gray hover:text-iel-blue focus:bg-iel-light-gray focus:text-iel-blue focus:outline-none"
                type="button"
                @click="createNote">
                <AppIcon
                    name="fal fa-pen"
                    class="mr-1 text-sm" />
                {{ $t('Create Note') }}
            </button>
        </div>
        <ul
            v-if="load.notes.length > 0"
            role="list"
            class="px-0.5">
            <li
                v-for="note in notes"
                :id="`note-${note.id}`"
                :key="note.id"
                class="mb-2.5">
                <AppMessage
                    :user="note.user_name"
                    :title="note.title"
                    :body="note.content"
                    :created-at="note.created_at"
                    :icon="note.icon" />
            </li>
        </ul>
        <div
            v-else
            class="mb-4 text-center">
            {{ $t('No notes found on this load.') }}
        </div>
    </div>
</template>

<script setup>
import { loadboard } from '@/stores';

const props = defineProps({
    load: Object
});

const regular = computed(() => loadboard.value.layout === 'regular');

function createNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: props.load }
    });
}

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' },
    task_snooze: { icon: 'fas fa-alarm-snooze' }
};

const notes = computed(() =>
    props.load.notes.map(note => {
        const noteType = noteTypes[note.type] ?? {};

        return {
            ...note,
            icon: note.type ? noteType.icon : null,
            title: note.title ? note.title : noteType.title
        };
    })
);
</script>
