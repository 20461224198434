<template>
    <AppCard
        title="Load History"
        theme="light_gray"
        content-class="p-2">
        <div class="min-w-full overflow-auto py-2">
            <table class="w-full whitespace-nowrap">
                <thead>
                    <tr class="mb-2 border-b border-iel-gray text-left text-iel-gray">
                        <th class="px-2 py-1">{{ $t('Load #') }}</th>
                        <th class="px-2 py-1">{{ $t('Customer Name') }}</th>
                        <th class="px-2 py-1">{{ $t('Date') }}</th>
                        <th class="px-2 py-1">{{ $t('Receive') }}</th>
                        <th class="px-2 py-1">{{ $t('Pick Up') }}</th>
                    </tr>
                </thead>

                <tr
                    v-for="load in loads"
                    :key="load"
                    class="border-b border-iel-gray">
                    <td class="px-2 py-1">{{ load.number }}</td>
                    <td class="px-2 py-1">{{ load.customer }}</td>
                    <td class="px-2 py-1">{{ load.date }}</td>
                    <td class="px-2 py-1"><AppCheckbox name="receive" /></td>
                    <td class="px-2 py-1"><AppCheckbox name="pickup" /></td>
                </tr>
            </table>
        </div>
    </AppCard>
</template>

<script setup>
const loads = computed(() => []);
</script>
