<template>
    <AppHeader
        title="Users"
        :icon-component="IconUserRole" />

    <form
        class="grid gap-4 lg:grid-cols-2"
        @submit.prevent="submit">
        <AppCard
            :title="user.name"
            theme="gray"
            overflow-class="overflow-visible"
            content-class="p-2">
            <template #titleMeta>
                <div class="absolute right-0">
                    <AppButton
                        class="flex items-center text-lg text-white"
                        icon="far fa-circle-xmark"
                        variant="invisible"
                        icon-size="lg"
                        @click="exitProfile" />
                </div>
            </template>
            <p class="mb-2 font-bold">Assigned Roles</p>
            <p
                v-if="!page.props.user.roles.length"
                class="text-sm italic text-iel-gray">
                No roles assigned.
            </p>
            <ul class="mb-4 flex flex-wrap gap-2">
                <li
                    v-for="role in page.props.user.roles"
                    :key="role.id"
                    class="flex rounded-md border border-iel-dark-green bg-iel-lightest-green p-1 text-xs leading-none text-iel-dark-green">
                    {{ role.name }}
                    <button
                        type="button"
                        class="ml-1"
                        @click.prevent="removeRole(role)">
                        <AppIcon
                            name="far fa-xmark"
                            class="ml-2" />
                    </button>
                </li>
            </ul>

            <div class="grid grid-cols-12">
                <AppSelectTags
                    id="user-role-selection"
                    v-model="roles"
                    name="roles"
                    label="Roles"
                    placeholder="Select roles"
                    :options="roleOptions"
                    class="col-span-11" />
            </div>
            <div class="flex w-full justify-end gap-2">
                <AppButton
                    id="cancel-save-roles"
                    class="ml-auto mt-6"
                    type="button"
                    @click="clearSelection">
                    Cancel
                </AppButton>
                <AppButton
                    id="save-roles"
                    class="mt-6"
                    type="submit">
                    Save
                </AppButton>
            </div>
        </AppCard>
        <AppCard
            :title="$t('Information')"
            theme="gray"
            overflow-class="overflow-visible">
            <div class="grid grid-cols-2 gap-4">
                <AppDisplayField
                    label="Manager"
                    :value="user.manager_name" />
                <AppDisplayField
                    label="Location"
                    :value="user.office_location" />
            </div>
        </AppCard>
    </form>
</template>

<script setup>
import IconUserRole from '/resources/img/icon-user-role.svg';
import { useForm, usePage } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

defineProps({
    user: Object
});

const page = usePage();

const rolesList = computed(() => page.props.roles);

const form = useForm({
    roles: []
});

const roles = ref([]);

provide('form', form);

const roleOptions = computed(() => {
    return rolesList.value.map(role => ({
        value: role.id,
        label: role.name
    }));
});

function clearSelection() {
    roles.value = [];
}

function submit() {
    form.roles = roles.value.map(role => role.value);

    form.post(route('users.roles.store', page.props.user.id), {
        preserveScroll: true,
        onSuccess() {
            roles.value = [];
        }
    });
}

function removeRole(role) {
    if (confirm(`Are you sure you would like to remove this role from "${page.props.user.name}"?`)) {
        form.delete(
            route('users.roles.destroy', {
                user: page.props.user.id,
                role: role.id
            }),
            {
                preserveScroll: true
            }
        );
    }
}

function exitProfile() {
    if (roles.value.length && !confirm(t('User Profile has unsaved changes; would you like to proceed?'))) {
        return;
    }

    window.history.back();

    setTimeout(() => {
        router.visit(route('users.index'));
    }, 200);
}
</script>
