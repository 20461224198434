<template>
    <div
        v-if="showMap"
        :id="getId('load-*-map', [load])"
        style="height: 300px; width: 100%" />
</template>

<script setup>
import { onMounted } from 'vue';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { getId } from '@/helpers';

const props = defineProps({
    load: Object
});

TrimbleMaps.APIKey = import.meta.env.VITE_TRIMBLE_MAPS_API_KEY;

const showMap = ref(TrimbleMaps.APIKey && props.load.planned_route_coordinates && props.load.actual_route_coordinates);

onMounted(() => {
    if (showMap.value) renderMap();
});

const createRoute = (id, color, coordinates) =>
    new TrimbleMaps.Route({
        routeId: id,
        routeColor: color,
        showStops: false,
        stops: coordinates.map(coord => new TrimbleMaps.LngLat(coord.longitude, coord.latitude))
    });

const createGeoJSON = coordinates => ({
    type: 'FeatureCollection',
    features: coordinates.map((coord, index) => ({
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [coord.longitude, coord.latitude] },
        properties: { stopIndex: index + 1 }
    }))
});

function renderMap() {
    const myMap = new TrimbleMaps.Map({
        container: `load-${props.load.id}-map`,
        center: new TrimbleMaps.LngLat(-96, 35),
        zoom: 3
    });

    const plannedRoute = createRoute('plannedRoute', 'blue', props.load.planned_route_coordinates);
    const actualRoute = createRoute('actualRoute', 'green', props.load.actual_route_coordinates);
    const plannedRouteGeoJSON = createGeoJSON(props.load.planned_route_coordinates);
    const actualRouteGeoJSON = createGeoJSON(props.load.actual_route_coordinates);

    myMap.on('load', () => {
        [plannedRoute, actualRoute].forEach(route => route.addTo(myMap));

        [
            { id: 'planned', data: plannedRouteGeoJSON },
            { id: 'actual', data: actualRouteGeoJSON }
        ].forEach(({ id, data }) => {
            myMap.addSource(`${id}RouteSource`, { type: 'geojson', data });
            myMap.addLayer({
                id: `${id}-stops-layer`,
                type: 'symbol',
                source: `${id}RouteSource`,
                layout: {
                    'icon-image': ['concat', ['get', 'stopIndex'], `-fill-${id === 'planned' ? 'blue' : 'green'}`]
                }
            });
        });
    });
}
</script>
