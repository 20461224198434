<template>
    <div v-if="load.carrier">
        <div
            :key="load.carrier.id"
            class="grid gap-3">
            <LoadLegacyCarrierPayTable
                id="load-detail-carrier-pay-rates"
                class="max-w-screen-xl"
                :load="load.value"
                v-bind="{
                    loadId: load.id,
                    rates: load.carrierLineItems,
                    line_item_type: 'carrier'
                }" />
            <LoadLegacyCarrierPayTotal
                id="load-detail-carrier-pay-total"
                class="max-w-screen-xl"
                :load="load" />
        </div>
    </div>
    <div v-else>
        {{ $t('A carrier has not yet been booked for this load.') }}
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const load = computed(() => page.props.load);
</script>
