<template>
    <li
        :id="`load-card-${load.id}`"
        class="flex flex-col gap-2 rounded-lg bg-white shadow xl:flex-row">
        <AppCard
            :theme="theme"
            stacked
            class="grow !border-none !shadow-none"
            content-class="py-1 px-2">
            <template #title>
                <Link
                    :href="route('loads.show', load.id)"
                    class="font-semibold text-white">
                    {{ load.customer?.name }} #{{ load.po_number }}
                </Link>
            </template>
            <template #nav>
                <div class="flex flex-row-reverse items-center space-x-2">
                    <div
                        v-if="pastDueCount > 0 || warningCount > 0"
                        class="group mx-3">
                        <AppWarningIcon size="md" />
                        <span
                            class="invisible absolute right-72 z-10 w-36 text-pretty rounded-md bg-iel-blue p-1 text-white group-hover:visible">
                            {{ pastDueOrWarningTooltipText }}
                        </span>
                    </div>
                    <div class="gap-1 text-sm text-white underline md:flex">
                        <span>{{ load.carrier?.name }}</span>
                        <a href="tel:{{ load.driver?.phone_number }}">
                            <span>{{ load.driver?.name }}</span>
                            <span>{{ load.driver?.phone_number }}</span>
                        </a>
                        <a href="tel:{{ load.driver2?.phone_number }}">
                            <span>{{ load.driver2?.name }}</span>
                            <span>{{ load.driver2?.phone_number }}</span>
                        </a>
                    </div>
                </div>
            </template>
            <div class="grid items-center gap-4 border-b pb-4 xl:grid-cols-2 xl:border-none">
                <LoadCardProgressBar
                    :load="load"
                    class="" />

                <div class="items-start justify-between 2xl:flex 2xl:items-center">
                    <div class="flex items-center gap-1 text-sm font-medium text-iel-blue">
                        <p class="flex items-center gap-1 xl:text-xs 2xl:text-sm">
                            <span v-if="load.lane.start">{{ load.lane.start.city }}, {{ load.lane.start.region }}</span>
                            <AppIcon
                                v-if="load.lane.start && load.lane.end"
                                name="far fa-arrow-right"
                                class="mx-2" />
                            <span v-if="load.lane.end">{{ load.lane.end.city }}, {{ load.lane.end.region }}</span>
                        </p>
                        <p class="xl:text-xs 2xl:text-sm">
                            {{ '' }}/{{ load.trip_miles }} {{ $t('Mi.') }} {{ $t('ETA') }}: {{ '' }}
                        </p>
                    </div>
                    <div class="xl:grid xl:grid-cols-2 xl:text-xs 2xl:grid-cols-1 2xl:text-sm">
                        <p>
                            {{ $t('Pick Up') }}:
                            <span class="font-medium">{{ load.stops?.[0]?.stop_window_begins_at }}</span>
                        </p>
                        <p>
                            {{ $t('Delivery') }}:
                            <span class="font-medium">
                                {{ load.stops?.[load.stops.length - 1]?.stop_window_begins_at }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </AppCard>
        <div
            class="h-20 overflow-y-auto py-3 xl:relative xl:w-[31rem]"
            tabindex="0">
            <LoadCardNotes
                class="xl:absolute xl:w-full"
                :load="load" />
        </div>
        <div
            class="flex justify-between rounded-bl-lg rounded-br-lg bg-iel-gray p-2 text-white xl:h-full xl:flex-col xl:rounded-bl-none xl:rounded-tr-lg">
            <LoadCardActionMenu :load="load" />
            <Link :href="route('loads.show', load.id)">
                <AppIcon
                    name="far fa-arrows-maximize"
                    class="h-5 w-5" />
            </Link>
        </div>
    </li>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const warningCount = computed(() => {
    const now = new Date();
    return props.load.tasks.filter(
        task =>
            task.due_at &&
            !task.is_complete &&
            task.warning_minutes !== 0 &&
            now >= new Date(new Date(task.due_at).getTime() - task.warning_minutes * 60000) &&
            now < new Date(task.due_at)
    ).length;
});

const pastDueCount = computed(() => {
    const now = new Date();
    return props.load.tasks.filter(task => task.due_at && !task.is_complete && now >= new Date(task.due_at)).length;
});

const pastDueOrWarningTooltipText = computed(() => {
    return `${pastDueCount.value > 0 ? `${pastDueCount.value} overdue task(s)` : ''}${pastDueCount.value > 0 && warningCount.value > 0 ? ', ' : ''}${warningCount.value > 0 ? `${warningCount.value} task(s) due soon` : ''}`;
});

const theme = computed(() => (pastDueCount.value > 0 ? 'past_due' : warningCount.value > 0 ? 'warning' : 'blue'));
</script>
