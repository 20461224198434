<template>
    <AppModal
        id="customer-contract-request-modal"
        :show="isOpen"
        :title="context.title ?? 'Customer Contract Request'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <AppInput
                id="customer-name"
                v-model="form.name"
                label="Customer Name"
                name="customer-name"
                required />
            <div class="grid grid-cols-2 gap-2 gap-x-2 xl:grid">
                <div class="col-span-2 space-y-1">
                    <AppAutocomplete
                        v-model="form.address"
                        class="xl:col-span-3"
                        :name="`customer-${customerId}-address-1`"
                        label="Address 1"
                        :options-url="route('addresses.autocomplete')"
                        :placeholder="$t('Search for an address...')"
                        :initial-value="form.address.address_1"
                        required />
                    <AppInput
                        :id="`customer-${customerId}-address-2`"
                        v-model="form.address.address_2"
                        class="xl:col-span-3"
                        :name="`customer-${customerId}-address-2`"
                        label="Address 2" />
                </div>
                <div class="col-span-2 grid gap-2 md:grid-cols-2">
                    <AppInput
                        :id="`customer-${customerId}-city`"
                        v-model="form.address.city"
                        class="xl:col-span-1"
                        :name="`customer-${customerId}-city`"
                        label="City"
                        required />
                    <AppInput
                        :id="`customer-${customerId}-region`"
                        v-model="form.address.region"
                        class="xl:col-span-1"
                        :name="`customer-${customerId}-region`"
                        label="State/Province"
                        required />
                    <AppInput
                        :id="`customer-${customerId}-postal-code`"
                        v-model="form.address.postal_code"
                        class="xl:col-span-1"
                        name="address.postal_code"
                        label="Postal Code"
                        required />
                    <AppSelect
                        :id="`customer-${customerId}-country`"
                        v-model="form.address.country"
                        class="xl:col-span-1"
                        :name="`customer-${customerId}-country`"
                        label="Country"
                        required
                        :options="{ CA: 'CA', MX: 'MX', US: 'US' }" />
                </div>
            </div>
            <AppTextarea
                id="special-instructions"
                v-model="form.special_instructions"
                label="Special Instructions"
                name="special-instructions" />
            <AppLabel
                :label="$t('Upload Contract Request')"
                class="-mb-3"
                required />
            <AppDocsDragDrop
                id="customer-contract-request-document"
                v-model="pending"
                class="mt-2"
                :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
            <div
                v-if="pending.length > 0"
                class="grid grid-cols-2 pt-2">
                <div
                    v-for="file in pending"
                    :key="file.name">
                    {{ file.name }}
                    <AppButton
                        id="customer-contract-request-document-remove"
                        variant="outline"
                        class="w-fit grid-cols-1 place-self-start"
                        icon="fal fa-trash-can"
                        @click="removePending(index)">
                        <span class="sr-only">Remove {{ file.name }}</span>
                    </AppButton>
                </div>
            </div>
            <div v-if="form.hasErrors">
                <ul>
                    <li
                        v-for="(message, index) in form.errors"
                        :key="index"
                        class="text-iel-red">
                        {{ message }}
                    </li>
                </ul>
            </div>
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerContractRequestModal');

const form = useForm({
    type: 'Customer Contract',
    name: null,
    address: {
        address_1: null,
        address_2: null,
        city: null,
        region: null,
        postal_code: null,
        country: null
    },
    special_instructions: null,
    documents: null
});

let customerId;

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();

    customerId = context.value.customer.id;
    pending.value = [];
    if (!value) return;
    form.name = context.value.customer.name;
    form.address = context.value.address || {};
    form.special_instructions = null;
});

async function handleSubmit() {
    form.documents = pending.value;
    form.post(
        route('customers.customerTicket.store', {
            customer: customerId
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}

const pending = ref([]);

function removePending(i) {
    pending.value.splice(i, 1);
}
</script>
