<template>
    <div :class="active ? 'block' : 'hidden'">
        <slot />
    </div>
</template>

<script setup>
const props = defineProps({
    active: Boolean,
    title: String,
    icon: String,
    badge: [String, Number]
});

const tabs = inject('tabs');
const activeTab = inject('activeTab');

const badgeLabel = computed(() => props.badge);

onMounted(() => {
    tabs.value.push({ title: props.title, icon: props.icon, badge: props.badge });
    if (props.active) {
        activeTab.value = props.title;
    }
});

watch(badgeLabel, newLabel => {
    const tab = tabs.value.find(tab => tab.title === props.title);
    tab.badge = newLabel;
});

const active = computed(() => activeTab.value === props.title);
</script>
