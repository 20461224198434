<template>
    <tr>
        <td class="md:w-18">
            <AppInput
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-quantity`"
                :scope="lineItem.id"
                :model-value="lineItem.quantity"
                name="quantity"
                placeholder="Quantity"
                type="number"
                :min="0"
                class="hide-pay-rate-label"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td class="md:w-18">
            <AppInput
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-amount`"
                :scope="lineItem.id"
                :model-value="lineItem.amount"
                name="amount"
                placeholder="Amount"
                type="number"
                :min="0"
                class="hide-pay-rate-label"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td>
            <AppSelect
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-type`"
                :scope="lineItem.id"
                :model-value="lineItem.line_type"
                name="line_type"
                required
                placeholder="Type"
                :options="lineItem.bill_type !== 'accessorial' ? rateTypes : accessorialRatesOptions"
                class="hide-pay-rate-label"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td>
            <AppInput
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-note`"
                :scope="lineItem.id"
                :model-value="lineItem.note"
                name="note"
                placeholder="Note"
                type="text"
                class="hide-pay-rate-label"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td v-if="lineItem.bill_type !== 'customer'">
            <AppSelect
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-location`"
                :scope="lineItem.id"
                :model-value="lineItem.location_id"
                name="location_id"
                required
                placeholder="Location"
                :options="locations"
                class="hide-pay-rate-label"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td
            v-if="lineItem.bill_type !== 'customer'"
            class="md:w-20">
            <AppCheckbox
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-bill`"
                :scope="lineItem.id"
                :model-value="lineItem.bill_to_customer"
                :name="'bill_to_customer'"
                label=""
                class="flex items-center justify-center pl-3 lg:col-span-1"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td
            v-if="lineItem.bill_type !== 'customer'"
            class="md:w-20">
            <AppCheckbox
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-pay-carrier`"
                :scope="lineItem.id"
                :model-value="lineItem.pay_carrier"
                :name="'pay_carrier'"
                label=""
                class="flex items-center justify-center pl-3 lg:col-span-1"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td
            v-if="lineItem.bill_type !== 'customer'"
            class="md:w-20">
            <AppCheckbox
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-receipt-required`"
                :scope="lineItem.id"
                :model-value="lineItem.receipt_required"
                :name="'receipt_required'"
                label=""
                class="flex items-center justify-center pl-3 lg:col-span-1"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </td>
        <td>
            <div class="flex items-center">
                <div class="mr-3 whitespace-nowrap text-sm md:text-right">
                    <div class="text-iel-dark-gray md:hidden">Total</div>
                    $ {{ lineItem.total }}
                </div>
                <AppButton
                    :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-remove`"
                    variant="outline"
                    icon="far fa-trash-can"
                    type="button"
                    :disabled="isDisabled"
                    :title="unauthorized ? $t('Unauthorized') : ''"
                    @click="removeRate(lineItem.id)" />
            </div>
        </td>

        <td v-if="lineItem.bill_type === 'accessorial'">
            <AppSelect
                v-if="!lineItem.document_id"
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-document`"
                :scope="lineItem.id"
                :model-value="lineItem.document_id"
                name="document_id"
                class="pl-2"
                :url="url"
                :disabled="isDisabled"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation">
                <option
                    :value="null"
                    disabled
                    selected>
                    {{ $t('Select Paperwork') }}
                </option>
                <option
                    v-for="document in documents"
                    :key="document.id"
                    :value="document.id">
                    {{ document.file_name }} ({{ document.labels.join(', ') }})
                </option>
            </AppSelect>
            <div
                v-else
                class="flex gap-2 pl-2">
                <span class="font-bold">
                    {{ fileName }}
                </span>
                <AppButton
                    class="flex items-center text-sm text-iel-dark-red"
                    type="button"
                    title="Remove document"
                    :disabled="isDisabled"
                    @click="removeDocument(lineItem)">
                    <AppIcon
                        name="far fa-trash-can"
                        class="mr-1 h-4 w-4" />
                    {{ $t('Remove') }}
                </AppButton>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { usePage, router } from '@inertiajs/vue3';
import { fromPairs, zipObject, values } from 'lodash-es';
import { accessorialCharges } from '@/data.js';
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    loadId: {
        type: Number,
        required: true
    },
    lineItem: {
        type: Object,
        default: () => {}
    },
    permission: String
});

const page = usePage();
const load = computed(() => page.props.load);

const url = route('loads.billing.update', { load: props.loadId, lineItem: props.lineItem });

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

const rateTypes = zipObject(values(page.props.options.rate_types), values(page.props.options.rate_types));

const accessorialRatesOptions = zipObject(accessorialCharges, accessorialCharges);

const locations = computed(() => fromPairs(page.props.load.stops.map(stop => [stop.location.id, stop.location.name])));

const documents = computed(() => page.props.load.documents);

const fileName = computed(() => documents.value.find(x => x.id === props.lineItem.document_id).file_name);

function removeDocument(lineItem) {
    router.patch(
        route('loads.billing.update', [props.loadId, lineItem]),
        { document_id: null },
        { preserveScroll: true, only: ['load'] }
    );
}

function removeRate(lineItem) {
    router.delete(route('loads.billing.destroy', [props.loadId, lineItem]), { preserveScroll: true, only: ['load'] });
}

const loadDisabled = useLoadDisabled(page.props.load);

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));

const isDisabled = computed(
    () => loadDisabled.value || unauthorized.value || Boolean(load.value.billingDetail?.invoiced_at)
);
</script>

<style>
@screen lg {
    .hide-pay-rate-label label {
        display: none;
    }
}
</style>
