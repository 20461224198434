<template>
    <AppModal
        id="transfer-comcheck-modal"
        :show="isOpen"
        :title="$t(context.title ?? 'Transfer Comcheck')"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="precheck(context.advance)">
            <p class="font-medium">What load PO would you like to transfer the Comcheck to?</p>
            <AppInput
                v-model="form.po_number"
                name="po_number"
                label="PO Number"
                required />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">{{ $t('Submit') }}</AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm, router } from '@inertiajs/vue3';
import Swal from 'sweetalert2';
import axios from 'axios';

const { isOpen, close, onClosed, context } = useModal('LoadComcheckTransferModal');

const form = useForm({
    po_number: null
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();

    if (context.value.mode === 'transfer') {
        form.po_number = context.value.comcheck.po_number;
    }
});

function precheck(advance) {
    axios
        .get(route('comcheck.transferPrecheck', { advance: advance }), { params: { po_number: form.po_number } })
        .then(response => {
            if (response.data.success) {
                submit(advance);
            } else {
                Swal.fire({
                    title: 'Missing Comcheck Setting',
                    text: response.data.message,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, transfer it!',
                    cancelButtonText: 'No, cancel'
                }).then(result => {
                    if (result.isConfirmed) {
                        submit(advance);
                    }
                });
            }
        });
}

function submit(advance) {
    form.patch(route('comcheck.transfer', { advance: advance }), {
        preserveScroll: true,
        onError(error) {
            form.setError(error);
        },
        onSuccess() {
            close();
            router.reload({ only: ['load'] });
        }
    });
}
</script>
