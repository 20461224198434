<template>
    <div
        id="load-claims-flag-section"
        class="grid gap-4 xl:grid-cols-5">
        <div class="md:grid md:h-1/2 md:grid-cols-2 md:gap-2 xl:col-span-3 2xl:grid-cols-4">
            <div>
                <AppAutocomplete
                    :model-value="load.claim.user ? load.claim.user.id : null"
                    name="user_id"
                    :label="$t('Assignee')"
                    :options-url="route('users.autocomplete')"
                    :placeholder="$t('Search for an assignee...')"
                    :initial-options="
                        load.claim.user
                            ? [
                                  {
                                      value: load.claim.user.id,
                                      display: load.claim.user.name
                                  }
                              ]
                            : []
                    "
                    :url="url" />
                <div
                    v-if="!load.claim.user?.id"
                    class="pt-2">
                    <AppButton
                        v-if="!load.claim.completed_at"
                        :id="`report-${load.claim.user?.id}-assign-button`"
                        variant="primary"
                        size="sm"
                        class="cursor-pointer"
                        :disabled="loadDisabled"
                        @click="assign()">
                        {{ $t('Assign To Me') }}
                    </AppButton>
                </div>
            </div>
            <AppSelect
                id="claim-type"
                name="claim_type"
                :label="$t('Claim Type')"
                :model-value="load.claim.claim_type"
                :url="url"
                :options="claimTypes"
                :disabled="loadDisabled" />
            <AppInput
                v-model="load.claim.amount"
                name="amount"
                type="number"
                :min="0"
                step=".01"
                :label="$t('Amount')"
                :url="url"
                :disabled="loadDisabled" />
            <AppSelectTags
                id="required-load_documents"
                name="required_load_docs"
                :label="$t('Required Load Docs')"
                :model-value="load.claim.required_load_docs"
                :url="url"
                :options="claimLoadTypes"
                :disabled="loadDisabled" />
            <AppSelectTags
                id="required-customer-documents"
                name="required_customer_docs"
                :label="$t('Required Customer Docs')"
                :model-value="load.claim.required_customer_docs"
                :url="url"
                :options="claimCustomerTypes"
                :disabled="loadDisabled" />
            <AppDateTime
                v-model:datetime="form.follow_up_at"
                v-model:timezone="form.follow_up_at_timezone"
                :field-names="{ datetime: 'follow_up_at', timezone: 'follow_up_at_timezone' }"
                name="follow_up"
                :label="$t('Follow Up')"
                :url="url"
                class="col-span-2"
                :disabled="loadDisabled" />
            <AppSelect
                id="resolution"
                name="resolution"
                :label="$t('Resolution')"
                :model-value="page.props.load.claim.resolution"
                :url="url"
                :options="{ Resolved: 'Resolved' }"
                :disabled="loadDisabled" />

            <div
                v-if="page.props.load.claim.notes.length > 0"
                class="col-span-4 mt-4">
                <AppLabel
                    label="Claim Notes"
                    class="mb-2" />
                <ul
                    role="list"
                    class="max-h-[12.2rem] overflow-y-auto border p-2">
                    <li
                        v-for="note in page.props.load.claim.notes"
                        :id="`note-${note.id}`"
                        :key="note.id"
                        class="mb-3 last:mb-0">
                        <AppMessage
                            :user="note.user_name"
                            :title="note.title"
                            :body="note.content"
                            :created-at="note.created_at"
                            icon="fas fa-gavel"
                            size="md" />
                    </li>
                </ul>
            </div>
        </div>

        <AppTextarea
            v-model="page.props.load.claim.description"
            name="description"
            :label="$t('Description')"
            input-class="resize-none"
            rows="17"
            :url="url"
            class="xl:col-span-2"
            :disabled="loadDisabled" />

        <div class="my-4 flex max-w-md flex-col gap-4 sm:flex-row sm:gap-y-0">
            <AppButton
                class="2xl:w-7/12"
                icon="fal fa-pen"
                :disabled="loadDisabled"
                @click="createLoadNote">
                {{ $t('Create Load Note') }}
            </AppButton>

            <AppButton
                class="2xl:w-7/12"
                icon="fal fa-pen"
                :disabled="loadDisabled"
                @click="createFlagNote">
                {{ $t('Create Flag Note') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { usePage, useForm } from '@inertiajs/vue3';
import { values, zipObject } from 'lodash-es';
import { useLoadDisabled } from '@/helpers';

const page = usePage();

const load = computed(() => page.props.load);

const form = useForm({
    follow_up_at: page.props.load.claim.follow_up_at,
    follow_up_at_timezone: page.props.load.claim.follow_up_at_timezone
});

const assigned_to = useForm({
    user_id: page.props.auth.user.id
});

provide('form', form);

function createFlagNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'claim',
        models: { load: page.props.load, claim: page.props.load.claim },
        type: 'claims'
    });
}

function createLoadNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: page.props.load },
        type: 'claim'
    });
}

const claimTypes = computed(() =>
    zipObject(values(page.props.options.claim_types), values(page.props.options.claim_types))
);
const claimLoadTypes = values(page.props.options.claim_load_types);
const claimCustomerTypes = values(page.props.options.claim_customer_types);

const url = route('loads.claims.update', { load: page.props.load.id, claim: page.props.load.claim.id });

const assign = () => {
    assigned_to.user_id = page.props.auth.user.id;
    assigned_to.patch(route('loads.claims.update', [page.props.load.id, page.props.load?.claim?.id]), {
        preserveScroll: true
    });
};

const loadDisabled = useLoadDisabled(page.props.load);
</script>
