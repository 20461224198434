<template>
    <div
        id="load-detail-carrier-pay-rates"
        class="grid">
        <div class="flex items-center gap-2">
            <AppHeading size="lg">{{ $t('Rates') }}</AppHeading>
        </div>
        <div class="flex flex-col gap-1">
            <table
                v-if="props.rates.length"
                class="NX-mobile-stacked">
                <thead>
                    <tr>
                        <th>{{ $t('Quantity') }}</th>
                        <th>{{ $t('Amount') }}</th>
                        <th>{{ $t('Type') }}</th>
                        <th>{{ $t('Note') }}</th>
                        <th>{{ $t('Location') }}</th>
                        <th class="text-center">
                            {{ $t('Bill to Customer') }}
                        </th>
                        <th class="text-center">
                            {{ $t('Pay Carrier') }}
                        </th>
                        <th class="text-center">
                            {{ $t('Receipt Required') }}
                        </th>
                        <th class="md:text-right">{{ $t('Total') }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <LoadLegacyLineItem
                        v-for="rate in props.rates"
                        :key="rate.id"
                        permission="update_carrier_pay_details"
                        :load-id="loadId"
                        :line-item="rate" />
                </tbody>
            </table>
            <div
                v-else
                :id="`load-${loadId}-carrier-pay-rates-empty-state`"
                class="text-sm">
                {{ $t('This load has no rates.') }}
            </div>
        </div>

        <div class="pt-4">
            <AppButton
                :id="`load-${loadId}-carrier-pay-rates-add`"
                type="button"
                permission="update_carrier_pay_details"
                :disabled="loadDisabled"
                @click="debouncedAddRate">
                {{ $t('Add Rate') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import { debounce } from 'lodash-es';

const props = defineProps({
    loadId: {
        type: Number,
        required: true
    },
    rates: {
        type: Array,
        default: () => []
    },
    line_item_type: {
        type: String,
        default: () => ''
    }
});

const page = usePage();
const load = computed(() => page.props.load);

const ratecon = useRateCon(load);

function addRate() {
    if (ratecon.isSent.value && !confirm(ratecon.confirmChangeMessage)) {
        return;
    }

    router.post(
        route('loads.billing.store', props.loadId),
        { quantity: 1, amount: 0, line_type: 'Flat Rate', bill_type: props.line_item_type },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddRate = debounce(addRate, 1000);

const loadDisabled = useLoadDisabled(page.props.load);
</script>
