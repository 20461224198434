<template>
    <div class="flex items-center gap-x-2">
        <div class="grid grid-cols-12 items-start gap-2">
            <AppSelect
                v-if="lineItem.bill_type !== 'customer'"
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-location`"
                :scope="lineItem.id"
                :model-value="lineItem.location_id"
                :label="$t('Location')"
                name="location_id"
                required
                placeholder="Location"
                :options="locations"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <AppSelect
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-type`"
                :scope="lineItem.id"
                :model-value="lineItem.line_type"
                label="Type"
                name="line_type"
                required
                placeholder="Type"
                :options="lineItem.bill_type !== 'accessorial' ? rateTypes : accessorialRatesOptions"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <AppInput
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-quantity`"
                :scope="lineItem.id"
                :model-value="lineItem.quantity"
                label="Quantity"
                name="quantity"
                placeholder="Quantity"
                type="number"
                :min="0"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <AppInput
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-amount`"
                :scope="lineItem.id"
                :model-value="lineItem.amount"
                label="Amount"
                name="amount"
                placeholder="Amount"
                type="number"
                :min="0"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <div class="text-center">
                <AppLabel :label="$t('Total')" />
                <p class="mt-2.5 font-semibold">$ {{ lineItem.total }}</p>
            </div>

            <AppCheckbox
                v-if="lineItem.bill_type !== 'customer'"
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-bill`"
                :scope="lineItem.id"
                :model-value="lineItem.bill_to_customer"
                name="'bill_to_customer'"
                :stacked="true"
                label="Bill Customer"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <AppCheckbox
                v-if="lineItem.bill_type !== 'customer'"
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-pay-carrier`"
                :scope="lineItem.id"
                :model-value="lineItem.pay_carrier"
                name="'pay_carrier'"
                :stacked="true"
                label="Pay Carrier"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <AppCheckbox
                v-if="lineItem.bill_type !== 'customer'"
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-receipt-required`"
                :scope="lineItem.id"
                :model-value="lineItem.receipt_required"
                name="'receipt_required'"
                :stacked="true"
                label="Receipt Req."
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
            <div
                v-if="lineItem.bill_type === 'accessorial'"
                class="col-span-2">
                <AppSelect
                    v-if="!lineItem.document_id"
                    :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-document`"
                    :scope="lineItem.id"
                    :model-value="lineItem.document_id"
                    label="Select Paperwork"
                    name="document_id"
                    :url="url"
                    :disable="loadDisabled || unauthorized || load.billingDetail?.invoiced_at"
                    :title="unauthorized ? $t('Unauthorized') : ''"
                    :confirmation="confirmation">
                    <option
                        :value="null"
                        disabled
                        selected>
                        {{ $t('Select Paperwork') }}
                    </option>
                    <option
                        v-for="document in documents"
                        :key="document.id"
                        :value="document.id">
                        {{ document.file_name }} ({{ document.labels.join(', ') }})
                    </option>
                </AppSelect>
                <div v-else>
                    <AppLabel :label="$t('Selected Paperwork')" />
                    <div class="relative -bottom-2 flex items-center">
                        <p class="relative truncate text-sm font-semibold italic">{{ fileName }}</p>
                        <AppButton
                            class="flex items-center text-iel-red"
                            type="button"
                            title="Remove document"
                            variant="invisible"
                            icon="far fa-circle-xmark"
                            icon-size="md"
                            :disable="loadDisabled || unauthorized || load.billingDetail?.invoiced_at"
                            @click="removeDocument(lineItem)" />
                    </div>
                </div>
            </div>
            <AppInput
                :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-note`"
                :scope="lineItem.id"
                :model-value="lineItem.note"
                label="Note"
                name="note"
                placeholder="Note"
                type="text"
                class="col-span-2"
                :url="url"
                :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
                :title="unauthorized ? $t('Unauthorized') : ''"
                :confirmation="confirmation" />
        </div>
        <AppButton
            :id="`load-${loadId}-${lineItem.bill_type}-rates-${lineItem.id}-remove`"
            variant="outline"
            icon="far fa-trash-can"
            type="button"
            class="h-7 self-end"
            :disable="loadDisabled || unauthorized || Boolean(load?.billingDetail?.invoiced_at)"
            :title="unauthorized ? $t('Unauthorized') : ''"
            @click="removeRate(lineItem.id)" />
    </div>
</template>

<script setup>
import { usePage, router } from '@inertiajs/vue3';
import { fromPairs, zipObject, values } from 'lodash-es';
import { accessorialCharges } from '@/data.js';
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    loadId: {
        type: Number,
        required: true
    },
    lineItem: {
        type: Object,
        required: true,
        default: () => ({})
    },
    permission: String,
    load: {
        type: Object,
        required: true
    }
});

const page = usePage();
const url = route('loads.billing.update', { load: props.loadId, lineItem: props.lineItem });

const ratecon = useRateCon(props.load);
const confirmation = ratecon.confirmChange;

const rateTypes = zipObject(values(page.props.options.rate_types), values(page.props.options.rate_types));
const accessorialRatesOptions = zipObject(accessorialCharges, accessorialCharges);

const locations = computed(() => {
    if (!props.load?.stops) return {};
    return fromPairs(props.load.stops.map(stop => [stop.location?.id, stop.location?.name]));
});
const documents = computed(() => props.load.documents);
const fileName = computed(() => {
    const document = documents.value.find(x => x.id === props.lineItem.document_id);
    return document?.file_name;
});

function removeDocument(lineItem) {
    router.patch(
        route('loads.billing.update', [props.loadId, lineItem]),
        { document_id: null },
        { preserveScroll: true, only: ['load'] }
    );
}

function removeRate(lineItem) {
    router.delete(route('loads.billing.destroy', [props.loadId, lineItem]), { preserveScroll: true, only: ['load'] });
}

const loadDisabled = useLoadDisabled(props.load);

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));
</script>
