<template>
    <div class="flex flex-col gap-4">
        <a
            v-if="load.customer && !load.customer.billing_contact_emails"
            class="rounded border border-iel-dark-yellow bg-iel-light-yellow p-2 text-sm"
            :href="route('customers.show', load.customer.id)"
            target="_blank">
            Please note that the customer has no billing contact emails, which are required to send notifications and
            documentation.
        </a>
        <LoadDocsList v-if="load.documents.length > 0">
            <LoadDocsItem
                v-for="document in load.documents"
                :key="document.id"
                :load="load"
                :document="document" />
        </LoadDocsList>
        <div
            v-else
            class="text-sm">
            {{ $t('No documents found on this load.') }}
        </div>
        <AppDocsDragDrop
            :id="`load-${load.id}-files-new`"
            v-model="pending"
            permission="upload_load_documents"
            :disabled="loadDisabled"
            :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
        <div
            v-if="pending.length > 0"
            class="flex flex-col">
            <h2 class="text-sm font-bold">Pending Uploads</h2>
            <LoadDocsList>
                <LoadDocsPendingItem
                    v-for="file in pending"
                    :key="file.name"
                    :load="load"
                    :pending-file="file"
                    @remove="removePending(index)" />
            </LoadDocsList>
        </div>
    </div>
</template>

<script setup>
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    load: Object
});

const pending = ref([]);

function removePending(i) {
    pending.value.splice(i, 1);
}

// Apply the function to determine if the load should be disabled
const loadDisabled = useLoadDisabled(props.load);
</script>
