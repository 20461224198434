<template>
    <div class="flex flex-col gap-1">
        <table
            v-if="load.accessorialLineItems.length"
            class="NX-mobile-stacked">
            <thead>
                <tr>
                    <th>{{ $t('Quantity') }}</th>
                    <th>{{ $t('Amount') }}</th>
                    <th>{{ $t('Type') }}</th>
                    <th>{{ $t('Note') }}</th>
                    <th>{{ $t('Stop') }}</th>
                    <th class="text-center">{{ $t('Bill to Customer') }}</th>
                    <th class="text-center">{{ $t('Pay Carrier') }}</th>
                    <th class="text-center">{{ $t('Receipt Required') }}</th>
                    <th class="md:text-right">{{ $t('Total') }}</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <LoadLegacyLineItem
                    v-for="rate in load.accessorialLineItems"
                    :key="rate.id"
                    permission="update_accessorial_charges"
                    :load-id="load.id"
                    :line-item="rate" />
            </tbody>
        </table>
        <div
            v-else
            class="text-sm">
            {{ $t('This load has no accessorial charges.') }}
        </div>

        <div class="flex items-center gap-2">
            <AppButton
                id="load-add-accessorial-charge-button"
                type="button"
                permission="update_accessorial_charges"
                :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                @click="debouncedAddAccessorialRate">
                {{ $t('Add') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import { debounce } from 'lodash-es';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

function addAccessorialRate() {
    router.post(
        route('loads.billing.store', props.load.id),
        { quantity: 1, amount: 0, bill_type: 'accessorial' },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddAccessorialRate = debounce(addAccessorialRate, 1000);

const loadDisabled = useLoadDisabled(props.load);
</script>
