<template>
    <div
        v-if="step"
        :id="getId('load-*-phases', [load])"
        class="grid">
        <!-- Phases -->
        <div class="flex overflow-hidden bg-iel-light-gray">
            <button
                v-for="phase in phases"
                :id="`load-card-${load.id}-${phase.name}-phase-tab`"
                :key="`load-card-${load.id}-${phase.name}-phase-tab`"
                class="chevron relative -ml-2 flex w-32 cursor-pointer flex-col items-center gap-1 py-3 transition-colors"
                :class="
                    phase.name === step.phase
                        ? 'bg-white text-iel-blue'
                        : {
                              'bg-iel-blue text-white': phase.status === 'complete',
                              'bg-iel-gray/50 text-iel-blue': phase.status === 'pending',
                              'bg-iel-light-gray text-iel-blue': phase.status === 'incomplete'
                          }
                "
                @click="selectStep(phase.name)">
                <AppIcon
                    :name="phaseIcons[phase.name]"
                    class="size-6" />
                <span class="text-sm font-medium">
                    {{ phase.label }}
                </span>
                <div
                    v-show="phase.name !== 'complete' && step.phase === phase.name"
                    class="chevron-line absolute inset-0 bg-iel-blue" />
            </button>
        </div>

        <!-- Taskbar -->
        <Component
            :is="taskbarComponent"
            v-bind="{ load, selectedTab: step.phase }" />
    </div>
</template>

<script setup>
import LoadPhasesTabbedTaskbar from '@/Pages/Loads/Partials/LoadPhasesTabbedTaskbar.vue';
import LoadPhasesCheckboxTaskbar from '@/Pages/Loads/Partials/LoadPhasesCheckboxTaskbar.vue';
import { getId } from '@/helpers';

const props = defineProps({
    load: Object,
    showSummary: Boolean,
    forceCheckboxTaskbar: Boolean,
    readStepFromStore: Boolean
});

const phaseIcons = {
    summary: 'fas fa-square-list',
    setup: 'fal fa-file',
    plan: 'fal fa-clipboard-list',
    ship: 'fal fa-truck',
    bill: 'fal fa-circle-dollar'
};

const step = props.readStepFromStore
    ? useLoadStepsStore(props.load.id).step
    : ref({ phase: props.showSummary ? 'summary' : props.load.phase });

const taskbarComponent = computed(() => {
    if (props.forceCheckboxTaskbar) return LoadPhasesCheckboxTaskbar;

    switch (step.value.phase) {
        case 'ship':
        case 'summary':
            return null;
        case 'plan':
            return LoadPhasesTabbedTaskbar;
        default:
            return LoadPhasesCheckboxTaskbar;
    }
});

const phases = computed(() => {
    const list = props.load.phases.filter(phase => phase.name !== 'complete');
    if (props.showSummary) list.unshift({ name: 'summary', label: 'Summary', status: 'complete' });
    return list;
});

function selectStep(key) {
    if (!props.readStepFromStore) {
        step.value.phase = key;
        return;
    }

    const cachedKey = localStorage.getItem(`load_${props.load.id}_step_${key}`);
    useLoadStepsStore(props.load.id).setStep(cachedKey ? cachedKey : key);
}
</script>

<style scoped>
.chevron {
    --offset: theme('spacing.3');
    --shape: polygon(
        calc(100% - var(--offset)) 0%,
        100% 50%,
        calc(100% - var(--offset)) 100%,
        0% 100%,
        var(--offset) 50%,
        0% 0%
    );
    clip-path: var(--shape);
    shape-outside: var(--shape);
}

.chevron-line {
    --offset: theme('spacing.3');
    --width: 3px;
    --shape: polygon(
        calc(100% + var(--offset)) 0%,
        calc(100% - var(--offset)) 100%,
        calc(100% - var(--offset) - var(--width)) 100%,
        calc(100% + var(--offset) - var(--width)) 0%
    );
    clip-path: var(--shape);
    shape-outside: var(--shape);
}

.chevron:first-child {
    /* No left cutout on the far left */
    --shape: polygon(calc(100% - var(--offset)) 0%, 100% 50%, calc(100% - var(--offset)) 100%, 0% 100%, 0% 0%);
}
</style>
