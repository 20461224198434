<template>
    <AppCard
        v-if="load"
        title="Carrier RateCon"
        content-class="p-4">
        <div
            v-if="step.task === 'send_carrier_rate_con'"
            class="mb-8 border-b border-iel-gray pb-8">
            <form
                class="flex items-end gap-2"
                @submit.prevent="handleSubmit">
                <AppInput
                    id="send-ratecon-email"
                    v-model="form.email"
                    name="email"
                    label="Email addresses (Multiple email addresses must be separated by comma.)"
                    class="w-full"
                    required />
                <AppButton
                    type="submit"
                    permission="update_ratecon"
                    :disabled="form.processing"
                    :confirmation="ratecon.isSent ? ratecon.confirmChangeMessage : null">
                    {{ $t('Send') }}
                </AppButton>
            </form>
        </div>
        <iframe
            id="send-ratecon-iframe"
            class="w-full"
            :src="`/loads/${load.id}/ratecon?view_as_user=true`" />
    </AppCard>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import Swal from 'sweetalert2';

const props = defineProps({
    load: Object
});

const load = computed(() => props.load);

const ratecon = useRateCon(load);

const step = useLoadStepsStore(props.load.id).step;

const form = useForm({
    email: ''
});

function handleSubmit() {
    if (!ratecon.isSent.value || confirm(ratecon.confirmChangeMessage)) {
        form.post(route('loads.ratecon.send', props.load), {
            preserveScroll: true,
            onSuccess() {
                form.reset();
                return Swal.fire({
                    title: 'Done!',
                    text: 'RateCon sent',
                    icon: 'success'
                });
            }
        });
    }
}

onMounted(() => {
    const iframe = document.querySelector('#send-ratecon-iframe');
    iframe.onload = function () {
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 40 + 'px';
    };
});
</script>
