import { createApp, h } from 'vue';
import { Head, Link, createInertiaApp } from '@inertiajs/vue3';
import { createI18n } from 'vue-i18n';
import { ZiggyVue } from 'ziggy-js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import * as Sentry from '@sentry/vue';
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import AppIcon from '@/Components/AppIcon.vue';
import AppPlaceholder from '@/Components/AppPlaceholder.vue';
import { uppercaseDirective } from './directives';
import es from '../../lang/es.json';
import fr from '../../lang/fr.json';
import '../css/app.css';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'IEL Nexus';

createInertiaApp({
    title: title => (title ? `${title} - ${appName}` : appName),
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
        const page = pages[`./Pages/${name}.vue`];
        // TODO may need to tweak this since ConfirmPassword is for authenticated users
        page.default.layout ??= name.startsWith('Auth/') ? undefined : AuthenticatedLayout;
        return page;
    },
    setup({ el, App, props, plugin }) {
        const i18n = createI18n({
            locale: props.initialPage.props.locale,
            fallbackLocale: 'en',
            formatFallbackMessages: true,
            missingWarn: false,
            legacy: false,
            messages: { es, fr }
        });

        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            autoSessionTracking: true,
            // Tracing
            tracesSampleRate: 0.3, //  Capture 30% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/[a-zA-Z]+\.(intxlog)\.(io|com)/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        app.use(plugin).use(ZiggyVue).use(i18n);
        app.component('Head', Head)
            .component('Link', Link)
            .component('FontAwesomeIcon', FontAwesomeIcon)
            .component('FontAwesomeLayers', FontAwesomeLayers)
            .component('AppIcon', AppIcon)
            .component('AppPlaceholder', AppPlaceholder)
            .directive('uppercase', uppercaseDirective)
            .mount(el);
    },
    progress: {
        color: '#4B5563'
    }
});
