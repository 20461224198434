<template>
    <div class="relative">
        <AppIcon
            name="far fa-magnifying-glass"
            class="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-iel-gray/50" />
        <input
            v-model="value"
            name="search"
            type="search"
            :placeholder="placeholder"
            class="block w-full rounded-full border-iel-gray/40 py-1 pl-8 text-sm"
            aria-label="search input" />
    </div>
</template>

<script setup>
defineProps({
    placeholder: String
});

const value = defineModel({ type: String });
</script>
