<template>
    <div class="grid gap-2 border-x border-b border-iel-mercury bg-white p-2">
        <LoadLegacyPanel
            :title="$t('Load Details')"
            open>
            <LoadLegacyDetails />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            :title="$t('Billing Details')"
            open>
            <LoadLegacyBilling />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            :title="$t('Accessorial Charges')"
            open>
            <LoadLegacyAccessorialCharges />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            :title="$t('Carrier Info')"
            :open="!!load.carrier">
            <LoadLegacyCarrier />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            :title="$t('Carrier Pay Details')"
            :open="!!load.carrier">
            <LoadLegacyCarrierPay />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            id="stops_panel"
            :title="$t('Stops')"
            open>
            <LoadLegacyStops permission="update_stops" />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            id="load-docs-detail-section"
            :title="load.phase !== 'bill' ? $t('Documents') : $t('Required Documents for Customer Invoicing')"
            open>
            <LoadLegacyDocs
                v-if="load.customer"
                :load="load" />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            id="load-notes-detail-section"
            :title="$t('Load Notes')"
            open>
            <LoadNotes :load="load" />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            id="load-text-section"
            :title="$t('Text')"
            open>
            <LoadTextMessages ref="textTabs" />
        </LoadLegacyPanel>

        <LoadLegacyPanel
            :title="$t('Tracking')"
            open>
            <LoadTracking :trackings="load.trackings" />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            :title="$t('Checkcalls')"
            open>
            <LoadCheckcalls />
        </LoadLegacyPanel>
        <LoadLegacyPanel
            v-if="isMacropointEnabled"
            :title="$t('Macropoint')"
            class="text-iel-red"
            open>
            <template #header>
                <LoadLegacyMacroPointStatusIcon
                    v-if="mpStatusMessage"
                    :mp-status-color="mpStatusColor"
                    :mp-status-message="mpStatusMessage"
                    class="pl-1" />
            </template>
            <LoadLegacyMacropoint :load="load" />
        </LoadLegacyPanel>
        <div id="load-flags-detail-section">
            <LoadLegacyPanel
                v-if="load.claim"
                class="mb-2"
                :title="$t('Claims Flag')"
                open>
                <LoadClaimsFlag />
            </LoadLegacyPanel>
            <LoadLegacyPanel
                v-if="load.carrier_incident"
                :title="$t('Carrier Relations Flag')"
                open>
                <LoadCarrierRelationsFlag />
            </LoadLegacyPanel>
        </div>
        <LoadLegacyPanel
            :title="$t('Advances')"
            open>
            <LoadAdvances />
        </LoadLegacyPanel>
    </div>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const isMacropointEnabled = computed(() => Boolean(props.load.macropointOrder));

const mpStatusColor = ref(props.load?.macropointOrder?.status?.color || null);
const mpStatusMessage = ref(props.load?.macropointOrder?.status?.message || null);
</script>
