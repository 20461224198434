<template>
    <AppModal
        id="send-ratecon-modal"
        class="lg:w-[8.5in] lg:max-w-none"
        :show="isOpen"
        @close="close"
        @closed="onClosed">
        <div class="flex items-center justify-between">
            <h1 class="text-xl font-bold text-iel-blue">{{ $t('Send RateCon') }}</h1>
            <AppButton
                v-if="context.task && (context.task.past_due || context.task.due_soon)"
                size="md"
                variant="invisible"
                @click="snoozeTask(context)">
                {{ $t('Snooze') }}
                <AppIcon
                    name="fas fa-alarm-snooze"
                    class="ml-2" />
            </AppButton>
        </div>
        <form
            id="send-ratecon-form"
            class="contents"
            @submit.prevent="handleSubmit">
            <iframe
                v-if="context.load"
                id="send-ratecon-iframe"
                class="h-[60vh] lg:h-[80vh]"
                :src="`/loads/${context.load.id}/ratecon?view_as_user=true`" />
            <AppInput
                id="send-ratecon-email"
                v-model="form.email"
                name="email"
                label="Email address"
                required />
            <div
                id="send-ratecon-buttons"
                class="flex justify-end gap-4 pt-2">
                <AppButton
                    id="send-ratecon-btn-cancel"
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    id="send-ratecon-btn-submit"
                    type="submit"
                    :confirmation="ratecon.confirmChange">
                    {{ $t('Send') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadRateConSendModal');

const load = computed(() => context.value.load);

const ratecon = useRateCon(load);

const form = useForm({
    email: ''
});

provide('form', form);

watch(isOpen, () => {
    form.reset();
    form.clearErrors();
});

function handleSubmit() {
    if (ratecon.isSent.value) {
        if (confirm(ratecon.confirmChangeMessage)) {
            form.post(route('loads.ratecon.send', context.value.load), {
                preserveScroll: true,
                onSuccess() {
                    close();
                }
            });
        }
    } else {
        form.post(route('loads.ratecon.send', context.value.load), {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        });
    }
}

const { open: openLoadSnoozeModal } = useModal('LoadSnoozeModal');

function snoozeTask(task) {
    openLoadSnoozeModal(
        {
            task
        },
        close()
    );
}
</script>
