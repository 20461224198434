<template>
    <div
        id="load-incidents-flag-section"
        class="grid gap-4 xl:grid-cols-5">
        <div class="grid h-1/2 grid-cols-2 gap-2 xl:col-span-3 2xl:grid-cols-4">
            <div>
                <AppAutocomplete
                    :model-value="load.carrier_incident.user ? load.carrier_incident.user.id : null"
                    name="user_id"
                    :label="$t('Assignee')"
                    :options-url="route('users.autocomplete')"
                    :placeholder="$t('Search for an assignee...')"
                    :initial-options="
                        load.carrier_incident.user
                            ? [
                                  {
                                      value: load.carrier_incident.user.id,
                                      display: load.carrier_incident.user.name
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :disabled="loadDisabled" />
                <div
                    v-if="!load.carrier_incident.user?.id"
                    class="pt-2">
                    <AppButton
                        v-if="!load.carrier_incident.completed_at"
                        :id="`report-${load.carrier_incident.user?.id}-assign-button`"
                        variant="primary"
                        size="sm"
                        class="cursor-pointer"
                        :disabled="loadDisabled"
                        @click="assign(load.carrier_incident.user)">
                        {{ $t('Assign To Me') }}
                    </AppButton>
                </div>
            </div>
            <AppSelect
                id="source"
                name="source"
                :label="$t('Source')"
                :model-value="page.props.load.carrier_incident.source"
                :url="url"
                :options="sourceOptions"
                :disabled="loadDisabled" />
            <AppSelect
                id="method"
                name="method"
                :label="$t('Method')"
                :model-value="page.props.load.carrier_incident.method"
                :url="url"
                :options="methodOptions"
                :disabled="loadDisabled" />
            <AppSelect
                id="issue"
                name="issue"
                :label="$t('Issue')"
                :model-value="page.props.load.carrier_incident.issue"
                :url="url"
                :options="issueOptions"
                :disabled="loadDisabled" />
            <AppDisplayField
                v-if="rootCauseOptions && !Object.values(rootCauseOptions).length"
                :label="$t('Root Cause')"
                value="Not Applicable"
                :disabled="loadDisabled" />
            <AppSelect
                v-if="rootCauseOptions && Object.values(rootCauseOptions).length"
                :model-value="page.props.load.carrier_incident.root_cause"
                name="root_cause"
                :label="$t('Root Cause')"
                :url="url"
                :options="rootCauseOptions"
                :disabled="loadDisabled" />
            <AppDateTime
                v-model:datetime="form.follow_up_at"
                v-model:timezone="form.follow_up_at_timezone"
                :field-names="{ datetime: 'follow_up_at', timezone: 'follow_up_at_timezone' }"
                name="follow_up"
                :label="$t('Follow Up')"
                :url="url"
                :disabled="loadDisabled"
                class="col-span-2" />
            <AppCheckbox
                v-model="completed"
                name="completed"
                :label="$t('Completed')"
                class="grid items-center"
                :url="url"
                :disabled="loadDisabled" />

            <div
                v-if="page.props.load.carrier_incident.notes.length > 0"
                class="col-span-4 mt-4">
                <AppLabel
                    label="Incident Notes"
                    class="mb-2" />
                <ul
                    role="list"
                    class="max-h-[12.2rem] overflow-y-auto border p-2">
                    <li
                        v-for="note in page.props.load.carrier_incident.notes"
                        :id="`note-${note.id}`"
                        :key="note.id"
                        class="mb-2.5 last:mb-0">
                        <AppMessage
                            :user="note.user_name"
                            :title="note.title"
                            :body="note.content"
                            :created-at="note.created_at"
                            icon="fas fa-handshake"
                            size="md" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="grid gap-4 lg:max-xl:grid-cols-2 xl:col-span-2">
            <AppTextarea
                v-model="page.props.load.carrier_incident.description"
                name="description"
                :label="$t('Description')"
                input-class="resize-none"
                rows="7"
                :url="url"
                :disabled="loadDisabled" />
            <AppTextarea
                v-model="page.props.load.carrier_incident.contact_info"
                name="contact_info"
                :label="$t('Contact Info')"
                input-class="resize-none"
                rows="7"
                :url="url"
                :disabled="loadDisabled" />
        </div>

        <div class="my-4 flex max-w-md flex-col gap-4 sm:flex-row sm:gap-y-0">
            <AppButton
                class="2xl:w-7/12"
                icon="fal fa-pen"
                :disabled="loadDisabled"
                @click="createLoadNote">
                {{ $t('Create Load Note') }}
            </AppButton>

            <AppButton
                class="2xl:w-7/12"
                icon="fal fa-pen"
                :disabled="loadDisabled"
                @click="createFlagNote">
                {{ $t('Create Flag Note') }}
            </AppButton>
        </div>
    </div>
</template>

<script setup>
import { usePage, useForm } from '@inertiajs/vue3';
import { zipObject } from 'lodash-es';
import { carrierRelationsIssues } from '@/data';
import { useLoadDisabled } from '@/helpers';

const page = usePage();

const load = computed(() => page.props.load);
const completed = computed(() => (page.props.load.carrier_incident.completed_at ? true : false));
const loadDisabled = useLoadDisabled(page.props.load);

const form = useForm({
    follow_up_at: page.props.load.carrier_incident.follow_up_at,
    follow_up_at_timezone: page.props.load.carrier_incident.follow_up_at_timezone
});

const assigned_to = useForm({
    user_id: page.props.auth.user.id
});

provide('form', form);

const issues = Object.keys(carrierRelationsIssues);
const issueOptions = zipObject(issues, issues);

const sources = [
    'Carrier',
    'Factoring Company',
    'Broker',
    'SEM / SM',
    'OPS Member',
    'Acct. Member',
    'Triumph Pay',
    'Other'
];
const sourceOptions = zipObject(sources, sources);

const methods = ['Call', 'Email', 'Voicemail'];
const methodOptions = zipObject(methods, methods);

const rootCauseOptions = computed(() => {
    if (!page.props.load.carrier_incident.issue) return null;
    const rootCauses = carrierRelationsIssues[page.props.load.carrier_incident.issue];
    return zipObject(rootCauses, rootCauses);
});

const url = route('loads.carrier-incident.update', [page.props.load.id, page.props.load.carrier_incident.id]);

function createFlagNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'carrierRelations',
        models: { load: page.props.load, carrierIncident: page.props.load.carrier_incident },
        type: 'carrier-incident'
    });
}

function createLoadNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: page.props.load },
        type: 'carrier_relations'
    });
}

const assign = () => {
    assigned_to.user_id = page.props.auth.user.id;
    assigned_to.patch(
        route('loads.carrier-incident.update', [page.props.load.id, page.props.load?.carrier_incident?.id]),
        {
            preserveScroll: true
        }
    );
};
</script>
