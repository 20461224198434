<template>
    <AppModal
        id="create-task-modal"
        :show="isOpen"
        :title="$t(context.title ?? 'Load Task')"
        class="max-w-md"
        @close="close"
        @closed="onClosed">
        <form @submit.prevent="handleSubmit">
            <div class="relative -top-8 flex justify-end">
                <AppButton
                    v-if="context.mode === 'edit'"
                    size="md"
                    :disabled="isCompleted"
                    @click="snoozeTask(context)">
                    {{ $t('Snooze') }}
                    <AppIcon
                        name="fas fa-alarm-snooze"
                        class="ml-2" />
                </AppButton>
            </div>
            <div class="grid gap-5">
                <AppSelect
                    v-model="form.phase"
                    name="phase"
                    :label="$t('Load Phase')"
                    :options="{ setup: 'Setup', plan: 'Plan', ship: 'Ship', bill: 'Bill' }"
                    bottom-border
                    required
                    :disabled="isCompleted" />

                <AppInput
                    v-model="form.name"
                    name="name"
                    bottom-border
                    :label="$t('Task Name')"
                    required
                    :disabled="isCompleted" />

                <AppTextarea
                    v-model="form.description"
                    name="task"
                    bottom-border
                    :label="$t('Description')"
                    required
                    :disabled="isCompleted" />

                <div class="datetime flex w-full items-end gap-2">
                    <AppIcon
                        name="far fa-calendar-day"
                        class="h-6 w-6 text-iel-gray" />
                    <AppDateTime
                        v-model:datetime="form.due_at"
                        v-model:timezone="form.due_at_timezone"
                        :field-names="{ datetime: 'due_at', timezone: 'due_at_timezone' }"
                        name="due_at"
                        :label="$t('Due Date/time')"
                        :is-centered="true"
                        required
                        :disabled="isCompleted" />
                </div>

                <div class="grid grid-cols-2 items-end">
                    <div class="flex items-end gap-2">
                        <AppIcon
                            name="far fa-clock"
                            class="h-6 w-6 text-iel-gray" />
                        <AppSelect
                            v-model="form.warning_minutes"
                            name="Warn At"
                            :label="$t('Warn At')"
                            :options="{
                                0: 'None',
                                15: '15 minutes',
                                30: '30 minutes',
                                60: '1 hour',
                                180: '3 hours',
                                480: '8 hours',
                                1440: '24 hours'
                            }"
                            bottom-border
                            required
                            :disabled="isCompleted" />
                    </div>
                    <AppCheckbox
                        v-model="form.is_complete"
                        name="is_complete"
                        :checked="form.completed_at"
                        :label="form.is_complete ? 'Completed' : 'Mark Completed'"
                        class="justify-self-end"
                        :disabled="isCompleted" />
                </div>
                <div class="flex justify-end gap-4 pt-2">
                    <AppButton
                        v-if="context.mode === 'edit'"
                        variant="outline"
                        class="mr-auto border-iel-dark-red text-iel-dark-red"
                        @click="confirmDelete">
                        {{ $t('Delete') }}
                    </AppButton>
                    <AppButton
                        variant="outline"
                        @click="close">
                        {{ $t('Cancel') }}
                    </AppButton>
                    <AppButton
                        type="submit"
                        :disabled="isCompleted">
                        {{ $t('Save') }}
                    </AppButton>
                </div>
                <AppInput
                    v-model="form.type"
                    name="type"
                    bottom-border
                    :label="$t('Task Type')"
                    required
                    class="hidden" />
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm, router } from '@inertiajs/vue3';
import { watch, computed, ref } from 'vue';

const { isOpen, close, onClosed, context } = useModal('LoadTaskModal');

const { open: openLoadSnoozeModal } = useModal('LoadSnoozeModal');

function snoozeTask(task) {
    openLoadSnoozeModal(
        {
            task
        },
        close()
    );
}

const form = useForm({
    phase: null,
    name: '',
    description: '',
    due_at: null,
    due_at_timezone: null,
    type: 'manual',
    warning_minutes: 0,
    is_complete: false
});

const taskIsCompleted = ref(false);

const isCompleted = computed(() => taskIsCompleted.value);

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    if (!value) return;

    if (context.value.mode === 'edit') {
        form.phase = context.value.task.phase;
        form.name = context.value.task.name;
        form.warning_minutes = context.value.task.warning_minutes;
        form.description = context.value.task.description;
        form.due_at = context.value.task.due_at;
        form.due_at_timezone = context.value.task.due_at_timezone;
        form.type = context.value.task.type;
        form.is_complete = context.value.task.is_complete;
        taskIsCompleted.value = context.value.task.is_complete;
    } else {
        delete form.is_complete;
        taskIsCompleted.value = false;
    }
});

function handleSubmit() {
    if (context.value.mode === 'create') {
        form.post(route('loads.tasks.store', [context.value.load, context.value.task]), {
            preserveScroll: true,
            onSuccess() {
                close();
                router.reload({ only: ['load'] });
            }
        });
    }

    if (context.value.mode === 'edit') {
        form.patch(
            route('loads.tasks.update', {
                load: context.value.load.id,
                task: context.value.task.id
            }),
            {
                preserveScroll: true,
                onSuccess() {
                    close();
                    router.reload({ only: ['load'] });
                }
            }
        );
    }
}

function confirmDelete() {
    if (confirm('Are you sure you would like to delete this task?')) {
        router.delete(route('loads.tasks.destroy', [context.value.load.id, context.value.task.id]), {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        });
    }
}
</script>

<style>
.datetime input {
    @apply !rounded-none !border-l-0 !border-r-0 !border-t-0;
}
.datetime .dp__input_icon {
    @apply !hidden;
}
.datetime .dp__input_icon_pad {
    @apply !pl-2;
}
</style>
