<template>
    <AppModal
        id="create-stop-modal"
        :show="isOpen"
        title="Create Stop"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <div class="grid gap-2">
                <AppAutocomplete
                    v-model="form.location_id"
                    name="location_id"
                    label=""
                    :options-url="route('locations.autocomplete')"
                    :placeholder="$t('Search for a location...')"
                    :confirmation="confirmation" />
                <AppRadioGroup
                    v-model="form.type"
                    name="type"
                    label=""
                    :options="[
                        { value: 'pickup', label: 'Pickup' },
                        { value: 'dropoff', label: 'Dropoff' }
                    ]" />
            </div>
            <div class="my-2 flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="form.processing">
                    {{ $t('Add') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadStopCreateModal');

const ratecon = useRateCon(context.value.load);
const confirmation = ratecon.confirmChange;

const form = useForm({
    load_id: null,
    location_id: null,
    type: null
});

watch(isOpen, value => {
    form.reset();
    form.clearErrors();

    if (value) {
        form.load_id = context.value.load.id;
    }
});

function handleSubmit() {
    form.post(route('stops.store'), {
        preserveScroll: true,
        onSuccess: () => {
            close();
        }
    });
}
</script>
