<template>
    <AppModal
        id="carrier-contact-modal"
        :show="isOpen"
        :title="context.title ?? 'Change Carrier Advance Terms'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <AppDisplayField
                label="Carrier"
                :value="form.name" />
            <AppInput
                id="carrier-email-address"
                v-model="form.email"
                label="Email"
                name="carrier-email-address"
                required />
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { ref } from 'vue';
import axios from 'axios';

const { isOpen, close, onClosed, context } = useModal('CarrierAdvanceTermsModal');

const form = useForm({
    name: null,
    email: null,
    load_id: null
});

const errorMessage = ref(null);

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();
    if (!value) return;
    form.name = context.value.carrier.name;
    form.email = context.value.carrier.marketing_email;
    form.load_id = context.value.load_id;
});

async function handleSubmit() {
    errorMessage.value = null;
    try {
        await axios
            .post(route('carriers.advance-terms-change-tickets.store', context.value.carrier), {
                email: form.email,
                load_id: form.load_id
            })
            .finally(() => {
                close();
            });
    } catch (error) {
        errorMessage.value = error.response?.data?.message || 'An unexpected error occurred.';
    }
}
</script>
