<template>
    <div class="flex items-center gap-4">
        <AppCard
            v-for="box in boxes"
            :key="box.title"
            :title="box.title"
            content-class="p-4 flex gap-4 text-sm whitespace-nowrap">
            <div
                v-for="cell in box.cells"
                :key="cell.label"
                class="flex-1">
                <div>
                    <strong>{{ cell.label }}</strong>
                </div>
                <div class="capitalize">{{ cell.value }}</div>
            </div>
        </AppCard>
    </div>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const boxes = computed(() => [
    {
        title: 'Billing Details',
        cells: [
            { label: 'Total Billing Lines', value: '$' + props.load.total_customer_billing_amount },
            { label: 'Accesorials', value: '$' + props.load.total_accessorial_charges_amount },
            { label: 'Total Bill', value: '$' + props.load.total_invoice_amount }
        ]
    },
    {
        title: 'Load Details',
        cells: [
            {
                label: 'Equipment',
                value: props.load.required_equipment_type
                    ? [props.load.equipment_length + "'", props.load.required_equipment_type].join(' ')
                    : '-'
            },
            {
                label: 'Temp',
                value: props.load.temperature
                    ? [props.load.temperature, props.load.temperature_units?.toUpperCase()].join(' ')
                    : '-'
            },
            { label: 'Total Trip Miles', value: props.load.trip_miles ?? '-' },
            { label: 'Commodity Type', value: props.load.commodity_type ?? '-' },
            { label: 'Total Load Weight', value: props.load.total_load_weight ?? '-' }
        ]
    }
]);
</script>
