<template>
    <AppCard
        :title="title"
        content-class="p-2"
        stacked>
        <template #titleMeta>
            <slot name="meta" />
        </template>
        <template #nav>
            <nav class="flex flex-col items-center justify-between gap-6 md:flex-row">
                <slot name="nav" />
                <ul class="ml-auto flex flex-wrap gap-2">
                    <li
                        v-for="(label, key) in sections"
                        :key="key">
                        <button
                            :id="`toggle-trigger-${key}`"
                            class="rounded px-1.5 leading-snug text-white"
                            :class="panels[key] ? 'bg-iel-blue' : 'border-iel-blue/25 bg-iel-blue/25'"
                            @click="toggle(key)">
                            {{ label }}
                        </button>
                    </li>
                </ul>
                <slot name="actions" />
                <AppButton
                    icon="far fa-circle-xmark"
                    variant="invisible"
                    class="text-white"
                    @click="goBack()"></AppButton>
            </nav>
        </template>
        <div class="columns-xl space-y-5">
            <slot
                v-for="key in activePanels"
                :key="key"
                :name="key" />
        </div>
    </AppCard>
</template>

<script setup>
import { mapValues, pickBy } from 'lodash-es';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    meta: {
        type: String,
        required: false
    },
    sections: {
        type: Object,
        required: true
    }
});

const panels = ref(mapValues(props.sections, () => true));
const activePanels = computed(() => Object.keys(pickBy(panels.value)));

function toggle(key) {
    panels.value[key] = !panels.value[key];
}

function goBack() {
    window.history.back();
}
</script>
