<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <Combobox
            :name="name"
            :model-value="hasAutoSave ? autoSaveValue : modelValue"
            as="div"
            class="relative"
            :disabled="unauthorized || disabled"
            @update:model-value="onUpdate">
            <ComboboxInput
                v-bind="attributes"
                :display-value="formatDisplayValue"
                class="block w-full rounded border-iel-gray/40 px-2 py-1 placeholder:text-iel-gray sm:text-sm"
                :aria-label="attributes.name"
                @change="onChange" />
            <ComboboxOptions
                v-show="hasSearched"
                class="absolute z-10 mt-1 max-h-28 w-full overflow-y-auto rounded bg-white ring-1 ring-black ring-opacity-25 focus:outline-none sm:text-sm"
                tabindex="0">
                <ComboboxOption
                    v-if="allowAdding"
                    class="cursor-pointer select-none px-1.5 py-0.5 ui-active:bg-iel-lightest-blue"
                    :value="ADD_NEW_PLACEHOLDER">
                    {{ query ? `Add "${query}"...` : 'Add new...' }}
                </ComboboxOption>
                <ComboboxOption
                    v-else-if="noResults"
                    class="px-1.5 py-0.5 italic text-iel-dark-gray"
                    disabled>
                    {{ $t('Nothing found...') }}
                </ComboboxOption>
                <template v-if="!noResults">
                    <ComboboxOption
                        v-for="option in options"
                        :key="option.value"
                        :value="option.value"
                        :disabled="!isNil(option[disabledOptionsField]) && !option[disabledOptionsField]"
                        :class="
                            !isNil(option[disabledOptionsField])
                                ? { [disabledOptionsClass]: !option[disabledOptionsField] }
                                : {}
                        "
                        class="cursor-pointer select-none px-1.5 py-0.5 ui-active:bg-iel-lightest-blue">
                        {{ option.label }}
                        {{
                            !isNil(option[disabledOptionsField]) && !option[disabledOptionsField]
                                ? disabledOptionsLabel
                                : ''
                        }}
                    </ComboboxOption>
                </template>
            </ComboboxOptions>
            <AppRequestStatus
                v-if="hasAutoSave"
                v-bind="{ recentlySuccessful, processing, error }" />
            <button
                v-if="!isEmpty && !processing && !recentlySuccessful && !hideClear && !disabled && !unauthorized"
                class="absolute inset-y-0 right-0 mr-1 flex items-center rounded-full pl-1 pr-1 text-iel-gray transition duration-150 ease-in-out hover:text-iel-red focus:outline-none focus:ring-2 focus:ring-iel-light-blue"
                type="button"
                title="Remove"
                @click="clear">
                <AppIcon
                    name="fal fa-circle-xmark"
                    class="block h-5 w-5 bg-white text-iel-red hover:scale-110" />
            </button>
        </Combobox>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
import axios from 'axios';
import { debounce, isPlainObject, isNil } from 'lodash-es';
import { Combobox, ComboboxInput, ComboboxOptions, ComboboxOption } from '@headlessui/vue';

const ADD_NEW_PLACEHOLDER = '__ADD_NEW_PLACEHOLDER__';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    url: String,
    value: [String, Number],
    label: String,
    error: String,
    scope: [String, Number],
    initialOptions: {
        type: Array,
        default: () => []
    },
    optionsUrl: String,
    getOptionsUsing: Function,
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
    queryParams: {
        type: Object,
        default: () => ({})
    },
    allowAdding: Boolean,
    modelValue: [String, Number, Object],
    initialValue: String,
    hideClear: Boolean,
    confirmation: String,
    selectedOption: Object,
    disabledOptionsLabel: String,
    disabledOptionsField: String,
    disabledOptionsClass: String,
    permission: String
});

const emit = defineEmits(['add', 'update:modelValue', 'update:selectedOption']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const {
    value: autoSaveValue,
    submit,
    processing,
    recentlySuccessful,
    error: autoSaveError,
    clearError
} = useAutoSave(props, 'modelValue');

const error = hasAutoSave ? autoSaveError : computed(() => props.error || form?.errors?.[props.name]);

const { label: fieldLabel, attributes } = useField(props, error);

const { can } = useAuth();

const unauthorized = computed(() => props.permission && !can(props.permission));

/**
 * User-entered search query.
 */
const query = ref('');

/**
 * Whether any search round-trip has been triggered yet.
 */
const hasSearched = ref(false);

/**
 * Available options.
 */
const options = ref(props.initialOptions ?? []);

if (hasAutoSave) {
    watch(
        () => props.initialOptions,
        () => {
            options.value = props.initialOptions;
        }
    );
}

/**
 * Whether the search returned no results.
 */
const noResults = computed(() => !options.value.length);

/**
 * Default function used to retrieve options. Performs a GET request to the URL
 * provided in the props, passing through the user's search query.
 * queryParams adds in extra options to be passed on the query string.
 */
async function fetchOptions(q, queryParams = {}) {
    return (await axios.get(props.optionsUrl, { params: { q, ...queryParams } })).data;
}

const isEmpty = computed(() => {
    if (!hasAutoSave) {
        if (isPlainObject(props.modelValue)) {
            return Object.values(props.modelValue).every(value => !value);
        }

        return !props.modelValue;
    } else {
        return !autoSaveValue.value;
    }
});

/**
 * Reactive state containing the function to use to retrieve options, either
 * a props-provided override or the default fetcher. The computed value is
 * the function, so this is called with `getOptionsUsing.value(q)`.
 */
const getOptionsUsing = computed(() => props.getOptionsUsing ?? fetchOptions);

/**
 * Watch the query reload the options when it changes (at most every 100ms).
 */
watch(
    query,
    debounce(async q => {
        options.value = await getOptionsUsing.value(q, props.queryParams);
        hasSearched.value = true;
    }, 300)
);

/**
 * Get the value to display inside the field when it's *not* focused.
 */
function formatDisplayValue(currentValue) {
    const option = options.value.concat(props.initialOptions).find(({ value }) => value === currentValue);
    return option?.display ?? option?.label ?? props.initialValue ?? '';
}

function onChange(e) {
    query.value = e.target.value;
    if (!hasAutoSave) {
        form?.clearErrors();
    } else {
        clearError();
    }
}

function onUpdate(value) {
    if (!hasAutoSave) {
        emit('update:modelValue', value);

        const selectedOption = options.value.find(option => option.value === value);
        emit('update:selectedOption', selectedOption || null);
    } else {
        if (value === ADD_NEW_PLACEHOLDER) {
            if (props.confirmation && !confirm(props.confirmation)) return;
            emit('add', query.value);
        } else {
            autoSaveValue.value = value;
            nextTick(submit);
        }
    }
}

function clear() {
    if (!hasAutoSave) {
        if (typeof props.modelValue === 'object') {
            let newValue = {};

            Object.keys(props.modelValue).forEach(key => {
                newValue[key] = null;
            });

            emit('update:modelValue', newValue);
        } else {
            emit('update:modelValue', null);
        }
    } else {
        autoSaveValue.value = null;
        nextTick(submit);
    }
}
</script>
