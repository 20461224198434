<template>
    <div class="flex flex-col gap-1">
        <AppHeading size="sm">{{ $t('Customer Billing Number') }}</AppHeading>

        <table
            v-if="form.references.length"
            class="NX-mobile-stacked">
            <tr
                v-for="(reference, index) in form.references"
                :key="index">
                <td>
                    <AppInput
                        :id="`load-${load.id}-reference-number-${index}`"
                        v-model="reference.number"
                        permission="update_load_billing_details"
                        :name="`load-${load.id}-reference-number-${index}`"
                        label="Ref#"
                        placeholder="Number"
                        :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                        :error="form.errors[`references.${index}.number`]" />
                </td>
                <td>
                    <div class="flex gap-2">
                        <AppSelect
                            :id="`load-${load.id}-reference-description-${index}`"
                            v-model="reference.description"
                            permission="update_load_billing_details"
                            :name="`load-${load.id}-reference-description-${index}`"
                            class="w-full"
                            :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                            :error="form.errors[`references.${index}.description`]"
                            :options="{
                                BOL: 'BOL',
                                'Delivery Number': 'Delivery Number',
                                PRO: 'PRO',
                                SAP: 'SAP',
                                'Customer Reference': 'Customer Reference',
                                'Freight Agreement Number': 'Freight Agreement Number',
                                'Purchase Order Number': 'Purchase Order Number',
                                'Release Number': 'Release Number',
                                Other: 'Other'
                            }" />
                        <AppInput
                            v-if="reference.description === 'Other'"
                            :id="`load-${load.id}-other-reference-description-${index}`"
                            v-model="reference.other"
                            permission="update_load_billing_details"
                            :name="`load-${load.id}-other-reference-description-${index}`"
                            placeholder="Type"
                            :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                            :error="form.errors[`references.${index}.other`]" />
                    </div>
                </td>
                <td class="align-bottom">
                    <AppButton
                        variant="outline"
                        icon="far fa-trash-can"
                        type="button"
                        permission="update_load_billing_details"
                        :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                        @click="removeReference(index)" />
                </td>
            </tr>
        </table>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no billing numbers.') }}
        </div>

        <div class="flex items-center gap-2">
            <AppButton
                id="load-add-billing-number-button"
                :disabled="loadDisabled || Boolean(load.billingDetail?.invoiced_at)"
                permission="update_load_billing_details"
                @click="debouncedAddReference">
                {{ $t('Add') }}
            </AppButton>
            <AppRequestStatus
                v-bind="{
                    ...pick(form, ['recentlySuccessful', 'processing', 'error']),
                    floating: false,
                    displayText: true
                }" />
        </div>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import { watchDebounced } from '@vueuse/core';
import { debounce, pick } from 'lodash-es';
import { useLoadDisabled } from '@/helpers';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const form = useForm({
    references: props.load.references || []
});

watchDebounced(
    form.references,
    () => {
        form.patch(route('loads.references.update', props.load), {
            preserveScroll: true
        });
    },
    { debounce: 1000 }
);

function removeReference(index) {
    form.references.splice(index, 1);
}

function addReference() {
    form.references.push({
        number: null,
        description: null,
        other: null
    });
}

const debouncedAddReference = debounce(addReference, 1000);

const loadDisabled = useLoadDisabled(props.load);
</script>
