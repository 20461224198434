<template>
    <div class="grid grid-cols-12 gap-2">
        <AppInput
            label="Minimum Pay Amount"
            type="number"
            class="col-span-2"
            name="min_pay_carrier_amount"
            :min="0"
            :model-value="load.min_pay_carrier_amount"
            :url="url" />
        <AppInput
            label="Maximum Pay Amount"
            type="number"
            class="col-span-2"
            name="max_pay_carrier_amount"
            :min="0"
            :model-value="load.max_pay_carrier_amount"
            :url="url" />
    </div>
</template>

<script setup>
const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

const url = route('loads.update', props.load);
</script>
