<template>
    <AppDropdown align="bottom-right">
        <template #trigger>
            <button
                :id="`load-card-${load.id}-actions-trigger`"
                class="flex"
                type="button"
                aria-label="dropdown menu to select load action options">
                <AppIcon
                    name="far fa-ellipsis-vertical"
                    class="cursor-pointer text-white" />
            </button>
        </template>
        <template #content>
            <a
                :href="route('loads.show', load.id)"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                target="_blank">
                {{ $t('Open In New Tab') }}
            </a>
            <button
                :id="`load-card-${load.id}-actions-create-checkcall-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                type="button"
                :disabled="selectionDisabled"
                @click="createCheckcall">
                {{ $t('Create Checkcall') }}
            </button>
            <button
                :id="`load-card-${load.id}-actions-create-note-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                type="button"
                @click="createNote">
                {{ $t('Create Note') }}
            </button>
            <button
                :id="`load-card-${load.id}-actions-create-task-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                type="button"
                :disabled="selectionDisabled"
                @click="createTask">
                {{ $t('Create Task') }}
            </button>
            <div
                v-if="
                    load.macropointOrder && load.macropointOrder.status.message === 'Tracking Completed Successfully'
                ">
                <p
                    :id="`load-card-${load.id}-actions-tracking-completed`"
                    class="block w-full cursor-not-allowed px-4 py-2 text-left text-sm leading-5 text-iel-blue"
                    disabled>
                    {{ $t('Tracking Completed') }}
                </p>
            </div>
            <div
                v-else-if="
                    load.macropointOrder &&
                    load.macropointOrder.status.message !== 'Stopped By Creator' &&
                    load.macropointOrder.status.message !== 'Unknown'
                ">
                <button
                    :id="`load-card-${load.id}-actions-edit-macropoint-order-button`"
                    class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                    :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                    type="button"
                    :disabled="selectionDisabled"
                    @click="editMacropointOrder">
                    {{ $t('Update Macropoint Order') }}
                </button>
                <button
                    :id="`load-card-${load.id}-actions-cancel-macropoint-order-button`"
                    class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                    :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                    type="button"
                    :disabled="selectionDisabled"
                    @click="cancelMacropointOrder">
                    {{ $t('Cancel Macropoint Order') }}
                </button>
            </div>
            <div v-else>
                <button
                    v-if="load.can.create_macropoint_order"
                    :id="`load-card-${load.id}-actions-create-macropoint-order-button`"
                    class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                    :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                    type="button"
                    :disabled="selectionDisabled"
                    @click="createMacropointOrder">
                    {{ $t('Create Macropoint Order') }}
                </button>
            </div>
            <button
                :id="`load-card-${load.id}-actions-send-dispatch-sheet-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                type="button"
                :disabled="selectionDisabled"
                @click="sendDispatchSheet">
                {{ $t('Send Dispatch Sheet') }}
            </button>
            <button
                :id="`load-card-${load.id}-actions-create-flag-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{ 'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled }"
                type="button"
                :disabled="selectionDisabled"
                @click="createFlag">
                {{ $t('Create Flag') }}
            </button>
            <button
                v-if="!load.is_tonu && !load.is_canceled"
                :id="`load-card-${props.load.id}-actions-create-tonu-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{
                    'cursor-not-allowed bg-iel-light-gray text-iel-gray':
                        selectionDisabled || !can('cancel_or_tonu_load')
                }"
                variant="invisible"
                type="button"
                permission="cancel_or_tonu_load"
                :disabled="selectionDisabled || !can('cancel_or_tonu_load')"
                @click="createTonu">
                {{ $t('Cancel/TONU') }}
            </button>
            <button
                v-if="load.is_tonu && !load.is_canceled"
                :id="`load-card-${props.load.id}-actions-edit-tonu-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                variant="invisible"
                type="button"
                :disabled="!can('cancel_or_tonu_load')"
                @click="editTonu">
                {{ $t('Edit TONU') }}
            </button>
            <button
                v-if="load.is_tonu || load.is_canceled"
                :id="`load-card-${props.load.id}-actions-uncancel-untonu-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                variant="invisible"
                type="button"
                @click="reverseCancelOrTonu">
                {{ $t('Reverse Cancel/TONU') }}
            </button>
            <button
                v-if="load.carrier"
                :id="`load-card-${load.id}-actions-issue-comcheck-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                :class="{
                    'cursor-not-allowed bg-iel-light-gray text-iel-gray': selectionDisabled || !can('issue_comcheck')
                }"
                variant="invisible"
                type="button"
                :disabled="selectionDisabled || !can('issue_comcheck')"
                @click="issueComcheck">
                {{ $t('Issue Comcheck') }}
            </button>
            <button
                :id="`load-card-${load.id}-actions-copy-load-button`"
                class="block w-full px-4 py-2 text-left text-sm leading-5 text-iel-blue transition duration-150 ease-in-out hover:bg-iel-light-gray focus:bg-iel-light-gray focus:outline-none"
                type="button"
                @click="copyLoad">
                {{ $t('Copy Load') }}
            </button>
        </template>
    </AppDropdown>
</template>

<script setup>
import { router, useForm } from '@inertiajs/vue3';
import { Confirm } from '@/Composables/useSwal.js';
import Swal from 'sweetalert2';

const props = defineProps({
    load: Object,
    options: Object
});

const { open: openLoadComcheckModal } = useModal('LoadComcheckModal');
const { open: openLoadCheckcallModal } = useModal('LoadCheckcallModal');
const { open: openLoadTaskModal } = useModal('LoadTaskModal');
const { open: openLoadMacropointModal } = useModal('LoadMacropointModal');
const { open: openLoadDispatchSheetSendModal } = useModal('LoadDispatchSheetSendModal');
const { open: openLoadFlagCreateModal } = useModal('LoadFlagCreateModal');
const { open: openLoadTonuModal } = useModal('LoadTonuModal');
const { open: openLoadCopyModal } = useModal('LoadCopyModal');

const { can } = useAuth();

function createCheckcall() {
    openLoadCheckcallModal({
        mode: 'create',
        load: props.load,
        defaults: { event: 'En Route' }
    });
}

function createNote() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: props.load }
    });
}

function createTask() {
    openLoadTaskModal({
        mode: 'create',
        load: props.load
    });
}

function createMacropointOrder() {
    if (props.load.can?.create_macropoint_order?.length) {
        return Swal.fire({
            title: `Load #${props.load.id} is not ready for MacroPoint`,
            html: `<div class="flex justify-center">
                <ul class="text-left list-disc">
                    ${props.load.can.create_macropoint_order.map(reason => `<li>${reason}</li>`).join('')}
                </ul>
            </div>`,
            icon: 'error'
        });
    }
    openLoadMacropointModal({
        mode: 'create',
        load: props.load,
        title: 'Enable Macropoint Order'
    });
}

function editMacropointOrder() {
    openLoadMacropointModal({
        mode: 'edit',
        load: props.load,
        macropointOrder: props.load.macropointOrder,
        title: 'Update Macropoint Order'
    });
}

function cancelMacropointOrder() {
    Confirm.fire({
        title: 'Cancel Macropoint Order',
        text: 'Are you sure you would like to cancel the MacroPoint order for this load?'
    }).then(result => {
        if (result.isConfirmed) {
            form.delete(route('loads.macropoint.destroy', [props.load, props.load.macropointOrder])),
                {
                    preserveScroll: true,
                    only: ['load'],
                    onSuccess() {
                        close();
                    }
                };
        }
    });
}

function createFlag() {
    openLoadFlagCreateModal({
        load: props.load,
        options: props.options
    });
}

function sendDispatchSheet() {
    openLoadDispatchSheetSendModal({
        mode: 'create',
        load: props.load
    });
}

function issueComcheck() {
    openLoadComcheckModal({
        mode: 'create',
        load: props.load
    });
}

function createTonu() {
    openLoadTonuModal({
        mode: 'create',
        load: props.load
    });
}

function editTonu() {
    openLoadTonuModal({
        mode: 'edit',
        load: props.load,
        title: 'Edit TONU'
    });
}

function copyLoad() {
    openLoadCopyModal({
        load: props.load
    });
}

const form = useForm({
    is_canceled: false,
    is_tonu: false
});

provide('form', form);

function reverseCancelOrTonu() {
    form.delete(route('loads.cancel.destroy', props.load), {
        preserveState: false,
        preserveScroll: true,
        onSuccess: () => {
            router.reload({ only: ['load'] });
        }
    });
}

const selectionDisabled = computed(() => {
    if (props.load.is_tonu || props.load.is_canceled) {
        return true;
    }
});
</script>
