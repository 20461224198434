<template>
    <div class="@container">
        <div class="mb-3 text-sm">
            IEL PO #
            <span class="font-medium">{{ load.po_number }}</span>
        </div>
        <div class="grid gap-4 xl:grid-cols-5">
            <div class="md:grid md:grid-cols-2 md:gap-2 xl:col-span-3 2xl:grid-cols-4">
                <AppDisplayField
                    v-if="load.customer?.id"
                    label="Customer Name"
                    :value="$page.props.load.customer?.name"
                    :href="route('customers.show', $page.props.load.customer.id)" />
                <AppDisplayField
                    label="Lane"
                    :value="formattedLane" />

                <AppDisplayField
                    label="Total Distance"
                    :value="$page.props.load.trip_miles?.toLocaleString()"
                    :error="tripMilesError" />
                <div class="flex items-end gap-2">
                    <AppDisplayField
                        label="Total Load Weight"
                        :value="$page.props.load.total_load_weight"
                        class="flex-1" />
                    <AppDisplayField
                        label=""
                        :value="$page.props.load.total_load_weight_description" />
                </div>

                <AppInput
                    v-model="load.customer_po"
                    permission="update_load_details"
                    name="customer_po"
                    label="Customer PO"
                    :disabled="loadDisabled"
                    :url="url" />
                <AppSelect
                    id="transportation-mode"
                    name="transportation_mode"
                    :model-value="load.transportation_mode"
                    permission="update_load_details"
                    :url="url"
                    :options="transportationModes"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />
                <AppDateTime
                    v-model:datetime="firstStopDatetime"
                    v-model:timezone="firstStopDateTimezone"
                    permission="update_load_details"
                    label="First Stop Date/Time"
                    name="first_stop_datetime"
                    :disabled="true" />
                <AppInput
                    v-model="load.total_pieces"
                    permission="update_load_details"
                    name="total_pieces"
                    :disabled="loadDisabled"
                    :url="url" />
                <AppSelect
                    id="required-equipment-type"
                    name="required_equipment_type"
                    :model-value="load.required_equipment_type"
                    permission="update_load_details"
                    :url="url"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    :options="equipmentTypes" />
                <AppInput
                    v-model="load.total_pallets"
                    permission="update_load_details"
                    name="total_pallets"
                    :disabled="loadDisabled"
                    :url="url" />
                <AppDateTime
                    v-model:datetime="lastStopDatetime"
                    v-model:timezone="lastStopDateTimezone"
                    permission="update_load_details"
                    label="Last Stop Date/Time"
                    name="last_stop_datetime"
                    :disabled="true" />
                <AppInput
                    v-model="load.seal_number"
                    permission="update_load_details"
                    name="seal_number"
                    label="Seal #"
                    :disabled="loadDisabled"
                    :url="url" />
                <AppSelect
                    name="commodity_type"
                    :model-value="load.commodity_type"
                    permission="update_load_details"
                    :url="url"
                    :options="commodityTypes"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />
                <div class="grid grid-cols-3 items-start gap-1">
                    <AppInput
                        v-model="temperatureValue"
                        permission="update_load_details"
                        name="temperature"
                        :url="url"
                        class="col-span-2"
                        :confirmation="confirmation"
                        :disabled="tempDisabled" />
                    <AppSelect
                        name="temperature_units"
                        label=""
                        class="relative -bottom-6"
                        :model-value="temperatureUnitValue"
                        permission="update_load_details"
                        :url="url"
                        :options="{ c: 'C', f: 'F' }"
                        :confirmation="confirmation"
                        :disabled="tempDisabled" />
                </div>
                <AppInput
                    v-model="load.equipment_length"
                    permission="update_load_details"
                    name="equipment_length"
                    :url="url"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />
                <AppSelect
                    id="rate-type"
                    name="rate_type"
                    :model-value="load.rate_type"
                    permission="update_load_details"
                    :url="url"
                    :options="rateTypes"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />
                <AppSelect
                    id="value-range"
                    name="value_range"
                    label="Load Value"
                    :model-value="load.value_range"
                    permission="update_load_details"
                    :url="url"
                    :options="valueRanges"
                    :disabled="loadDisabled"
                    :confirmation="confirmation" />
                <AppInput
                    v-if="load.customer?.tracking_service === 'fourkites'"
                    v-model="load.fourkites_shipment_id"
                    permission="update_load_details"
                    :url="url"
                    label="FourKites Shipment ID"
                    name="fourkites_shipment_id"
                    :disabled="loadDisabled" />
            </div>
            <AppTextarea
                v-model="load.general_notes"
                permission="update_load_details"
                name="general_notes"
                label="General Notes"
                input-class="resize-none"
                rows="11"
                :url="generalNotesUrl"
                class="xl:col-span-2"
                :disabled="loadDisabled" />
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import { values, zipObject } from 'lodash-es';
import { useLoadDisabled } from '@/helpers';

const page = usePage();
const load = computed(() => page.props.load);

const firstStopDatetime = ref(load.value.stops?.[0]?.stop_window_begins_at ?? null);
const lastStopDatetime = ref(load.value.stops?.[load.value.stops.length - 1]?.stop_window_begins_at ?? null);
const firstStopDateTimezone = ref(load.value.stops?.[0]?.stop_window_begins_at_timezone ?? null);
const lastStopDateTimezone = ref(
    load.value.stops?.[load.value.stops.length - 1]?.stop_window_begins_at_timezone ?? null
);

const equipmentTypes = computed(() =>
    zipObject(values(page.props.options.equipment_types), values(page.props.options.equipment_types))
);

const transportationModes = computed(() =>
    zipObject(values(page.props.options.transportation_modes), values(page.props.options.transportation_modes))
);

const rateTypes = computed(() =>
    zipObject(values(page.props.options.load_rate_types), values(page.props.options.load_rate_types))
);

const commodityTypes = computed(() => page.props.options.commodity_types);

const valueRanges = computed(() =>
    zipObject(values(page.props.options.value_ranges), values(page.props.options.value_ranges))
);

const temperatureValue = computed(() =>
    page.props.load.commodity_type === 'dry' ? null : page.props.load.temperature
);

const temperatureUnitValue = computed(() =>
    page.props.load.commodity_type === 'dry' ? null : page.props.load.temperature_units
);

const url = route('loads.update', page.props.load);
const generalNotesUrl = route('loads.general-notes.update', page.props.load);

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

const formattedLane = computed(() => {
    let { start, end } = page.props.load.lane;
    if (!start.city) {
        return;
    } else {
        return [
            ...(start ? [`${start.city}, ${start.region}`] : []),
            ...(end ? [`${end.city}, ${end.region}`] : [])
        ].join(' → ');
    }
});

const tripMilesError = computed(() => {
    if (page.props.load.trip_miles || page.props.load.stops.length < 2) return null;
    return 'We are unable to calculate because one of the stops is invalid.';
});

const loadDisabled = useLoadDisabled(page.props.load);

const tempDisabled = computed(() => {
    if (page.props.load.is_tonu || page.props.load.is_canceled || page.props.load.commodity_type === 'dry') {
        return true;
    } else {
        return false;
    }
});
</script>
