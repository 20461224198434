<template>
    <form
        class="grid gap-5"
        @submit.prevent="handleSubmit">
        <AppInput
            v-model="form.commodity"
            name="commodity"
            required />
        <AppSelect
            id="flag-transportation-mode"
            v-model="form.transportation_mode"
            name="transportation_mode"
            :model-value="form.transportation_mode"
            :options="transportationModes"
            required />
        <AppSelect
            id="flag-equipment-type"
            v-model="form.equipment_type"
            name="equipment_type"
            :model-value="form.equipment_type"
            :options="equipmentTypes"
            required />
        <AppInput
            v-model="form.amount"
            name="load_value"
            type="number"
            placeholder="$0.00"
            :min="0"
            step=".01"
            required />
        <AppInput
            v-model="form.estimated_cost"
            name="estimated_cost"
            type="number"
            placeholder="$0.00"
            :min="0"
            step=".01"
            :disabled="true"
            required />
        <div v-if="form.hasErrors">
            <ul>
                <li
                    v-for="(message, index) in form.errors"
                    :key="index"
                    class="text-iel-red">
                    {{ message }}
                </li>
            </ul>
        </div>
        <div class="flex items-center justify-end gap-4">
            <AppButton
                variant="outline"
                type="button"
                @click="cancel">
                Cancel
            </AppButton>
            <AppButton type="submit">Submit</AppButton>
        </div>
    </form>
</template>

<script setup>
import { watch, onMounted } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { values, zipObject } from 'lodash-es';
import Swal from 'sweetalert2';

const props = defineProps({
    load: Object,
    options: Object
});

const equipmentTypes = computed(() =>
    zipObject(values(props.options.equipment_types), values(props.options.equipment_types))
);

const transportationModes = computed(() =>
    zipObject(values(props.options.transportation_modes), values(props.options.transportation_modes))
);

const form = useForm({
    commodity: null,
    amount_currency: 'USD',
    transportation_mode: null,
    equipment_type: null,
    amount: null,
    estimated_cost: null
});

onMounted(() => {
    form.reset();
    form.clearErrors();

    form.transportation_mode = props.load.transportation_mode;
    form.equipment_type = props.load.required_equipment_type;
});

watch(
    () => form.amount,
    value => {
        if (value) {
            form.estimated_cost = (value * 0.009).toFixed(2);
        } else {
            form.estimated_cost = null;
        }
    }
);

provide('form', form);

const emit = defineEmits(['cancel', 'success']);

function cancel() {
    form.reset();
    form.clearErrors();
    emit('cancel');
}

function handleSubmit() {
    form.post(route('loads.all-risk.store', props.load), {
        preserveScroll: true,
        onSuccess() {
            emit('success');
            if (form.amount > 1000000) {
                return Swal.fire({
                    title: `Load Value Exceeds $1,000,000`,
                    html: `<div class="flex justify-center">
                <p class="text-center">Flag submitted successfully.<br>
                    For loads above $1,000,000, please reach out to the claims department to provide further details.
                </p>
            </div>`,
                    icon: 'warning'
                });
            }
        }
    });
}
</script>
