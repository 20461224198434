<template>
    <div class="p-2">
        <div
            v-if="icon || title"
            class="flex gap-2">
            <AppIcon
                v-if="icon"
                :name="icon" />
            <p
                v-else
                class="text-sm font-semibold italic">
                {{ title }}
            </p>
        </div>

        <p>{{ body }}</p>

        <div class="flex gap-4 text-xs text-iel-gray">
            <p v-if="user">{{ user }}</p>

            <div
                v-if="isText"
                class="flex gap-2">
                <p>To: {{ textRecipient }}</p>
                <p>{{ message.to }}</p>
            </div>
            <time :datetime="createdAt">
                {{ formatDate(createdAt) }}
            </time>
        </div>
    </div>
</template>

<script setup>
import { formatDate } from '@/helpers.js';

const props = defineProps({
    user: String,
    title: String,
    body: String,
    createdAt: String,
    icon: String,
    isText: Boolean,
    message: Object,
    load: Object,
    activeTab: String
});

const textRecipient = computed(() => {
    if (props.activeTab === 'Dispatcher') {
        return props.load?.dispatcher?.name;
    }
    if (props.activeTab === 'Driver') {
        return props.load?.driver?.name;
    }
    if (props.activeTab === 'Driver 2') {
        return props.load?.driver2?.name;
    }
    return null;
});

const { user, title, body, createdAt } = computed(() => {
    const { isText, message, user, title, body, createdAt } = props;

    return {
        user: isText ? message?.user?.name : user,
        title: isText ? '' : title,
        body: isText ? message?.body : body,
        createdAt: createdAt
    };
}).value;
</script>
