<template>
    <div class="my-2 grid grid-cols-2 px-4 lg:grid-cols-3">
        <LoadLegacyAccessorialChargesTable
            class="col-span-2 max-w-screen-xl"
            v-bind="{ load: load }" />
        <LoadLegacyAccessorialChargesTotal
            v-bind="{ load }"
            class="col-span-1" />
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();
const load = computed(() => page.props.load);
</script>
