<template>
    <Head title="Dashboard" />
    <div class="grid max-w-[1750px] grid-cols-1 gap-2 p-3 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        <AppCard
            title="Today's Calls"
            theme="light_gray"
            class="col-span-2 min-h-[21rem]"
            icon="fal fa-bell"
            content-class="p-2">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index', { filter: 'today' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t("Today's Calls") }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphTodaysCalls title="Todays Calls" />
        </AppCard>

        <AppCard
            title="All Loads"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-grid">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index')"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Loadboard') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphAllLoads title="All Loads" />
        </AppCard>
        <AppCard
            title="Today's Loads"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            icon="far fa-calendar-day"
            content-class="p-2">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index', { filter: 'today' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t("Today's Loads") }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphTodaysLoads title="Todays Loads" />
        </AppCard>
        <AppCard
            title="Needs Attention"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            icon="fal fa-warning"
            content-class="p-2">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index', { filter: 'past-due' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Needs Attention') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphNeedsAttention title="Needs Attention" />
        </AppCard>
        <AppCard
            title="In Transit"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            icon="fal fa-truck"
            content-class="p-2">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index', { filter: 'transit' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('In Transit Loads') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphTransit title="In Transit" />
        </AppCard>
        <AppCard
            title="Future"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-clipboard-list">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index', { filter: 'future' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Future Loads') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphFuture title="Future Loads" />
        </AppCard>
        <AppCard
            title="Billing"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-circle-dollar">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('loads.index', { filter: 'billing' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Billing Loads') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
            <DashboardGraphBilling title="Billing" />
        </AppCard>
        <AppCard
            title="Flagged Loads"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-solid fa-flag">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('flags.index')"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Flagged') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
        </AppCard>
        <AppCard
            title="Claims Flagged Loads"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-solid fa-gavel">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('flags.index', { filter: 'claims' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Flagged') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
        </AppCard>
        <AppCard
            title="Carrier Relations Flagged Loads"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-solid fa-handshake">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('flags.index', { filter: 'incidents' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Flagged') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
        </AppCard>
        <AppCard
            title="Flagged Loads Assigned to Me"
            theme="light_gray"
            card-class="min-h-[21rem] mb-2 md:mb-0"
            content-class="p-2"
            icon="fal fa-solid fa-user">
            <template #nav>
                <div class="flex items-center gap-2">
                    <Link
                        :href="route('flags.index', { filter: 'me' })"
                        class="flex items-center gap-1 whitespace-nowrap rounded-md border border-iel-blue bg-iel-blue px-2 py-0.5 text-sm font-medium text-white">
                        {{ $t('Flagged') }}
                        <AppIcon name="far fa-arrow-right-long" />
                    </Link>
                </div>
            </template>
        </AppCard>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';

const user = computed(() => usePage().props.auth.user);

Sentry.setUser({
    id: user.value.id,
    email: user.value.email,
    username: user.value.name
    // IP Address should be auto grabbed by Sentry, no need to manually specify
});
</script>
