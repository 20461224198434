<template>
    <ul
        v-if="load.notes?.length > 0"
        role="list"
        class="grid max-h-96 gap-2 divide-y divide-iel-light-gray overflow-y-auto"
        tabindex="0">
        <li
            v-for="note in notes"
            :id="`note-${note.id}`"
            :key="note.id">
            <AppMessage
                :user="note.user_name"
                :title="note.title"
                :body="note.content"
                :created-at="note.created_at"
                :icon="note.icon"
                size="lg" />
        </li>
    </ul>

    <div v-else>{{ $t('No notes found on this load.') }}</div>

    <AppButton
        id="load-create-note-button"
        @click="openCreateNoteModal">
        {{ $t('Create Note') }}
    </AppButton>
</template>

<script setup>
const props = defineProps({
    load: Object
});

const initialNoteIds = ref([]);

const noteTypes = {
    claim: { title: 'Claim', icon: 'fas fa-gavel' },
    carrier_relations: { title: 'Carrier Relations', icon: 'fas fa-handshake' },
    task_snooze: { icon: 'fas fa-alarm-snooze' }
};

const notes = computed(() =>
    props.load.notes.map(note => {
        const noteType = noteTypes[note.type] ?? {};

        return {
            ...note,
            icon: note.type ? noteType.icon : null,
            title: note.title ? note.title : noteType.title
        };
    })
);

onMounted(() => (initialNoteIds.value = props.load.notes.map(({ id }) => id)));

function openCreateNoteModal() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'load',
        models: { load: props.load }
    });
}
</script>
