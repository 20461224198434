<template>
    <AppCard
        :title="$t('Documents')"
        theme="light_gray">
        <div class="flex flex-col gap-4">
            <CustomerDocsList v-if="customer.documents?.length > 0">
                <CustomerDocsItem
                    v-for="document in customer.documents"
                    :key="document.id"
                    :customer="customer"
                    :document="document"
                    :document-types="documentTypes" />
            </CustomerDocsList>
            <div
                v-else
                class="text-sm">
                {{ $t('No documents found for this customer.') }}
            </div>
            <AppDocsDragDrop
                :id="`customer-${customer.id}-files-new`"
                v-model="pending"
                permission="upload_customer_documents"
                :accepted-file-types="['application/pdf', 'image/jpeg', 'image/png']" />
            <div
                v-if="pending.length > 0"
                class="flex flex-col">
                <h2 class="text-sm font-bold">Pending Uploads</h2>
                <CustomerDocsList>
                    <CustomerDocsPendingItem
                        v-for="(file, index) in pending"
                        :key="file.name"
                        :customer="customer"
                        :pending-file="file"
                        :document-types="documentTypes"
                        @remove="removePending(index)" />
                </CustomerDocsList>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
defineProps({
    customer: Object,
    documentTypes: Object
});

const pending = ref([]);

function removePending(i) {
    pending.value.splice(i, 1);
}
</script>
