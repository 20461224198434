<template>
    <div
        class="space-y-1"
        data-test="wrapper">
        <AppLabel v-bind="{ name: attributes.name, label: fieldLabel, required }" />
        <div class="w-full sm:flex sm:items-center sm:gap-2">
            <div class="relative flex w-full items-center gap-2">
                <VueDatePicker
                    v-bind="{ ...attributes, ...position }"
                    v-model="datetime"
                    data-test="datetime-picker"
                    placeholder="mm/dd/yyyy HH:mm"
                    :is-24="true"
                    :text-input="{ format: 'MM/dd/yyyy HH:mm' }"
                    :model-type="format"
                    format="MM/dd/yyyy HH:mm"
                    input-class-name="block w-full rounded border-iel-gray/40 text-iel-dark-gray py-1 placeholder:text-iel-gray sm:text-sm"
                    :disabled="unauthorized || props.disabled"
                    @update:model-value="handleChange"
                    @open="resetTimezone">
                    <template #action-extra>
                        <div class="p-2 text-left">
                            <AppSelect
                                v-model="internalTimezone"
                                name="timezone"
                                label="Timezone"
                                :options="timezones"
                                required />
                        </div>
                    </template>
                    <template #clear-icon="{ clear }">
                        <AppIcon
                            name="fal fa-circle-xmark"
                            class="mr-2 block h-5 w-5 text-iel-gray"
                            @click="clear" />
                    </template>
                </VueDatePicker>
                <AppRequestStatus
                    v-if="hasAutoSave && autoSaveForm"
                    v-bind="{
                        recentlySuccessful: autoSaveForm.recentlySuccessful,
                        processing: autoSaveForm.processing,
                        error: autoSaveForm.error
                    }"
                    class="right-4 mr-5 sm:right-2 lg:right-1" />
            </div>
            <div
                class="whitespace-nowrap text-sm"
                :class="{ hidden: hideTimezoneText }">
                {{ timezones[timezone] }}
            </div>
        </div>
        <AppErrorMessage
            :name="attributes.name"
            :error="error" />
    </div>
</template>

<script setup>
import VueDatePicker from '@vuepic/vue-datepicker';
import { timezones as defaultTimezones } from '@/data';
import { useForm } from '@inertiajs/vue3';
import '@vuepic/vue-datepicker/dist/main.css';

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    label: String,
    error: String,
    scope: [String, Number],
    type: {
        type: String,
        default: 'text'
    },
    placeholder: String,
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    url: String,
    confirmation: String,
    format: {
        type: String
    },
    fieldNames: Object,
    timezones: Object,
    enableTimePicker: {
        type: Boolean,
        default: true
    },
    enableTimeZone: {
        type: Boolean,
        default: true
    },
    hideTimezoneText: {
        type: Boolean,
        default: false
    },
    isCentered: {
        type: Boolean,
        default: false
    },
    permission: String
});

const emit = defineEmits(['blur']);

const hasAutoSave = props.url !== undefined;

const form = inject('form', undefined);

const datetime = defineModel('datetime');

const timezone = defineModel('timezone');

const timezones = props.timezones ?? defaultTimezones;

const internalTimezone = ref(timezone.value);

const error = computed(
    () => props.error || form?.errors?.[props.fieldNames?.datetime] || form?.errors?.[props.fieldNames?.timezone]
);

const { label: fieldLabel, attributes } = useField(props, error);

let autoSaveForm;

const { can } = useAuth();
const unauthorized = computed(() => props.permission && !can(props.permission));

function handleChange(selectedDatetime) {
    if (!selectedDatetime) internalTimezone.value = null;

    timezone.value = internalTimezone.value;

    if (!internalTimezone.value && !selectedDatetime) {
        form.errors[props.fieldNames.timezone] = 'Field is required';
        return;
    }

    if (!internalTimezone.value) {
        form.errors[props.fieldNames.timezone] = 'Timezone is required';
        return;
    }

    if (form) form.errors[props.fieldNames.timezone] = null;

    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };

    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(selectedDatetime).replace(',', '');

    datetime.value = formattedDate;

    if (hasAutoSave) {
        autoSaveForm = useForm({
            [props.fieldNames.datetime]: formattedDate,
            [props.fieldNames.timezone]: internalTimezone.value
        });

        autoSaveForm.patch(props.url, {
            preserveScroll: true
        });
    } else {
        form.clearErrors(props.fieldNames.datetime);
        form.clearErrors(props.fieldNames.timezone);
        emit('blur');
    }
}

const resetTimezone = () => {
    internalTimezone.value = timezone.value;
};

const position = computed(() => {
    return props.isCentered ? { 'teleport-center': true } : { teleport: true };
});
</script>

<style>
.dp__icon {
    @apply text-iel-gray;
}
.dp__input {
    color: inherit;
}
.dp__input:hover {
    @apply border-iel-gray;
}
.dp__overlay {
    z-index: 9999;
}
.dp__action_select {
    background-color: var(--dp-primary-color) !important;
}
.dp__pm_am_button {
    color: var(--dp-text-color);
}
</style>
