<template>
    <div class="flex flex-col gap-4">
        <p class="text-lg font-semibold text-iel-dark-gray">{{ $t('Pay Truck Lines') }}</p>

        <div
            v-if="load.customerLineItems.length"
            class="flex flex-col gap-4">
            <LoadBillingLineItem
                v-for="rate in load.customerLineItems"
                :key="rate.id"
                permission="update_load_billing_details"
                :load-id="load.id"
                :line-item="rate"
                :load="load" />
        </div>

        <div
            v-else
            class="text-sm">
            {{ $t('This load has no pay truck line items.') }}
        </div>

        <div class="flex items-center justify-between gap-2">
            <AppButton
                id="load-add-customer-rate-button"
                type="button"
                icon="far fa-circle-plus"
                permission="update_load_billing_details"
                :disable="loadDisabled || Boolean(load?.billingDetail?.invoiced_at)"
                @click="debouncedAddCustomerRate">
                {{ $t('Add Pay Truck Line') }}
            </AppButton>

            <div class="flex gap-2 font-bold">
                <div class="text-right">
                    <AppHeading
                        size="md"
                        class="text-right">
                        {{ $t('Total') }}
                    </AppHeading>
                </div>
                <div>$ {{ load.total_customer_billing_amount }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import { debounce } from 'lodash-es';

const props = defineProps({
    load: {
        type: Object,
        required: true
    }
});

function addCustomerRate() {
    router.post(
        route('loads.billing.store', props.load.id),
        { quantity: 1, amount: 0, line_type: 'Flat Rate', bill_type: 'customer' },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

const debouncedAddCustomerRate = debounce(addCustomerRate, 300);

const loadDisabled = useLoadDisabled(props.load);
</script>
