<template>
    <AppModal
        id="carrier-activation-error-modal"
        :show="true"
        title="Carrier Activation Error"
        class="max-w-xl"
        @close="close"
        @closed="closed">
        <div class="text-center">
            <div>
                {{ error.carrier_verification }}
            </div>
            <div class="mt-3">
                <button
                    type="button"
                    class="rounded-md border border-iel-blue px-4 py-2 text-iel-blue transition-colors duration-200 ease-in-out hover:bg-iel-blue hover:text-white"
                    @click="close">
                    Close
                </button>
            </div>
        </div>
    </AppModal>
</template>

<script setup>
defineProps({
    error: String
});

const emit = defineEmits(['close']);

function close() {
    emit('close');
}
</script>
