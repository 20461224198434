<template>
    <tr @keyup.esc="edit">
        <td>
            <div class="my-2 sm:my-0">
                <div class="flex items-start gap-x-2">
                    <AppInput
                        v-if="editing"
                        v-model="form.file_name"
                        name="file_name"
                        label="Name"
                        required
                        :url="url"
                        class="w-full" />
                    <p
                        v-else
                        class="border border-transparent py-1 text-sm font-semibold leading-6 text-iel-darkest-gray">
                        {{ document.file_name }}
                    </p>
                </div>
                <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-iel-dark-gray">
                    <div>
                        <p class="whitespace-nowrap">
                            <time :datetime="document.updated_at">{{ formatDate(document.updated_at) }}</time>
                        </p>
                        <p class="whitespace-nowrap">
                            <span>By: {{ document.submitted_by }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </td>
        <td>
            <div class="my-2 flex flex-col items-start gap-1 sm:my-0">
                <AppSelect
                    v-if="editing"
                    id="select-label-customer-docs"
                    v-model="form.label"
                    :placeholder="$t('Select Label')"
                    name="label"
                    :options="documentTypes"
                    class="w-full"
                    required
                    :url="url" />
                <p
                    v-else
                    class="mt-0.5 flex whitespace-nowrap rounded-md bg-iel-lightest-green px-1.5 py-0.5 text-sm font-bold text-iel-dark-green ring-1 ring-inset ring-iel-green/20">
                    {{ document.label }}
                </p>
            </div>
        </td>
        <td>
            <div class="my-2 flex gap-x-2 sm:my-0">
                <AppButton
                    :id="`document-list-${document.id}-actions-edit`"
                    variant="outline"
                    permission="update_customer_documents"
                    :icon="editing ? 'far fa-xmark' : 'fal fa-pen'"
                    @click="edit">
                    <span class="sr-only">Edit {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`documents-list-${document.id}-actions-view-document-button`"
                    variant="outline"
                    icon="fal fa-eye"
                    @click="open">
                    <span class="sr-only">View {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-download`"
                    :href="route('customers.documents.download', [props.customer, props.document])"
                    :download="`${document.file_name}`"
                    variant="outline"
                    icon="fal fa-file-arrow-down">
                    <span class="sr-only">Download {{ document.file_name }}</span>
                </AppButton>
                <AppButton
                    :id="`document-list-${document.id}-actions-delete`"
                    variant="outline"
                    class="border-iel-darkest-red text-iel-darkest-red"
                    permission="delete_customer_documents"
                    icon="far fa-trash-can"
                    :disabled="document.loading"
                    @click="confirmDelete(document)">
                    <span class="sr-only">Delete {{ document.file_name }}</span>
                </AppButton>
            </div>
        </td>
    </tr>
</template>

<script setup>
import { router, useForm } from '@inertiajs/vue3';
import { formatDate } from '@/helpers.js';

const props = defineProps({
    customer: Object,
    document: Object,
    documentTypes: Object
});

const form = useForm({
    file_name: props.document.file_name,
    label: props.document.label
});

const url = route('customers.documents.update', [props.customer, props.document]);

provide('form', form);

const editing = ref(false);

function edit() {
    editing.value = !editing.value;
}

function open() {
    usePopup().open(
        route('customers.documents.show', {
            customer: props.customer,
            media: props.document,
            embed: true
        })
    );
}

function confirmDelete() {
    if (confirm(`Are you sure you would like to delete "${props.document.file_name}"?`)) {
        props.document.loading = true;
        router.delete(route('customers.documents.destroy', [props.customer, props.document]), {
            preserveScroll: true
        });
    }
}
</script>
