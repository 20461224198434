<template>
    <div v-if="!load.carrier">
        {{ $t('A carrier has not yet been booked for this load.') }}
    </div>
    <div v-else>
        <div class="@container">
            <div class="grid gap-4 @md:grid-cols-3">
                <div class="grid grid-cols-12 gap-1">
                    <AppDisplayField
                        :label="$t('Carrier Name')"
                        :value="load.carrier?.name"
                        :href="route('carriers.show', load.carrier.id)"
                        class="col-span-11" />
                    <AppDropdown
                        align="bottom-right"
                        content-width="w-80"
                        class="self-end pl-2">
                        <template #trigger>
                            <button
                                class="col-span-1 self-end pb-1 transition duration-300 ease-in-out"
                                type="button"
                                @click="carrierPopoverToggle">
                                <AppIcon
                                    name="far fa-circle-info"
                                    class="h-4 w-4" />
                            </button>
                        </template>
                        <template #content>
                            <div class="flex justify-center gap-3">
                                <div>
                                    <AppHeading
                                        size="sm"
                                        class="pb-2">
                                        {{ $t('Driver ComCheck') }}
                                    </AppHeading>
                                    <AppCheckbox
                                        id="driver-comcheck-accessorials"
                                        v-model="load.carrier.has_driver_accessorials"
                                        permission="update_comcheck_info"
                                        :disabled="true"
                                        name="has_driver_accessorials"
                                        :label="$t('Accessorials')" />
                                    <AppCheckbox
                                        id="driver-comcheck-fuel-advance"
                                        v-model="load.carrier.has_driver_fuel_advance"
                                        permission="update_comcheck_info"
                                        :disabled="true"
                                        name="has_driver_fuel_advance"
                                        :label="$t('Fuel Advance')" />
                                </div>
                                <div
                                    class="via-neutral-500 dark:via-neutral-400 min-h-[1em] w-px self-stretch bg-gradient-to-tr from-transparent to-transparent opacity-25" />
                                <div>
                                    <AppHeading
                                        size="sm"
                                        class="pb-2">
                                        {{ $t('Dispatcher ComCheck') }}
                                    </AppHeading>
                                    <AppCheckbox
                                        id="dispatcher-comcheck-accessorials"
                                        v-model="load.carrier.has_dispatcher_accessorials"
                                        permission="update_comcheck_info"
                                        :disabled="true"
                                        name="has_dispatcher_accessorials"
                                        :label="$t('Accessorials')" />
                                    <AppCheckbox
                                        id="dispatcher-comcheck-fuel-advance"
                                        v-model="load.carrier.has_dispatcher_fuel_advance"
                                        permission="update_comcheck_info"
                                        :disabled="true"
                                        name="has_dispatcher_fuel_advance"
                                        :label="$t('Fuel Advance')" />
                                </div>
                            </div>
                            <div class="flex justify-center py-3">
                                <AppButton @click="changeCarrierAdvanceTerms(load.carrier)">
                                    {{ $t('Request Change') }}
                                </AppButton>
                            </div>
                        </template>
                    </AppDropdown>
                </div>
                <AppDisplayField
                    label="MC Number"
                    :value="load.carrier?.mc_number" />
                <AppDisplayField
                    label="DOT Number"
                    :value="load.carrier?.dot_number" />
            </div>
            <div class="mt-4 grid gap-4 lg:grid-cols-2">
                <div>
                    <div class="flex gap-1">
                        <AppAutocomplete
                            name="dispatcher_id"
                            label="Dispatcher"
                            :model-value="load.dispatcher?.id"
                            permission="update_load_carrier_info"
                            :url="url"
                            :initial-options="
                                load.dispatcher
                                    ? [
                                          {
                                              value: load.dispatcher.id,
                                              display: `${truncatedDispatcherName}  - ${load.dispatcher.phone_number}`
                                          }
                                      ]
                                    : []
                            "
                            :options-url="route('carriers.contacts.autocomplete', load.carrier)"
                            :placeholder="$t('Select a dispatcher...')"
                            :query-params="{ role: 'dispatcher' }"
                            :confirmation="confirmation"
                            class="w-full"
                            :disabled="loadDisabled"
                            allow-adding
                            @add="query => addContact(query, 'dispatcher', 'dispatcher')" />
                        <AppButton
                            class="self-end"
                            icon="fal fa-comment-sms"
                            icon-size="2xl"
                            variant="invisible"
                            :disabled="loadDisabled"
                            permission="update_load_carrier_info"
                            @click="scrollToText('Dispatcher')" />
                    </div>
                    <AppButton
                        v-if="load.dispatcher"
                        class="mt-2"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="editContact(load.dispatcher, 'dispatcher')">
                        Edit
                    </AppButton>
                </div>
                <div>
                    <div class="flex gap-1">
                        <AppAutocomplete
                            name="driver_id"
                            label="Driver"
                            :model-value="load.driver?.id"
                            permission="update_load_carrier_info"
                            :url="url"
                            :initial-options="
                                load.driver
                                    ? [
                                          {
                                              value: load.driver.id,
                                              display: `${truncatedDriver1Name} - ${load.driver.phone_number}`
                                          }
                                      ]
                                    : []
                            "
                            :options-url="route('carriers.contacts.autocomplete', load.carrier)"
                            :placeholder="$t('Select a driver...')"
                            :query-params="{ role: 'driver' }"
                            :confirmation="confirmation"
                            class="w-full"
                            :disabled="loadDisabled"
                            allow-adding
                            @add="query => addContact(query, 'driver', 'driver')" />
                        <AppButton
                            class="self-end"
                            icon="fal fa-comment-sms"
                            icon-size="2xl"
                            variant="invisible"
                            :disabled="loadDisabled"
                            permission="update_load_carrier_info"
                            @click="scrollToText('Driver')" />
                    </div>
                    <AppButton
                        v-if="load.driver"
                        class="mt-2"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="editContact(load.driver, 'driver')">
                        Edit
                    </AppButton>
                </div>
                <AppCheckbox
                    label="Add Second Driver"
                    name="team_load"
                    :model-value="load.team_load"
                    permission="update_load_carrier_info"
                    :url="url"
                    :disabled="loadDisabled"
                    :confirmation="confirmation"
                    class="pt-7" />
                <div>
                    <div class="flex gap-2">
                        <AppAutocomplete
                            v-if="load.team_load"
                            name="driver2_id"
                            label="Driver 2"
                            :model-value="load.driver2?.id"
                            permission="update_load_carrier_info"
                            :url="url"
                            :initial-options="
                                load.driver2
                                    ? [
                                          {
                                              value: load.driver2.id,
                                              display: `${truncatedDriver2Name}  - ${load.driver2.phone_number}`
                                          }
                                      ]
                                    : []
                            "
                            :options-url="route('carriers.contacts.autocomplete', load.carrier)"
                            :placeholder="$t('Select a driver...')"
                            :query-params="{ role: 'driver' }"
                            :confirmation="confirmation"
                            class="w-full"
                            :disabled="loadDisabled"
                            allow-adding
                            @add="query => addContact(query, 'driver', 'driver2')" />
                        <AppButton
                            v-if="load.driver2?.phone_number"
                            class="self-end"
                            icon="fal fa-comment-sms"
                            icon-size="2xl"
                            variant="invisible"
                            :disabled="loadDisabled"
                            permission="update_load_carrier_info"
                            @click="scrollToText('Driver 2')" />
                    </div>
                    <AppButton
                        v-if="load.driver2"
                        class="mt-2"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="editContact(load.driver2, 'driver2')">
                        Edit
                    </AppButton>
                </div>
            </div>
            <div class="mt-4 grid gap-4 @md:grid-cols-2 @xl:grid-cols-4">
                <AppAutocomplete
                    name="tractor_number_id"
                    label="Tractor #"
                    :model-value="load.tractorNumber?.id"
                    permission="update_load_carrier_info"
                    :initial-options="
                        load.tractorNumber
                            ? [
                                  {
                                      value: load.tractorNumber.id,
                                      display: load.tractorNumber.identifier
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :options-url="route('carriers.equipment.autocomplete', load.carrier)"
                    :placeholder="$t('Select a Tractor Number...')"
                    :query-params="{ type: 'tractor' }"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    allow-adding
                    @add="query => addEquipment(query, 'tractor')" />
                <AppAutocomplete
                    name="trailer_number_id"
                    label="Trailer #"
                    :model-value="load.trailerNumber?.id"
                    permission="update_load_carrier_info"
                    :initial-options="
                        load.trailerNumber
                            ? [
                                  {
                                      value: load.trailerNumber.id,
                                      display: load.trailerNumber.identifier
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :options-url="route('carriers.equipment.autocomplete', load.carrier)"
                    :placeholder="$t('Select a Trailer Number...')"
                    :query-params="{ type: 'trailer' }"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    allow-adding
                    @add="query => addEquipment(query, 'trailer')" />
                <AppAutocomplete
                    name="plate_number_id"
                    label="Plate #"
                    :model-value="load.plateNumber?.id"
                    permission="update_load_carrier_info"
                    :initial-options="
                        load.plateNumber
                            ? [
                                  {
                                      value: load.plateNumber.id,
                                      display: load.plateNumber.identifier
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :options-url="route('carriers.equipment.autocomplete', load.carrier)"
                    :placeholder="$t('Select a Plate Number...')"
                    :query-params="{ type: 'plate' }"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    allow-adding
                    @add="query => addEquipment(query, 'plate')" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { usePage, router } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';

const page = usePage();
const load = computed(() => page.props.load);

const url = route('loads.carrier-info.update', page.props.load);

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

function addEquipment(query, equipmentType) {
    router.post(
        route('carrierEquipment.store', load.value.carrier),
        { identifier_type: equipmentType, identifier: query, load_id: load.value.id },
        { preserveScroll: true }
    );
}

const { open: openCarrierContactModal } = useModal('CarrierContactModal');
const { open: openCarrierAdvanceTermsModal } = useModal('CarrierAdvanceTermsModal');

function addContact(query, role, relationship) {
    openCarrierContactModal({
        mode: 'create',
        carrier: load.value.carrier,
        query: query,
        role: role,
        load_id: load.value.id,
        load_relationship: relationship,
        onClose() {
            // TODO after creating a new contact, set the original field to that contact
        }
    });
}

function editContact(contact, relationship) {
    openCarrierContactModal({
        mode: 'edit',
        carrier: load.value.carrier,
        contact: contact,
        load_id: load.value.id,
        load_relationship: relationship
    });
}

function changeCarrierAdvanceTerms(carrier) {
    openCarrierAdvanceTermsModal({
        carrier: carrier,
        load_id: load.value.id
    });
}

function truncateString(str) {
    const adjustedMaxLength = window.innerWidth >= 1024 ? 20 : 5;

    return str.length > adjustedMaxLength ? str.substring(0, adjustedMaxLength) + '...' : str;
}

const truncatedDriver1Name = computed(() => truncateString(load.value.driver?.name));
const truncatedDriver2Name = computed(() => truncateString(load.value.driver2?.name));
const truncatedDispatcherName = computed(() => truncateString(load.value.dispatcher?.name));

function scrollToText() {
    const element = document.getElementById('load-text-messages');
    element.scrollIntoView({ behavior: 'smooth' });
}

const loadDisabled = useLoadDisabled(page.props.load);
</script>
