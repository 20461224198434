<template>
    <Head :title="carrier.name" />
    <AppHeader
        title="Carriers"
        :icon-component="IconCarriers" />
    <AppPanels
        id="carriers-show-toggle"
        :title="carrier.name"
        :sections="{
            information: 'Information',
            insurance: 'Insurance',
            contacts: 'Contacts',
            fmcsa: 'FMCSA',
            remit: 'Remit Info',
            documents: 'Documents',
            equipment: 'Equipment'
        }">
        <template #nav>
            <form
                id="toggle-active-inactive-form"
                @submit.prevent="submit">
                <AppToggle
                    v-model="form.is_active"
                    permission="toggle_carrier"
                    :label="['Active', 'Inactive']"
                    class="font-bold uppercase text-white"
                    @click="toggleIsActive"
                    @change="submit" />
            </form>
        </template>

        <template #information>
            <CarrierInformationCard />
        </template>
        <template #contacts>
            <CarrierContactsCard />
        </template>
        <template #fmcsa>
            <CarrierFmcsaCard :carrier="carrier" />
        </template>
        <template #insurance>
            <CarrierInsuranceCard />
        </template>
        <template #remit>
            <CarrierRemitInfoCard />
        </template>
        <template #documents>
            <AppCard
                :title="$t('Documents')"
                theme="white"
                content-class="p-2 @container"
                class="grid">
                <CarrierDocsCard
                    :carrier="$page.props.carrier"
                    :document-types="documentTypes" />
            </AppCard>
        </template>
        <template #equipment>
            <CarrierEquipmentCard />
        </template>
        <div>{{ props }}</div>
    </AppPanels>
    <CarrierActivationErrorModal
        v-if="activationError"
        :error="activationError"
        @close="activationError = ''" />
</template>

<script setup>
import { useForm, usePage, router } from '@inertiajs/vue3';
import IconCarriers from '/resources/img/icon-carriers.svg';
import { values, zipObject } from 'lodash-es';
import { ref } from 'vue';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { Toast } from '@/Composables/useSwal.js';

defineProps({
    carrier: Object
});

const page = usePage();

const activationError = ref('');

const form = useForm({
    is_active: page.props.carrier.is_active
});

provide('form', form);

function toggleIsActive(event) {
    if (!confirm("Are you sure you want to change this carrier's status?")) event.preventDefault();
}

function submit() {
    form.patch(route('carriers.status.update', page.props.carrier.id), {
        onError: error => {
            activationError.value = error;
        },
        onFinish: () => {
            form.reset('is_active');
        }
    });
}

const documentTypes = computed(() =>
    zipObject(values(page.props.options.documentTypes), values(page.props.options.documentTypes))
);

window.Pusher = Pusher;

const laravelEcho = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_CLIENT_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
});

laravelEcho.private(`carriers.${page.props.carrier.id}`).listen('MacropointPartnerStatusStored', payload => {
    router.reload({
        preserveScroll: true,
        only: ['carrier']
    });
    Toast.fire({
        title: payload.response.message,
        icon: payload.response.result
    });
});
</script>
