<template>
    <div v-if="tabs.length">
        <div
            id="load-text-messages"
            class="flex items-center">
            <div
                v-for="tab in tabs"
                :key="tab"
                class="text-md mx-1 mb-4 cursor-pointer border-b-2"
                :class="
                    activeTab === tab.key
                        ? ' border-iel-blue  font-bold text-iel-blue'
                        : 'border-transparent text-iel-gray'
                "
                @click="activeTab = tab.key">
                {{ tab.label }}
            </div>
        </div>
        <div>
            <ul
                v-if="messages.length > 0"
                role="list"
                class="my-4 max-h-96 space-y-2 overflow-y-auto px-1"
                tabindex="0">
                <li
                    v-for="message in messages"
                    :key="message.id">
                    <AppMessage
                        :is-text="true"
                        :load="page.props.load"
                        :active-tab="activeTab"
                        icon="fal fa-sms"
                        :message="message"
                        :created-at="message.created_at"
                        size="md" />
                </li>
            </ul>
            <div v-else>No messages sent to {{ people[activeTab] }}.</div>
            <AppButton
                icon="fal fa-sms"
                class="my-2"
                :disabled="loadDisabled"
                @click="showComposeForm = true">
                {{ $t('Send Text') }}
            </AppButton>
            <form
                v-if="showComposeForm"
                class="mb-4 rounded-md border bg-white p-2 @container"
                @submit.prevent="sendMessage">
                <p class="text-sm md:text-base">{{ $t('New Message') }}</p>
                <div class="grid gap-2">
                    <AppDisplayField
                        :value="recipient"
                        name="phone_number"
                        label="Number" />
                    <AppTextarea
                        v-model="form.message"
                        name="message"
                        label="Message"
                        required />
                </div>
                <div class="my-2 flex w-full gap-x-2">
                    <AppButton
                        id="load-create-message-add-button"
                        type="submit"
                        :disabled="form.processing">
                        {{ $t('Send') }}
                    </AppButton>
                    <AppButton
                        id="load-create-message-cancel-button"
                        type="submit"
                        variant="outline"
                        @click.prevent="showComposeForm = false">
                        {{ $t('Cancel') }}
                    </AppButton>
                </div>
            </form>
        </div>
    </div>
    <div v-else>
        The load currently has no assigned driver or dispatcher. Please select one to enable text message communication.
    </div>
</template>

<script setup>
import { usePage, useForm } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';

const page = usePage();
const activeTab = ref();
const people = { dispatcher: 'Dispatcher', driver: 'Driver', driver2: 'Driver 2' };
const showComposeForm = ref(false);
const loadDisabled = useLoadDisabled(page.props.load);

const form = useForm({
    message: ''
});

const tabs = computed(() => {
    const array = [];

    Object.keys(people).forEach(person => {
        if (page.props.load[person]?.phone_number) {
            array.push({ key: person, label: people[person] });
        }
    });

    return array;
});

const messages = computed(() => {
    return page.props.load.messages.filter(message => {
        return message.to === page.props.load[activeTab.value]?.phone_number;
    });
});

const recipient = computed(() => {
    return page.props.load[activeTab.value]?.phone_number;
});

function sendMessage() {
    form.transform(data => Object.assign(data, { phone_number: recipient.value })).post(
        route('messages.send', page.props.load),
        {
            preserveScroll: true,
            onSuccess: () => {
                showComposeForm.value = false;
                form.reset();
            }
        }
    );
}

onMounted(() => {
    if (tabs.value.length) activeTab.value = tabs.value[0].key;
});
</script>
