<template>
    <div class="-mb-4 -mr-4 -mt-4 w-96 shrink-0 bg-iel-dark-gray p-4">
        <div class="flex h-full flex-col gap-8 rounded-md bg-white p-4">
            <div class="flex gap-4">
                <LoadSummaryFinancesBox
                    label="Profit"
                    :value="data.profit" />
                <LoadSummaryFinancesBox
                    label="Margin"
                    :value="data.margin" />
            </div>
            <div class="flex flex-col gap-4">
                <div class="flex items-center justify-between">
                    <div class="text-xl font-bold">Carrier Pay</div>
                    <button
                        class="rounded-full border border-iel-gray p-2"
                        title="Send Carrier RateCon"
                        @click="goToPlanSendCarrierRateCon">
                        <AppIcon
                            name="far fa-paper-plane"
                            class="block size-5" />
                    </button>
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <LoadSummaryFinancesBox
                        label="Pay"
                        :value="data.carrierPay" />
                    <LoadSummaryFinancesBox
                        label="Min. Pay Amount"
                        :value="data.carrierMinPay" />
                    <LoadSummaryFinancesBox
                        label="Max. Pay Amount"
                        :value="data.carrierMaxPay" />
                </div>
                <div class="grid grid-cols-3 gap-4">
                    <LoadSummaryFinancesBox
                        label="Accesorials"
                        :value="data.carrierAccesorials" />
                    <LoadSummaryFinancesBox
                        label="Advances"
                        :value="data.carrierAdvances" />
                    <LoadSummaryFinancesBox
                        label="3rd Party"
                        :value="data.carrierAdvancesThirdParty" />
                </div>
                <LoadSummaryFinancesBox
                    label="Total Carrier Pay"
                    :value="data.carrierTotalPay" />
            </div>

            <div class="flex flex-col gap-4">
                <div class="text-xl font-bold">Customer Billing</div>
                <div class="grid grid-cols-2 gap-4">
                    <LoadSummaryFinancesBox
                        label="Billing"
                        :value="data.customerBilling" />
                    <LoadSummaryFinancesBox
                        label="Accesorials"
                        :value="data.customerAccesorials" />
                </div>
                <LoadSummaryFinancesBox
                    label="Total Customer Billing"
                    :value="data.customerBillingTotal" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { mapValues } from 'lodash-es';
import { formatMoney } from '@/helpers';

const props = defineProps({
    load: Object
});

const loadStats = useLoadStats(props.load);

const data = computed(() =>
    mapValues(loadStats, (stat, key) => {
        return key === 'margin' ? stat.value.toFixed(2) + '%' : formatMoney(stat.value);
    })
);

function goToPlanSendCarrierRateCon() {
    useLoadStepsStore(props.load.id).setStep('plan.send_carrier_rate_con');
}
</script>
