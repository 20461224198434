<template>
    <AppCard
        title="Information"
        theme="light_gray"
        content-class="p-2">
        <div class="grid grid-cols-[2fr,1fr] gap-2">
            <AppInput
                id="customer-infocard-name"
                v-model="customer.name"
                name="name"
                permission="update_customer_information"
                :url="url" />
            <AppInput
                id="customer-infocard-duns"
                v-model="customer.duns"
                name="duns"
                permission="update_customer_information"
                label="D-U-N-S"
                :url="url" />
        </div>
        <CustomerAddressCard class="my-2" />
        <div class="grid gap-2 md:grid-cols-3">
            <AppInput
                id="customer-infocard-phone"
                v-model="customer.phone_number"
                name="phone_number"
                label="Phone Number"
                permission="update_customer_information"
                :url="url" />
            <AppInput
                id="customer-infocard-fax"
                v-model="customer.fax_number"
                name="fax_number"
                label="Fax Number"
                permission="update_customer_information"
                :url="url" />
            <AppInput
                id="customer-infocard-email"
                v-model="customer.email_address"
                name="email_address"
                label="Email Address"
                permission="update_customer_information"
                :url="url" />
        </div>
        <div
            v-if="!customer.is_active"
            class="grid place-content-end pt-2">
            <AppButton
                id="activate-button"
                icon="far fa-truck"
                :disabled="customer.open_tickets.includes('Customer Activation')"
                @click="openCustomerActivationModal(customer)">
                {{ $t('Activate') }}
            </AppButton>
        </div>
    </AppCard>
</template>

<script setup>
import { useForm, usePage } from '@inertiajs/vue3';

const page = usePage();

const url = route('customers.update', page.props.customer);

const customer = computed(() => page.props.customer);

const form = useForm({
    name: page.props.customer?.name,
    duns: page.props.customer.duns,
    phone_number: page.props.customer.phone_number,
    fax_number: page.props.customer.fax,
    email_address: page.props.customer.email_address
});

provide('form', form);

const { open: CustomerActivationModal } = useModal('CustomerActivationModal');

const openCustomerActivationModal = customer => {
    CustomerActivationModal({
        modalId: 'customer-activation-modal',
        title: 'Request Customer Activation',
        ticketType: 'Customer Activation',
        customer: customer
    });
};
</script>
