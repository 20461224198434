<template>
    <div>
        <AppHeading
            size="sm"
            class="pb-2 text-iel-gray">
            {{ $t('Request Notes') }}
        </AppHeading>

        <div class="flex justify-end pr-1">
            <AppButton
                id="load-create-note-button"
                icon="far fa-circle-plus"
                :disabled="!request.can_create_note"
                :permission="permissions.create"
                @click="openCreateNoteModal">
                {{ $t('Create Note') }}
            </AppButton>
        </div>

        <ul
            v-if="request.notes.length > 0"
            role="list"
            class="my-4 max-h-96 space-y-2 overflow-y-auto px-1"
            tabindex="0">
            <li
                v-for="note in notes"
                :id="`note-${note.id}`"
                :key="note.id">
                <AppMessage
                    :user="note.user_name"
                    :title="note.title"
                    :body="note.content"
                    :created-at="note.created_at"
                    :icon="note.icon"
                    size="md" />
            </li>
        </ul>

        <div v-else>{{ $t('No notes found on this request.') }}</div>
    </div>
</template>

<script setup>
const props = defineProps({
    request: Object,
    permissions: {
        type: Object,
        default: () => ({})
    }
});

const emit = defineEmits(['success']);

const notes = computed(() => props.request.notes);

function openCreateNoteModal() {
    useModal('AppNoteCreateModal').open({
        endpoint: 'tickets',
        models: { request: props.request, ticket: props.request },
        onClose() {
            emit('success');
        }
    });
}
</script>
