<template>
    <summary
        class="flex w-full cursor-pointer select-none flex-col items-start gap-2 p-2 text-left text-sm lg:flex-row lg:items-center lg:justify-between lg:py-1">
        <div class="flex w-full flex-col items-start gap-2 lg:grid lg:grid-cols-12 lg:justify-between lg:py-1">
            <div class="flex gap-4 whitespace-normal lg:col-span-3 lg:gap-2">
                <AppIcon
                    name="far fa-bars"
                    class="handle h-5 w-5 cursor-move text-iel-gray" />
                <div class="flex truncate">
                    <p
                        class="lg:whitespace-wrap truncate whitespace-normal lg:col-span-4"
                        :class="{ 'text-iel-red': !stop.location.address.validated_at }">
                        <span class="mr-1 font-semibold">{{ $t('Stop #') }} {{ stop.order }}</span>
                        <span class="uppercase">{{ stop.type }}</span>
                    </p>
                </div>
            </div>
            <p
                class="lg:whitespace-wrap truncate whitespace-normal lg:col-span-4"
                :class="{ 'text-iel-red': !stop.location.address.validated_at }">
                <span class="mr-1 font-semibold">{{ stop.location.name }}</span>
                {{ stop.location.address.city }},
                {{ stop.location.address.region }}
            </p>
            <p class="flex gap-x-4 lg:col-span-5">
                {{ stop.stop_window_begins_at }}
                <span class="font-semibold">APPT. #{{ stop.appointment_number }}</span>
            </p>
        </div>
        <div class="flex items-center gap-4">
            <p
                v-if="stop.order === load.nextStop?.number"
                class="whitespace-nowrap"
                :class="trackingStatusColor">
                {{ load.nextStop.status }}
            </p>
            <AppButton
                :id="`load-delete-stop-${stop.id}-button`"
                variant="outline"
                icon="far fa-trash-can"
                permission="update_stops"
                :disabled="loadDisabled"
                @click.stop="deleteStop(stop.id)">
                Delete
            </AppButton>
        </div>
    </summary>

    <section class="grid grid-cols-12 items-center gap-2 p-2">
        <AppRadioGroup
            name="type"
            :url="url"
            label=""
            :scope="stop.id"
            :model-value="stop.type"
            permission="update_stops"
            inline
            :options="[
                { value: 'pickup', label: 'Pickup' },
                { value: 'dropoff', label: 'Dropoff' }
            ]"
            :disabled="loadDisabled"
            :confirmation="confirmation"
            class="col-span-2" />
        <div class="col-span-3">
            <AppAutocomplete
                v-model="stop.location.id"
                name="location_id"
                label="Stop Location"
                permission="update_stops"
                :scope="stop.id"
                :url="url"
                :initial-options="[{ value: stop.location.id, display: stop.location.name }]"
                :options-url="route('locations.autocomplete')"
                :placeholder="$t('Search for a location...')"
                :disabled="loadDisabled"
                :confirmation="confirmation" />
            <!-- <Link
                :href="route('locations.show', stop.location.id)"
                class="inline-flex items-center gap-2 mt-1 text-sm underline transition group hover:text-iel-gray/90">
                View {{ stop.location.name }}
                <AppIcon
                    name="far fa-arrow-right"
                    class="w-3 h-3 transition group-hover:translate-x-2" />
            </Link> -->
        </div>
        <AppDisplayField
            label="Stop Address"
            :value="fullAddress"
            class="col-span-4"
            :error="!stop.location.address.validated_at && 'Invalid address'" />
        <AppSelect
            :scope="stop.id"
            label="Stop Contacts"
            name="contact_id"
            :model-value="stop.contact?.id"
            permission="update_stops"
            :url="url"
            :disabled="loadDisabled"
            class="mb-auto">
            <optgroup
                v-if="stop.location.primaryContact"
                label="Location Contact">
                <option :value="stop.location.primaryContact?.id">
                    {{ stop.location.primaryContact?.name }} {{ stop.location.primaryContact?.phone_number }}
                </option>
            </optgroup>

            <optgroup
                v-if="stop.location.shippingContacts"
                label="Shippers">
                <option
                    v-for="contact in stop.location.shippingContacts"
                    :key="contact.id"
                    :value="contact.id">
                    {{ contact.name }} {{ contact.phone_number }}
                </option>
            </optgroup>

            <optgroup
                v-if="stop.location.receivingContacts"
                label="Receivers">
                <option
                    v-for="contact in stop.location.receivingContacts"
                    :key="contact.id"
                    :value="contact.id">
                    {{ contact.name }} {{ contact.phone_number }}
                </option>
            </optgroup>
        </AppSelect>
        <AppInput
            v-model="stop.appointment_number"
            permission="update_stops"
            name="appointment_number"
            :scope="stop.id"
            :url="url"
            :disabled="loadDisabled"
            label="Appointment #" />
        <AppDisplayField
            label="Mi. to Next Stop"
            :value="stop.miles_to_next_stop?.toLocaleString()"
            :error="milesToNextStopError" />
    </section>

    <section class="grid grid-cols-12 items-center gap-2 p-2">
        <div class="col-span-2 flex items-end gap-2">
            <AppInput
                v-model="stop.weight"
                permission="update_stops"
                :scope="stop.id"
                name="weight"
                :disabled="loadDisabled"
                :url="url" />
            <AppSelect
                name="weight_unit"
                label=""
                permission="update_stops"
                :scope="stop.id"
                :model-value="stop.weight_unit"
                :disabled="loadDisabled"
                :url="url"
                :options="$page.props.units.weight" />
        </div>
        <div class="col-span-2 flex items-end gap-2">
            <AppInput
                v-model="stop.volume"
                permission="update_stops"
                :scope="stop.id"
                name="volume"
                :disabled="loadDisabled"
                :url="url" />
            <AppSelect
                name="volume_unit"
                label=""
                permission="update_stops"
                :model-value="stop.volume_unit"
                :disabled="loadDisabled"
                :url="url"
                :scope="stop.id"
                :options="$page.props.units.volume" />
        </div>
        <div class="col-span-2 flex items-end gap-2">
            <AppInput
                :id="`load-${load.id}-stop-${stop.id}-pieces`"
                v-model="stop.pieces"
                permission="update_stops"
                :name="`load-${load.id}-stop-${stop.id}-pieces`"
                label="Pieces"
                :disabled="loadDisabled"
                :url="url" />
            <AppSelect
                name="pieces_unit"
                label=""
                :model-value="stop.pieces_unit"
                permission="update_stops"
                :disabled="loadDisabled"
                :url="url"
                :scope="stop.id"
                :options="$page.props.units.pieces" />
        </div>
        <AppInput
            v-model="stop.pallets"
            permission="update_stops"
            :scope="stop.id"
            name="pallets"
            :disabled="loadDisabled"
            :url="url" />
        <!-- Timing -->
        <!-- <AppRadioGroup
            name="has_stop_window"
            label="Type"
            inline
            class="mx-auto"
            :scope="stop.id"
            :model-value="stop.has_stop_window"
            :url="url"
            permission="update_stops"
            :disabled="loadDisabled"
            :confirmation="confirmation"
            :options="[
                { value: true, label: 'FCFS' },
                { value: false, label: 'Appt' }
            ]" />
        <AppDateTime
            v-model:datetime="stop.stop_window_begins_at"
            v-model:timezone="stop.stop_window_begins_at_timezone"
            permission="update_stops"
            name="stop_window_begins_at"
            :scope="stop.id"
            :label="`Stop Plan Arrival ${stop.has_stop_window ? 'Window Start' : ''}`"
            :field-names="{ datetime: 'stop_window_begins_at', timezone: 'stop_window_begins_at_timezone' }"
            :url="url"
            :disabled="loadDisabled"
            :confirmation="confirmation"
            class="col-span-2" />
        <template v-if="stop.has_stop_window">
            <AppDateTime
                v-model:datetime="stop.stop_window_ends_at"
                v-model:timezone="stop.stop_window_ends_at_timezone"
                permission="update_stops"
                name="stop_window_ends_at"
                :scope="stop.id"
                :label="`Stop Plan Arrival ${stop.has_stop_window ? 'Window End' : ''}`"
                :field-names="{ datetime: 'stop_window_ends_at', timezone: 'stop_window_ends_at_timezone' }"
                :url="url"
                :disabled="loadDisabled"
                :confirmation="confirmation"
                class="col-span-2" />
        </template> -->
    </section>

    <!-- References -->
    <section class="p-2">
        <div
            v-for="(reference, i) in form.references"
            :id="`load-${load.id}-stop-${stop.id}-references`"
            :key="i"
            class="grid grid-cols-12 items-end gap-2">
            <div class="relative col-span-3">
                <AppInput
                    v-model="reference.number"
                    permission="update_stops"
                    name="reference_number"
                    :disabled="loadDisabled"
                    :scope="stop.id"
                    :error="form.errors[`references.${i}.number`]"
                    @blur="submitReferences(`${i}.number`)" />
                <AppRequestStatus
                    v-if="lastSubmittedReferenceField === `${i}.number`"
                    class="!right-2 -bottom-6"
                    :processing="form.processing"
                    :recently-successful="form.recentlySuccessful"
                    :error="form.errors[`references.${i}.number`]" />
            </div>
            <div class="relative col-span-4">
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-reference-description-${i}`"
                    v-model="reference.description"
                    permission="update_stops"
                    name="reference_description"
                    :scope="stop.id"
                    :options="{
                        BOL: 'BOL',
                        'Delivery Number': 'Delivery Number',
                        PRO: 'PRO',
                        SAP: 'SAP',
                        'Customer Reference': 'Customer Reference',
                        'Freight Agreement Number': 'Freight Agreement Number',
                        'Purchase Order Number': 'Purchase Order Number',
                        'Release Number': 'Release Number',
                        Other: 'Other'
                    }"
                    :error="form.errors[`references.${i}.description`]"
                    :disabled="loadDisabled"
                    @change="submitReferences(`${i}.description`)" />
                <AppRequestStatus
                    v-if="lastSubmittedReferenceField === `${i}.description`"
                    class="!right-2 -bottom-6"
                    :processing="form.processing"
                    :recently-successful="form.recentlySuccessful"
                    :error="form.errors[`references.${i}.description`]" />
            </div>
            <div
                v-if="reference.description === 'Other'"
                class="relative col-span-4">
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-other-reference-description-${i}`"
                    v-model="reference.other"
                    permission="update_stops"
                    name="reference_description_other"
                    :disabled="loadDisabled"
                    :error="form.errors[`references.${i}.other`]"
                    @blur="submitReferences(`${i}.other`)" />
                <AppRequestStatus
                    v-if="lastSubmittedReferenceField === `${i}.other`"
                    class="!right-2 -bottom-6"
                    :processing="form.processing"
                    :recently-successful="form.recentlySuccessful"
                    :error="form.errors[`references.${i}.other`]" />
            </div>
            <button
                v-if="form.references.length > 1"
                id="stop-reference-delete-button"
                type="button"
                :disabled="loadDisabled"
                class="mb-1 flex h-5 w-5 items-center justify-center self-end rounded-full border border-iel-blue p-1 text-iel-blue hover:bg-iel-silver"
                @click="removeReference(i)">
                <AppIcon
                    name="far fa-minus"
                    class="h-4 w-4" />
            </button>
        </div>
        <AppButton
            :id="`load-${load.id}-stop-${stop.id}-add-stop-reference-button`"
            permission="update_stops"
            :disabled="loadDisabled"
            class="mt-2"
            icon="fal fa-pen"
            @click="addReference()">
            {{ $t('New Reference') }}
        </AppButton>
    </section>

    <!-- Responsibility -->
    <section class="grid grid-cols-12 items-center gap-2 p-2">
        <h2 class="col-span-2 font-bold text-iel-blue">{{ $t('Carrier Responsible For') }}</h2>
        <AppRadioGroup
            name="is_pallet_exchange"
            label="Pallet Exchange"
            :scope="stop.id"
            :model-value="stop.is_pallet_exchange"
            permission="update_stops"
            :url="url"
            :disabled="loadDisabled"
            :confirmation="confirmation"
            :options="[
                { value: true, label: 'Yes' },
                { value: false, label: 'No' }
            ]"
            inline
            class="col-span-2" />
        <AppRadioGroup
            name="has_unloading_charges"
            label="Unloading Charges"
            :scope="stop.id"
            :model-value="Number(stop.has_unloading_charges)"
            :url="url"
            permission="update_stops"
            :disabled="loadDisabled"
            :confirmation="confirmation"
            :options="[
                { value: 1, label: 'Yes' },
                { value: 0, label: 'No' }
            ]"
            inline
            class="col-span-2" />
        <AppTextarea
            :model-value="stop.note?.content"
            permission="update_stops"
            :scope="stop.id"
            :label="$t('Ratecon Special Instructions')"
            name="note"
            :url="url"
            :disabled="loadDisabled"
            class="col-span-6 resize-none"
            rows="7" />
    </section>

    <!-- Line Items -->
    <section class="p-2">
        <h2 class="font-bold text-iel-blue">{{ $t('Line Items') }}</h2>
        <ul
            v-if="stop.line_items.length > 0"
            :id="`load-${page.props.load.id}-stop-${stop.id}-line-items`">
            <li
                v-for="item in stop.line_items"
                :id="`line-item-${item.id}`"
                :key="item.id"
                class="mt-2 grid items-end gap-2 pt-8 first-of-type:pt-0 lg:grid-cols-3 xl:grid-cols-12 xl:pt-0">
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-shipment-reference-number-${item.id}`"
                    v-model="item.shipment_reference_number"
                    permission="update_stops"
                    name="shipment_reference_number"
                    :scope="stop.id"
                    label="Shipment Ref#"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-shipment-po-${item.id}`"
                    v-model="item.shipment_po"
                    permission="update_stops"
                    name="shipment_po"
                    :scope="stop.id"
                    label="Shipment PO"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-weight-${item.id}`"
                    v-model="item.weight"
                    permission="update_stops"
                    name="weight"
                    :scope="stop.id"
                    label="Weight"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-weight-unit-${item.id}`"
                    name="weight_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :model-value="item.weight_unit"
                    :options="$page.props.units.weight"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-volume-${item.id}`"
                    v-model="item.volume"
                    permission="update_stops"
                    name="volume"
                    :scope="stop.id"
                    label="Volume"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-volume-unit-${item.id}`"
                    name="volume_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :model-value="item.volume_unit"
                    :options="$page.props.units.volume"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-pieces-${item.id}`"
                    v-model="item.pieces"
                    permission="update_stops"
                    name="pieces"
                    :scope="stop.id"
                    label="Pieces"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)" />
                <AppSelect
                    :id="`load-${load.id}-stop-${stop.id}-piece-unit-${item.id}`"
                    name="pieces_unit"
                    :scope="stop.id"
                    label="Unit"
                    class="min-w-[theme('spacing.14')]"
                    permission="update_stops"
                    :disabled="loadDisabled"
                    :model-value="item.pieces_unit"
                    :options="$page.props.units.pieces"
                    :url="route('stops.lineItems.update', item)" />
                <AppInput
                    :id="`load-${load.id}-stop-${stop.id}-description-${item.id}`"
                    v-model="item.description"
                    permission="update_stops"
                    name="description"
                    :scope="stop.id"
                    label="Commodity Description"
                    :disabled="loadDisabled"
                    :url="route('stops.lineItems.update', item)"
                    class="col-span-2" />
                <div>
                    <AppButton
                        icon="far fa-trash-can"
                        variant="outline"
                        class="inline-block transition hover:bg-iel-light-gray"
                        permission="update_stops"
                        :disabled="loadDisabled"
                        @click="deleteLineItem(item)">
                        {{ $t('Delete') }}
                    </AppButton>
                </div>
            </li>
        </ul>
        <div v-else>{{ $t('No line items for this stop') }}</div>
        <AppButton
            :id="`load-${load.id}-stop-${stop.id}-add-new-line-item-button`"
            icon="fal fa-pen"
            class="mt-2 transition hover:bg-white hover:text-iel-blue"
            permission="update_stops"
            :disabled="loadDisabled"
            @click="debouncedAddLineItem">
            {{ $t('New Line Item') }}
        </AppButton>
    </section>
</template>

<script setup>
import { usePage, router, useForm } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { useLoadDisabled } from '@/helpers';
import { debounce } from 'lodash-es';

const props = defineProps({
    stop: Object
});

const { t } = useI18n();

const page = usePage();

const load = computed(() => page.props.load);

const url = route('stops.update', props.stop);

const ratecon = useRateCon(load);

const confirmation = ratecon.confirmChange;

const milesToNextStopError = computed(() => {
    if (props.stop.miles_to_next_stop) return null;
    const { address } = props.stop.location;
    const stops = page.props.load.stops;
    if (stops[stops.length - 1].id === props.stop.id) {
        return address.validated_at ? null : 'We are unable to calculate because this stop has an invalid address.';
    } else {
        return `We are unable to calculate because ${
            address.validated_at ? 'one of the other stops' : 'this stop'
        } has an invalid address.`;
    }
});

const fullAddress = computed(() => {
    const { address } = props.stop.location;
    const address2 = address.address_2 ? `, ${address.address_2}` : '';
    return `${address.address_1}${address2}, ${address.city}, ${address.region} ${address.postal_code}`;
});

function deleteStop(stop) {
    const message = ratecon.isSent.value ? ratecon.confirmChangeMessage : t('Delete stop?');

    if (confirm(message)) {
        // TODO change reload call to `only: ['stops']` if stops are exposed as their own key in the response
        router.delete(route('stops.destroy', stop), {
            preserveScroll: true,
            only: ['load']
        });
    }
}

function deleteLineItem(lineItem) {
    if (confirm('Delete Line Item?')) {
        router.delete(route('stops.lineItems.destroy', lineItem.id), {
            preserveScroll: true
        });
    }
}

function addLineItem() {
    router.post(route('stops.lineItems.store', props.stop), null, {
        preserveScroll: true
    });
}

const debouncedAddLineItem = debounce(addLineItem, 300);

const lastSubmittedReferenceField = ref();

const form = useForm({
    references:
        props.stop.references?.length > 0 ? props.stop.references : [{ number: null, description: null, other: null }]
});

provide('form', form);

function submitReferences(field) {
    lastSubmittedReferenceField.value = field;
    form.patch(url, {
        preserveScroll: true
    });
}

function removeReference(i) {
    form.references.splice(i, 1);
    submitReferences();
}

function addReference() {
    form.references.push({
        number: null,
        description: null,
        other: null
    });
}

const loadDisabled = useLoadDisabled(page.props.load);

const trackingStatusColor = computed(() => ({
    'text-iel-green': load.value.nextStop?.status === 'On Time',
    'text-iel-yellow': load.value.nextStop?.status === 'Behind',
    'text-iel-red': load.value.nextStop?.status !== 'On Time' && load.value.nextStop?.status !== 'Behind'
}));
</script>
