<template>
    <AppModal
        id="book-carrier-modal"
        :show="isOpen"
        class="max-w-2xl"
        @close="close"
        @closed="onClosed">
        <div class="items-center, flex justify-between">
            <h1 class="text-xl font-bold text-iel-blue">{{ $t('Book Carrier') }}</h1>
            <AppButton
                v-if="context.task && (context.task.past_due || context.task.due_soon)"
                size="md"
                variant="invisible"
                @click="snoozeTask(context)">
                {{ $t('Snooze') }}
                <AppIcon
                    name="fas fa-alarm-snooze"
                    class="ml-2" />
            </AppButton>
        </div>
        <form
            class="contents"
            @submit.prevent="save">
            <AppAutocomplete
                v-model="form.carrier_id"
                v-model:selected-option="selectedCarrier"
                name="carrier_id"
                label="Carrier"
                :initial-options="
                    context.load.carrier
                        ? [
                              {
                                  value: context.load.carrier.id,
                                  display: context.load.carrier.label
                              }
                          ]
                        : []
                "
                :get-options-using="getCarrierOptions"
                :placeholder="$t('Search By MC or DOT Number')"
                :hide-clear="!!context.load.carrier"
                :error="form.errors.q"
                disabled-options-class="text-iel-dark-red"
                disabled-options-field="is_active"
                disabled-options-label="(inactive)" />
            <AppMacropointPartnerStatus
                v-if="carrier"
                :carrier="carrier" />
            <div class="flex justify-end gap-2 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton
                    type="submit"
                    :disabled="!form.carrier_id">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import axios from 'axios';
import { useForm, usePage } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('LoadCarrierBookModal');

const form = useForm({
    carrier_id: null
});

const selectedCarrier = ref(null);

const carrier = computed(() => {
    return selectedCarrier.value ?? context.value.load?.carrier;
});

const page = usePage();

provide('form', form);

const load = computed(() => page.props.load);
const ratecon = useRateCon(load);

watch(isOpen, value => {
    // Reset form and clear errors whenever modal is opened or closed
    form.reset();
    form.clearErrors();
    if (value && context.value.load?.carrier) {
        form.carrier_id = context.value.load.carrier.id;
    }
});

async function getCarrierOptions(q) {
    if (!q) {
        return [];
    }

    try {
        return (await axios.get(route('carriers.autocomplete'), { params: { q } })).data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            form.setError('q', error.response.data.errors.q[0]);
            return [];
        } else {
            form.setError('q', t('An error occurred while fetching carriers.'));
            return [];
        }
    }
}

function save() {
    if (ratecon.isSent.value && !confirm(ratecon.confirmChangeMessage)) {
        return;
    }

    form.post(route('loads.carrier.store', context.value.load), {
        preserveScroll: true,
        onSuccess() {
            close();
        }
    });
}

const { open: openLoadSnoozeModal } = useModal('LoadSnoozeModal');

function snoozeTask(task) {
    openLoadSnoozeModal(
        {
            task
        },
        close()
    );
}
</script>
