<template>
    <AppModal
        id="customer-insurance-request-modal"
        :show="isOpen"
        :title="context.title ?? 'Customer Insurance Request'"
        class="max-w-xl"
        @close="close"
        @closed="onClosed">
        <form
            class="contents"
            @submit.prevent="handleSubmit">
            <AppInput
                id="customer-name"
                v-model="form.name"
                label="Customer Name"
                name="customer-name"
                required />
            <div class="grid grid-cols-2 gap-2 gap-x-2 xl:grid">
                <div class="col-span-2 space-y-1">
                    <AppAutocomplete
                        v-model="form.address"
                        class="xl:col-span-3"
                        :name="`customer-${customerId}-address-1`"
                        label="Address 1"
                        :options-url="route('addresses.autocomplete')"
                        :placeholder="$t('Search for an address...')"
                        :initial-value="form.address.address_1"
                        required />
                    <AppInput
                        :id="`customer-${customerId}-address-2`"
                        v-model="form.address.address_2"
                        class="xl:col-span-3"
                        :name="`customer-${customerId}-address-2`"
                        label="Address 2" />
                </div>
                <div class="col-span-2 grid gap-2 md:grid-cols-2">
                    <AppInput
                        :id="`customer-${customerId}-city`"
                        v-model="form.address.city"
                        class="xl:col-span-1"
                        :name="`customer-${customerId}-city`"
                        label="City"
                        required />
                    <AppInput
                        :id="`customer-${customerId}-region`"
                        v-model="form.address.region"
                        class="xl:col-span-1"
                        :name="`customer-${customerId}-region`"
                        label="State/Province"
                        required />
                    <AppInput
                        :id="`customer-${customerId}-postal-code`"
                        v-model="form.address.postal_code"
                        class="xl:col-span-1"
                        name="address.postal_code"
                        label="Postal Code"
                        required />
                    <AppSelect
                        :id="`customer-${customerId}-country`"
                        v-model="form.address.country"
                        class="xl:col-span-1"
                        :name="`customer-${customerId}-country`"
                        label="Country"
                        required
                        :options="{ CA: 'CA', MX: 'MX', US: 'US' }" />
                </div>
            </div>
            <div
                required>
                <AppLabel
                    :label="$t('Insurance Types')"
                    required />
                <AppCheckbox
                    v-model="form.certification_holder"
                    name="certification_holder"
                    label="Certification Holder"
                    @change="toggleInsuranceType('certification_holder')" />
                <AppCheckbox
                    v-model="form.additional_insured_with_contract"
                    name="additional_insured_with_contract"
                    label="Additionally Insured with Contract"
                    @change="toggleInsuranceType('additional_insured_with_contract')" />
                <AppCheckbox
                    v-model="form.additional_insured_without_contract"
                    name="additional_insured_without_contract"
                    label="Additionally Insured without Contract"
                    @change="toggleInsuranceType('additional_insured_without_contract')" />
                <AppCheckbox
                    v-model="form.other"
                    name="other"
                    label="Other"
                    @change="toggleInsuranceType('other')" />
            </div>
            <AppTextarea
                id="special-instructions"
                v-model="form.special_instructions"
                label="Special Instructions"
                name="special-instructions" />
            <div v-if="form.hasErrors">
                <ul>
                    <li
                        v-for="(message, index) in form.errors"
                        :key="index"
                        class="text-iel-red">
                        {{ message }}
                    </li>
                </ul>
            </div>
            <div class="flex justify-end gap-4 pt-2">
                <AppButton
                    variant="outline"
                    @click="close">
                    {{ $t('Cancel') }}
                </AppButton>
                <AppButton type="submit">
                    {{ $t('Save') }}
                </AppButton>
            </div>
        </form>
    </AppModal>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';

const { isOpen, close, onClosed, context } = useModal('CustomerInsuranceRequestModal');

const form = useForm({
    type: 'Customer Insurance',
    name: null,
    address: {
        address_1: null,
        address_2: null,
        city: null,
        region: null,
        postal_code: null,
        country: null
    },
    insurance_types: [],
    certification_holder: false,
    additional_insured_with_contract: false,
    additional_insured_without_contract: false,
    other: false,
    special_instructions: null
});

let customerId;

provide('form', form);

watch(isOpen, value => {
    form.reset();
    form.clearErrors();

    if (!value) {
        form.insurance_types = [];
        form.certification_holder = false;
        form.additional_insured_with_contract = false;
        form.additional_insured_without_contract = false;
        form.other = false;
        return;
    }

    customerId = context.value.customer.id;
    form.name = context.value.customer.name;
    form.address = context.value.address || {};
    form.insurance_types = [];
    form.special_instructions = null;

    // Set checkbox states based on insurance_types
    form.certification_holder = form.insurance_types.includes('certification_holder');
    form.additional_insured_with_contract = form.insurance_types.includes('additional_insured_with_contract');
    form.additional_insured_without_contract = form.insurance_types.includes('additional_insured_without_contract');
    form.other = form.insurance_types.includes('other');
});

function toggleInsuranceType(type) {
    if (form.insurance_types.includes(type)) {
        form.insurance_types = form.insurance_types.filter(t => t !== type);
    } else {
        form.insurance_types.push(type);
    }
}

async function handleSubmit() {
    form.post(
        route('customers.customerTicket.store', {
            customer: customerId
        }),
        {
            preserveScroll: true,
            onSuccess() {
                close();
            }
        }
    );
}
</script>
