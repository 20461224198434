<template>
    <div
        v-if="isOpen"
        id="main-navigation-backdrop"
        class="fixed inset-0 z-40 bg-black/40"
        @click="$emit('close')" />
    <div
        id="main-navigation"
        class="fixed inset-0 z-50 flex -translate-x-full flex-col bg-iel-blue px-1.5 pb-4 transition-transform xl:sticky xl:max-h-screen xl:translate-x-0"
        :class="{ 'translate-x-0': isOpen, 'w-44': !isCollapsed }">
        <div
            class="ml-2.5 mt-1 flex h-16 items-center"
            :class="{ 'w-8': isCollapsed }">
            <Logo
                class="h-8"
                alt="IEL Nexus" />
        </div>
        <div
            class="mt-4 flex flex-col gap-1"
            role="navigation">
            <Component
                :is="item.external ? 'a' : 'Link'"
                v-for="item in navigation"
                :id="`main-navigation-${item.id}`"
                :key="item.id"
                :target="item.target"
                :href="item.href"
                class="flex h-9 items-center justify-start gap-4 rounded-md px-4 font-medium hover:bg-iel-lightest-gray/10 hover:text-white"
                :class="{ 'bg-iel-lightest-gray/20 text-white': item.current, 'text-white/70': !item.current }"
                @click="$emit('close')">
                <AppIcon
                    :name="item.icon"
                    class="text-current w-5 text-lg" />
                <span v-if="!isCollapsed">{{ item.name }}</span>
            </Component>
        </div>
        <div class="mb-16 mt-auto">
            <button
                id="main-navigation-collapse"
                class="hidden h-9 items-center gap-3 px-4 font-medium text-white/70 hover:text-white xl:flex"
                @click="isCollapsed = !isCollapsed">
                <div
                    class="flex items-center justify-center transition-transform"
                    :class="{ 'rotate-180': isCollapsed }">
                    <AppIcon name="far fa-chevrons-left" />
                </div>
                <span v-if="!isCollapsed">Collapse</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import Logo from '/resources/img/logo.svg';
import { usePage } from '@inertiajs/vue3';
import { useScrollLock } from '@vueuse/core';

const isLocked = useScrollLock(document.body);

const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false
    }
});

defineEmits(['close']);

const page = usePage();
const isCollapsed = ref(false);

const route = inject('route');

const navigation = computed(() => [
    {
        name: 'Dashboard',
        id: 'dashboard',
        href: route('dashboard'),
        icon: 'fal fa-house-chimney',
        current: page.component === 'Dashboard'
    },
    {
        name: 'Loadboard',
        id: 'loadboard',
        href: route('loads.index'),
        icon: 'fal fa-gauge-max',
        current: page.component?.startsWith('Loads')
    },
    {
        name: 'Flagged',
        id: 'flagged',
        href: route('flags.index'),
        icon: 'fal fa-flag',
        current: page.component?.startsWith('Flagged')
    },
    {
        name: 'Requests',
        id: 'requests',
        href: route('tickets.index'),
        icon: 'fal fa-clipboard-list',
        current: page.component?.startsWith('Requests')
    },
    {
        name: 'Customers',
        id: 'customers',
        href: route('customers.index'),
        icon: 'fal fa-users',
        current: page.component?.startsWith('Customers')
    },
    {
        name: 'Carriers',
        id: 'carriers',
        href: route('carriers.index'),
        icon: 'fal fa-truck',
        current: page.component?.startsWith('Carriers')
    },
    {
        name: 'Locations',
        id: 'locations',
        href: route('locations.index'),
        icon: 'fal fa-location-dot',
        current: page.component?.startsWith('Locations')
    },
    {
        name: 'Reports',
        id: 'reports',
        href: route('reports.index'),
        icon: 'fal fa-file',
        current: page.component?.startsWith('Reports')
    },
    {
        name: 'Help Desk',
        id: 'help desk',
        href: 'https://intxlog.service-now.com/sp',
        external: true,
        icon: 'fal fa-circle-question',
        current: false,
        target: '_blank'
    },
    {
        name: 'Settings',
        id: 'settings',
        href: route('profile.index'),
        icon: 'fal fa-gear',
        current: page.component?.startsWith('Profile')
    },
    {
        name: 'Roles',
        id: 'roles',
        href: route('roles.index'),
        icon: 'fal fa-id-card-clip',
        current: page.component?.startsWith('Roles')
    },
    {
        name: 'Users',
        id: 'users',
        href: route('users.index'),
        icon: 'fal fa-sitemap',
        current: page.component?.startsWith('Users')
    },
    {
        name: 'Feedback',
        id: 'feedback',
        href: route('feedback.index'),
        icon: 'fal fa-comment-pen',
        current: page.component?.startsWith('Feedback')
    }
]);

watch(
    () => props.isOpen,
    value => {
        isLocked.value = value;
    }
);
</script>
