<template>
    <div
        ref="dropdown"
        class="relative"
        aria-haspopup="true"
        :aria-expanded="open"
        role="button"
        @click="toggleDropdown">
        <slot name="trigger" />
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0">
            <div
                v-show="open"
                :class="['absolute z-50 rounded-md shadow-lg', computedAlignment, contentWidth]"
                role="menu"
                :inert="!open"
                @click="handleContentClick">
                <div class="w-full rounded-md bg-white ring-1 ring-black ring-opacity-5">
                    <slot name="content" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { router } from '@inertiajs/vue3';

// Dropdown alignment positions
const alignments = {
    'top-right': 'origin-top-right right-0 bottom-8',
    'bottom-right': 'origin-bottom-right top-8 right-0',
    'top-left': 'origin-top-left left-0 bottom-8',
    'bottom-left': 'origin-bottom-left top-8 left-0'
};

// Props for alignment and preventCloseOnContentClick
const props = defineProps({
    align: { type: String, default: 'top-right' },
    preventCloseOnContentClick: { type: Boolean, default: false },
    contentWidth: { type: String, default: 'w-60', required: false }
});

// Computed alignment based on prop
const computedAlignment = computed(() => alignments[props.align] || alignments['top-right']);

const open = ref(false);
const dropdown = ref(null);

// Toggle dropdown and emit global event to notify other dropdowns
const toggleDropdown = e => {
    e.stopPropagation();

    if (!open.value) {
        // Notify other dropdowns to close
        window.dispatchEvent(new CustomEvent('close-all-dropdowns', { detail: dropdown.value }));
    }

    open.value = !open.value;
};

// Handle clicks inside the dropdown content, preventing the dropdown from closing
const handleContentClick = e => {
    if (props.preventCloseOnContentClick) {
        e.stopPropagation();
    }
};

// Handle clicks outside the dropdown to close it
const handleOutsideClick = e => {
    if (open.value && !dropdown.value.contains(e.target)) {
        open.value = false;
    }
};

// Close this dropdown if a custom event is triggered and it's not the current dropdown
const closeDropdown = e => {
    const clickedDropdown = e.detail;
    if (clickedDropdown !== dropdown.value) {
        open.value = false;
    }
};

// Handle Escape key to close the dropdown
const handleKeydown = e => {
    if (open.value && e.key === 'Escape') {
        open.value = false;
    }
};

onMounted(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('keydown', handleKeydown);

    // Listen for the custom event to close the dropdown
    window.addEventListener('close-all-dropdowns', closeDropdown);

    router.on('navigate', () => {
        open.value = false;
    });
});

onUnmounted(() => {
    document.removeEventListener('click', handleOutsideClick);
    document.removeEventListener('keydown', handleKeydown);

    // Remove the custom event listener
    window.removeEventListener('close-all-dropdowns', closeDropdown);
});
</script>
