<template>
    <AppCard
        title="External"
        theme="light_gray"
        content-class="p-2">
        <div class="grid gap-4">
            <div
                v-if="isInherited"
                class="border-y border-iel-light-blue bg-iel-lightest-blue px-4 py-3 text-iel-blue">
                <p class="uppercase">
                    {{ $t('Inherited from group') }}
                </p>
            </div>
            <AppSelect
                name="map_service_version"
                label="PC Miler Version"
                permission="update_external"
                :model-value="customer.map_service_version"
                :url="url"
                :options="page.props.mapServiceVersions"
                :disabled="isInherited" />
            <AppInput
                name="macropoint_id"
                label="Macropoint ID"
                permission="update_external"
                :model-value="customer.macropoint_id"
                :url="url"
                :disabled="isInherited" />
            <CustomerTrackingForm
                :customer="customer"
                url="customers.tracking-service" />
        </div>
    </AppCard>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

const customer = computed(() => page.props.customer);

const isInherited = computed(() => !!customer.value.customer_group?.id);

const url = route('customers.services.update', page.props.customer);
</script>
