<template>
    <div
        v-if="tasksByPhase[step.phase]"
        class="small-scrollbar flex items-center gap-2 overflow-x-auto overflow-y-hidden whitespace-nowrap border-b border-iel-light-gray bg-white px-4 pt-2 text-sm">
        <div
            v-for="(task, index) in tasksByPhase[step.phase]"
            :key="task.id"
            class="-mb-px flex cursor-pointer items-center gap-2"
            @click="selectStep(step.phase + '.' + task.name)">
            <div
                class="flex items-center gap-2 border-b-4"
                :class="[selectedTask === task.name ? 'border-iel-blue' : 'border-transparent']">
                <div class="flex h-5 w-5 items-center justify-center rounded-full border border-iel-blue">
                    <AppIcon
                        v-if="task.is_complete"
                        name="fa fa-circle-check"
                        class="text-iel-blue" />
                </div>
                <div class="py-2 capitalize">
                    {{ task.title }}
                </div>
            </div>
            <div
                v-if="index < tasksByPhase[step.phase].length - 1"
                class="w-12 border-t border-iel-light-gray" />
        </div>
    </div>
</template>

<script setup>
import { get } from 'lodash-es';

const props = defineProps({
    load: Object
});

const { tasksByPhase } = useTasks(toRef(props, 'load'));

const step = useLoadStepsStore(props.load.id).step;

const selectedTask = computed(() => {
    if (step.value.task) return step.value.task;
    return get(tasksByPhase.value[step.value.phase], '0.name');
});

function selectStep(key) {
    localStorage.setItem(`load_${props.load.id}_step_${step.value.phase}`, key);
    useLoadStepsStore(props.load.id).setStep(key);
}
</script>
