<template>
    <div class="flex items-center gap-4">
        <AppCard
            v-for="box in boxes"
            :key="box.title"
            :title="box.title"
            content-class="p-4 grid grid-cols-2 gap-4 text-sm whitespace-nowrap">
            <div
                v-for="cell in box.cells"
                :key="cell.label"
                class="w-1/2">
                <div>
                    <strong>{{ cell.label }}</strong>
                </div>
                <div class="capitalize">{{ cell.value }}</div>
            </div>
        </AppCard>
    </div>
</template>

<script setup>
import { formatMoney } from '@/helpers';

const props = defineProps({
    load: Object
});

const loadStats = useLoadStats(props.load);

const boxes = computed(() => {
    return [
        {
            title: 'Billing Details',
            cells: [
                { label: 'Margin', value: formatMoney(loadStats.margin.value) },
                { label: 'Profit', value: formatMoney(loadStats.profit.value) },
                { label: 'Billing Lines', value: formatMoney(loadStats.customerBilling.value) },
                { label: 'Accesorials', value: formatMoney(loadStats.customerAccesorials.value) },
                { label: 'Total Bill', value: formatMoney(loadStats.customerBillingTotal.value) }
            ]
        },
        {
            title: 'Carrier Pay Details',
            cells: [
                { label: 'Pay Truck', value: formatMoney(loadStats.carrierPay.value) },
                { label: 'Accesorials', value: formatMoney(loadStats.carrierAccesorials.value) },
                { label: 'Advances', value: formatMoney(loadStats.carrierAdvances.value) },
                { label: 'Total Pay Truck', value: formatMoney(loadStats.carrierTotalPay.value) },
                { label: 'Total 3rd Party', value: formatMoney(loadStats.carrierAdvancesThirdParty.value) }
            ]
        },
        {
            title: 'Load Details',
            cells: [
                {
                    label: 'Equipment',
                    value: props.load.required_equipment_type
                        ? [props.load.equipment_length + "'", props.load.required_equipment_type].join(' ')
                        : '-'
                },
                {
                    label: 'Temp',
                    value: props.load.temperature
                        ? [props.load.temperature, props.load.temperature_units?.toUpperCase()].join(' ')
                        : '-'
                },
                { label: 'Total Trip Miles', value: props.load.trip_miles ?? '-' },
                { label: 'Commodity Type', value: props.load.commodity_type ?? '-' },
                {
                    label: 'Pallets/Pieces',
                    value: `${props.load.total_pallets ?? '-'}/${props.load.total_pieces ?? '-'}`
                },
                { label: 'Total Load Weight', value: props.load.total_load_weight ?? '-' }
            ]
        }
    ];
});
</script>
