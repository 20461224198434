<template>
    <div :class="request.type === 'Customer Activation' || request.type === 'Customer Insurance' ? 'grid grid-cols-3 gap-2' : 'grid grid-cols-1 gap-2'">
        <CustomerHeader
            :request="request"
            :class="request.type === 'Customer Activation' || request.type === 'Customer Insurance' ? 'col-span-3 -mx-2 -mt-2' : 'col-span-2 -mx-2 -mt-2'"
            @success="$emit('success')" />
        <div
            :class="
                request.type === 'Customer Activation' || request.type === 'Customer Insurance'
                    ? 'col-span-3 grid grid-cols-3 gap-2 divide-x'
                    : 'col-span-2 grid grid-cols-3 gap-2 divide-x'
            ">
            <div>
                <CustomerSection
                    :request="request"
                    @success="$emit('success')" />
            </div>
            <div
                v-if="request.type === 'Customer Activation' || request.type === 'Customer Insurance'"
                class="pl-2">
                <RequestsDocs
                    :request="request"
                    :permissions="{
                        upload: 'upload_customer_activation_request_docs',
                        edit: 'update_customer_activation_request_docs',
                        delete: 'delete_customer_activation_request_docs'
                    }"
                    @success="$emit('success')" />
            </div>
            <div :class="request.type === 'Customer Activation' || request.type === 'Customer Insurance' ? 'pl-2' : 'col-span-2 pl-2'">
                <RequestNotes
                    :request="request"
                    :permissions="{ create: createNotePermission }"
                    @success="$emit('success')" />
            </div>
        </div>
        <div
            v-if="request.type === 'Credit Increase'"
            class="col-span-2 pl-2">
            <CustomerCreditSection
                :key="request.completed_at"
                :request="request"
                @success="$emit('success')" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    request: Object
});

const createNotePermission = computed(() => {
    if (props.request.type === 'Customer Activation') {
        return 'create_customer_activation_request_note';
    } else if (props.request.type === 'Credit Increase') {
        return 'create_customer_credit_request_note';
    }
});

defineEmits(['success']);
</script>
