<template>
    <Head title="Customer/Carrier Requests" />

    <div class="flex min-h-screen">
        <!-- Sidebar -->
        <AppSidebar
            v-bind="{ isOpen: isMobileMenuOpen }"
            @close="isMobileMenuOpen = false" />

        <!-- Main -->
        <div class="flex max-h-screen flex-1 flex-col gap-2 overflow-hidden bg-iel-silver">
            <AppTopbar @open-mobile-menu="isMobileMenuOpen = true" />

            <AppHeader
                title="Customer/Carrier Requests"
                :icon-component="IconRequest">
                <template #search>
                    <AppSearchInput
                        id="requests-loads-list-search"
                        v-model="q"
                        placeholder="Name, Broker, PO#, DOT#" />
                </template>
                <template #actions>
                    <AppFilterPills
                        id="requests-loads-list-filters"
                        class="lg:ml-auto"
                        :filters="{
                            default: { label: 'All' },
                            open: { label: 'Open' },
                            closed: { label: 'Closed' }
                        }"
                        :link-params="{ only: ['tickets'] }"
                        :reset-pagination="true" />
                    <AppFilterDropdown
                        align="bottom-right"
                        width="64">
                        <template #trigger>
                            <button
                                id="topbar-user-dropdown-trigger"
                                class="flex items-center rounded-full border border-iel-blue px-2"
                                type="button">
                                <div
                                    class="ml-3 text-sm font-medium capitalize leading-6 text-iel-darkest-gray xl:block">
                                    {{ $t('Filters') }}
                                </div>
                                <AppIcon
                                    name="far fa-chevron-down"
                                    class="ml-2 h-4 w-4" />
                            </button>
                        </template>
                        <template #content>
                            <div class="space-y-1 px-2 py-2 font-medium">
                                <AppCheckbox
                                    id="assigned-to-me"
                                    v-model="isMeChecked"
                                    name="assigned_to_me"
                                    @change="handleFilter('me', isMeChecked)" />
                                <AppCheckbox
                                    id="carrier-activation"
                                    v-model="isCarrierActivationChecked"
                                    name="carrier_activation"
                                    @change="handleFilter('carrier-activation', isCarrierActivationChecked)" />
                                <AppCheckbox
                                    id="carrier-insurance"
                                    v-model="isCarrierInsuranceChecked"
                                    name="carrier_insurance"
                                    @change="handleFilter('carrier-insurance', isCarrierInsuranceChecked)" />
                                <AppCheckbox
                                    id="carrier-advance-terms"
                                    v-model="isCarrierAdvanceTermsChecked"
                                    name="carrier_advance_terms"
                                    @change="handleFilter('carrier-advance-terms', isCarrierAdvanceTermsChecked)" />
                                <AppCheckbox
                                    id="customer-activation"
                                    v-model="isCustomerActivationChecked"
                                    name="customer_activation"
                                    @change="handleFilter('customer-activation', isCustomerActivationChecked)" />
                                <AppCheckbox
                                    id="customer-credit-increase"
                                    v-model="isCustomerCreditIncreaseChecked"
                                    name="customer_credit_increase"
                                    @change="
                                        handleFilter('customer-credit-increase', isCustomerCreditIncreaseChecked)
                                    " />
                                <AppCheckbox
                                    id="add-customer-to-group"
                                    v-model="isCustomerGroupAddExistingChecked"
                                    name="add_customer_to_group"
                                    @change="
                                        handleFilter('add-customer-to-group', isCustomerGroupAddExistingChecked)
                                    " />
                                <AppCheckbox
                                    id="customer-insurance"
                                    v-model="isCustomerInsuranceChecked"
                                    name="customer_insurance"
                                    @change="handleFilter('customer-insurance', isCustomerInsuranceChecked)" />
                                <AppCheckbox
                                    id="customer-contract"
                                    v-model="isCustomerContractChecked"
                                    name="customer_contract"
                                    @change="handleFilter('customer-contract', isCustomerContractChecked)" />
                            </div>
                        </template>
                    </AppFilterDropdown>
                    <AppButton
                        id="clear-filter-button"
                        @click="clearFilters()">
                        {{ $t('Clear Filters') }}
                    </AppButton>
                    <AppButton
                        id="clear-sorting-button"
                        @click="clearSorting()">
                        {{ $t('Clear Sorting') }}
                    </AppButton>
                </template>
            </AppHeader>
            <slot />
        </div>
    </div>

    <AppModalsCollection />
</template>

<script setup>
import { isMobileMenuOpen } from '@/stores';
import { router } from '@inertiajs/vue3';
import IconRequest from '/resources/img/icon-requests.svg';
import { onUpdated } from 'vue';

const isMeChecked = ref(false);
const isCarrierActivationChecked = ref(false);
const isCarrierInsuranceChecked = ref(false);
const isCarrierAdvanceTermsChecked = ref(false);
const isCustomerActivationChecked = ref(false);
const isCustomerCreditIncreaseChecked = ref(false);
const isCustomerGroupAddExistingChecked = ref(false);
const isCustomerInsuranceChecked = ref(false);
const isCustomerContractChecked = ref(false);
const { q } = useQueryParam({ only: ['tickets'] });

function pushHistoryState(params) {
    let url = new URL(window.location.href);
    url.search = params.toString();
    window.history.pushState({}, '', url);
}

function handleFilter(filterParam, value) {
    let params = new URLSearchParams(location.search);
    value ? params.set(filterParam, 'true') : params.delete(filterParam);

    let url = `${window.location.pathname}?${params.toString()}`;

    router.visit(url, { only: ['tickets'] });
}

function updateCheckboxes() {
    let urlSP = new URLSearchParams(location.search);

    isMeChecked.value = urlSP.has('me');
    isCarrierActivationChecked.value = urlSP.has('carrier-activation');
    isCarrierInsuranceChecked.value = urlSP.has('carrier-insurance');
    isCarrierAdvanceTermsChecked.value = urlSP.has('carrier-advance-terms');
    isCustomerActivationChecked.value = urlSP.has('customer-activation');
    isCustomerCreditIncreaseChecked.value = urlSP.has('customer-credit-increase');
    isCustomerGroupAddExistingChecked.value = urlSP.has('add-customer-to-group');
    isCustomerInsuranceChecked.value = urlSP.has('customer-insurance');
    isCustomerContractChecked.value = urlSP.has('customer-contract');
}

function clearFilters() {
    const params = new URLSearchParams(location.search);
    Array.from(params.keys()).forEach(key => params.delete(key));

    pushHistoryState(params);
    updateCheckboxes();

    router.reload({ only: ['tickets'] });
}

function clearSorting() {
    const params = new URLSearchParams(location.search);
    params.delete('orderBy');
    params.delete('orderByDir');
    const queryString = params.toString();
    router.visit(window.location.pathname + '?' + queryString, { only: ['tickets'] });
}

onMounted(() => {
    updateCheckboxes();
});

onUpdated(() => {
    updateCheckboxes();
});
</script>
