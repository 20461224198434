<template>
    <AppCard
        title="Details"
        theme="light_gray"
        content-class="p-2 grid w-full gap-2 md:grid-cols-2">
        <AppInput
            :id="`customer-group-${customerGroup.id}-name`"
            v-model="customerGroup.name"
            name="name"
            label="Name"
            :url="url" />
        <AppDisplayField
            :id="`customer-group-${customerGroup.id}-last-load-delivered-at`"
            label="Last Load Delivered"
            :value="customerGroup.last_load_delivered_at" />
    </AppCard>
</template>

<script setup>
const props = defineProps({
    customerGroup: Object
});

const url = route('customers.groups.update', props.customerGroup);
</script>
