<template>
    <div class="flex flex-1 overflow-hidden">
        <div
            class="flex-shrink-0 flex-grow-0 overflow-auto px-2"
            :class="{ 'w-1/2': selectedFlag, 'w-full': !selectedFlag }">
            <AppTablePill
                :columns="[
                    { name: 'flag_date', label: 'Flag Date', sortable: true },
                    { name: 'po_number', label: 'PO Number', sortable: true },
                    { name: 'model_type', label: 'Type', sortable: true },
                    { name: 'customer', label: 'Customer', sortable: true },
                    { name: 'carrier', label: 'Carrier', sortable: true },
                    { name: 'broker', label: 'Broker', sortable: true },
                    { name: 'sales_manager', label: 'Sales Manager', sortable: true },
                    { name: 'location', label: 'Location', sortable: true },
                    { name: 'follow_up_at', label: 'Follow Up Date', sortable: true },
                    { name: 'assignee', label: 'Assignee', sortable: true }
                ]"
                :data="flags.data"
                data-key="flags"
                :classes="{ row: 'cursor-pointer' }"
                :sticky-header="true"
                @row-click="selectFlag">
                <template #flag_date-cell="{ row }">
                    {{ row.flag_date }}
                </template>
                <template #po_number-cell="{ row }">
                    <a
                        v-if="row.po_number"
                        :href="route('loads.showByPO', row.po_number)"
                        target="_blank">
                        {{ row.po_number }}
                    </a>
                </template>
                <template #model_type-cell="{ row }">
                    {{ row.model_type }}
                </template>
                <template #customer-cell="{ row }">
                    {{ row.customer }}
                </template>
                <template #carrier-cell="{ row }">
                    {{ row.carrier }}
                </template>
                <template #broker-cell="{ row }">
                    {{ row.broker.name }}
                </template>
                <template #sales_manager-cell="{ row }">
                    {{ row.broker.manager_name }}
                </template>
                <template #location-cell="{ row }">
                    {{ row.broker.office_location }}
                </template>
                <template #follow_up_at-cell="{ row }">
                    {{ row.follow_up_at }}
                </template>
                <template #assignee-cell="{ row }">
                    <div class="h-8">
                        <template v-if="!row.assignee.id">
                            <AppButton
                                v-if="!row.completed_at"
                                :id="`report-${row.id}-assign-button`"
                                variant="primary"
                                size="sm"
                                class="cursor-pointer"
                                :permission="permissionToAssign(row.type)"
                                @click.stop="assign(row, page.props.auth.user.id)">
                                {{ $t('Assign To Me') }}
                            </AppButton>
                        </template>
                        <template v-else-if="row.assignee.id === page.props.auth.user.id">
                            <div class="flex h-7 pt-1">
                                <p>{{ row.assignee.name }}</p>
                                <AppIcon
                                    v-if="!row.completed_at"
                                    name="fal fa-circle-xmark"
                                    class="ml-2 h-5 w-5 cursor-pointer text-iel-dark-red"
                                    title="Click to unnassign yourself."
                                    @click.stop="assign(row, null)" />
                            </div>
                        </template>
                        <template v-else>
                            <div class="flex h-7 pt-1">
                                <p class="text-iel-gray">{{ row.assignee.name }}</p>
                            </div>
                        </template>
                    </div>
                </template>
            </AppTablePill>
        </div>
        <div
            v-if="selectedFlag"
            class="flex flex-1 flex-col border-l border-iel-light-gray bg-white">
            <div class="flex justify-between bg-iel-blue px-4 py-2 text-white">
                <div class="font-semibold uppercase">
                    <AppIcon name="fas fa-handshake" />
                    {{ selectedFlag.model_type }}
                </div>
                <AppButton
                    class="flex items-center text-lg text-white"
                    icon="far fa-circle-xmark"
                    icon-size="lg"
                    @click="deselectFlag" />
            </div>
            <div
                v-if="isFetching"
                class="flex flex-1 items-center justify-center">
                <AppIcon
                    name="fal fa-arrows-rotate"
                    class="size-10 animate-spin text-iel-gray" />
            </div>
            <div
                v-else-if="selectedFlagInfo"
                class="flex-1 overflow-auto">
                <div class="grid grid-cols-3 gap-1 border bg-iel-lightest-gray px-2 py-1 text-iel-blue">
                    <div>
                        <a
                            v-if="selectedFlagInfo.po_number"
                            :href="route('loads.showByPO', selectedFlagInfo.po_number)"
                            target="_blank">
                            #
                            <strong>{{ selectedFlagInfo.po_number }}</strong>
                        </a>
                    </div>
                    <div>
                        <span class="font-semibold">{{ $t('Carrier') }}:</span>
                        {{ selectedFlagInfo.carrier?.name ?? '-' }}
                    </div>
                    <div v-if="selectedFlag.broker">
                        <span class="font-semibold">{{ $t('Sales Manager') }}:</span>
                        {{ selectedFlag.broker.manager_name ?? '-' }}
                    </div>
                    <div>
                        <span class="font-semibold">{{ $t('Customer Name') }}:</span>
                        {{ selectedFlagInfo.customer?.name ?? '-' }}
                    </div>
                    <div>
                        <span class="font-semibold">{{ $t('Broker') }}:</span>
                        {{ selectedFlagInfo.owner ?? '-' }}
                    </div>
                    <div v-if="selectedFlag.broker">
                        <span class="font-semibold">{{ $t('Location') }}:</span>
                        {{ selectedFlag.broker.office_location ?? '-' }}
                    </div>
                </div>
                <div class="flex gap-2 p-4">
                    <FlaggedCarrierInfo
                        v-if="selectedFlag.model_type === 'Carrier Relations'"
                        :flag="selectedFlagInfo"
                        :assignee="selectedFlag.assignee"
                        @assign-to-me="assign(selectedFlag, page.props.auth.user.id)" />
                    <FlaggedClaimsInfo
                        v-if="selectedFlag.model_type === 'Claim'"
                        :flag="selectedFlagInfo"
                        :assignee="selectedFlag.assignee"
                        @assign-to-me="assign(selectedFlag, page.props.auth.user.id)" />
                    <FlaggedDocs
                        :load="selectedFlagInfo"
                        :permissions="{
                            upload: 'upload_load_documents',
                            edit: 'update_load_documents',
                            delete: 'delete_load_documents'
                        }"
                        @success="refreshSelectedFlagInfo" />
                    <FlaggedNotes
                        :load="selectedFlagInfo"
                        @success="refreshSelectedFlagInfo" />
                </div>
            </div>
        </div>
    </div>
    <AppPagination
        :meta="flags.meta"
        class="px-2 py-4" />
</template>

<script setup>
import { useFetch, useUrlSearchParams } from '@vueuse/core';
import FlagsLayout from '@/Layouts/FlagsLayout.vue';
import { usePage } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';

defineOptions({
    layout: [FlagsLayout]
});

const props = defineProps({
    flags: Object
});

const page = usePage();

const params = useUrlSearchParams('history');

const selectedFlag = computed(() =>
    props.flags.data.find(
        flag => flag.load.id == params.load && flag.model_id == params.flag && flag.model_type === params.type
    )
);

function selectFlag(row) {
    params.load = row.load.id;
    params.flag = row.model_id;
    params.type = row.model_type;
}

function deselectFlag() {
    params.load = null;
    params.flag = null;
    params.type = null;
}

const selectedFlagInfoUrl = ref(null);

const {
    isFetching,
    data: selectedFlagInfo,
    execute: refreshSelectedFlagInfo
} = useFetch(selectedFlagInfoUrl, { refetch: true, immediate: false }).json();

watch(
    params,
    () => {
        if (params.load && params.flag && params.type) {
            selectedFlagInfoUrl.value = `/loads/${params.load}/flag-info`;
        }
    },
    { immediate: true }
);

function assign(row, assignee = null) {
    const type = row.model_type === 'Claim' ? 'claims' : 'carrier-incident';
    router.patch(route(`loads.${type}.update`, [row.load.id, row.model_id]), { user_id: assignee });
}

function permissionToAssign(type) {
    const permissions = {
        'Carrier Relations': 'update_carrier_relations_record',
        Claims: 'update_claim_record'
    };

    return permissions[type];
}
</script>
