<template>
    <AppCard
        :id="getId('load-*-stops-list', [load])"
        title="Stops">
        <template #title>
            <button
                :id="getId('load-*-stops-shortcut', [load])"
                class="cursor-pointer px-4 py-2 text-lg font-semibold text-white underline"
                @click="goToSetupStops">
                Stops
            </button>
        </template>
        <template #nav>
            <div
                v-if="headerNav === 'TOGGLE_MAP_AND_ADD_STOP'"
                class="flex items-center gap-3">
                <div
                    :id="getId('load-*-stops-map-toggle', [load])"
                    class="flex items-center gap-2">
                    <span class="text-sm text-white">Show Map</span>
                    <AppToggle v-model="showMap" />
                </div>
                <AppButton
                    :id="getId('load-*-stops-add-button', [load])"
                    permission="update_stops"
                    @click="addStop">
                    Add Stop
                </AppButton>
            </div>

            <div
                v-if="headerNav === 'TOTAL_MILES'"
                :id="getId('load-*-stops-total-miles', [load])"
                class="mr-4 text-sm font-bold text-white">
                Total Miles:
                {{ load.trip_miles }}
            </div>
        </template>

        <!-- MAP -->
        <div
            v-if="showMap"
            class="p-6">
            <LoadMap :load="load" />
        </div>

        <!-- STOPS TABLE -->
        <div class="rounded-b">
            <table
                :id="getId('load-*-stops-table', [load])"
                class="w-full text-sm">
                <thead class="bg-iel-mercury">
                    <tr>
                        <th v-if="isSortable" />
                        <th
                            v-for="stopColumn in stopColumns"
                            :key="stopColumn.id"
                            class="p-2 text-left">
                            {{ stopColumn.field }}
                        </th>
                        <th v-if="hasDelete" />
                    </tr>
                </thead>
                <Draggable
                    v-model="stops"
                    group="people"
                    tag="tbody"
                    item-key="id"
                    handle="[data-handle]"
                    ghost-class="pending-drop-target"
                    @change="sortStops"
                    @start="selectedStop = null">
                    <template #item="{ element: stop }">
                        <tr
                            :id="getId('load-*-stops-row-*', [load, stop])"
                            class="border-t border-iel-mercury"
                            :class="{ 'cursor-pointer': !stop.open, hidden: stop.hidden }"
                            @click="!stop.open && selectStopId(stop.id)">
                            <template v-if="stop.open">
                                <td :colspan="stopColumns.length + 2">
                                    <div class="bg-iel-mercury p-3">
                                        <LoadStopForms v-bind="{ stop, loadId: load.id }" />
                                    </div>
                                </td>
                            </template>
                            <template v-else>
                                <td
                                    v-if="isSortable"
                                    class="p-2">
                                    <div
                                        :id="getId('load-*-stops-row-*-drag-handle', [load, stop])"
                                        data-handle
                                        class="cursor-grab">
                                        <AppIcon name="far fa-bars" />
                                    </div>
                                </td>
                                <td
                                    v-for="stopColumn in stopColumns"
                                    :id="getId('load-*-stops-row-*-cell-*', [load, stop, stopColumn])"
                                    :key="stopColumn.id"
                                    class="p-2">
                                    <div v-html="stop[stopColumn.id]" />
                                </td>
                                <td
                                    v-if="hasDelete"
                                    class="p-2">
                                    <button
                                        :id="getId('load-*-stops-row-*-delete-button', [load, stop])"
                                        class="hover:text-iel-red"
                                        @click.stop="deleteStop(stop.id)">
                                        <AppIcon name="far fa-trash-can" />
                                    </button>
                                </td>
                            </template>
                        </tr>
                    </template>
                </Draggable>
            </table>
            <button
                v-if="hidesRows && stops.length > 4"
                :id="getId('load-*-stops-toggle-hidden-rows-button', [load])"
                class="block w-full cursor-pointer border-t border-iel-mercury bg-iel-mercury py-2 text-center text-sm"
                @click="isListExpanded = !isListExpanded">
                <span v-if="hiddenStopsCount">Show {{ hiddenStopsCount }} more</span>
                <span v-else>Show less</span>
            </button>
        </div>
    </AppCard>
</template>

<script setup>
import Draggable from 'vuedraggable';
import { router } from '@inertiajs/vue3';
import { useI18n } from 'vue-i18n';
import { get } from 'lodash-es';
import { timezones as defaultTimezones } from '@/data';
import { formatStopDate, getId } from '@/helpers';

const { t } = useI18n();

const props = defineProps({
    load: Object,
    isSortable: {
        type: Boolean,
        default: true
    },
    hasDelete: {
        type: Boolean,
        default: true
    },
    headerNav: {
        type: String,
        default: 'TOGGLE_MAP_AND_ADD_STOP',
        validator: value => ['TOGGLE_MAP_AND_ADD_STOP', 'TOTAL_MILES'].includes(value)
    },
    displayColumns: {
        type: Array,
        default: () => ['type', 'location', 'time', 'appt', 'po', 'miles', 'mode', 'hours-operation', 'hours-dock']
    },
    hidesRows: {
        type: Boolean
    }
});

const load = computed(() => props.load);

const ratecon = useRateCon(load);

const showMap = ref(false);

const isListExpanded = ref(false);

const columns = ref([
    { field: 'Stop Type', id: 'type' },
    { field: 'Location', id: 'location' },
    { field: 'Date & Time', id: 'time' },
    { field: 'Appt #', id: 'appt' },
    { field: 'PO #', id: 'po' },
    { field: 'Next Stop', id: 'miles' },
    { field: 'FCFS/Appt', id: 'mode' },
    { field: 'Hours of Operation', id: 'hours-operation' },
    { field: 'Dock Hours', id: 'hours-dock' }
]);

const stopColumns = computed(() => columns.value.filter(column => props.displayColumns.includes(column.id)));

const selectedStopId = ref(null);

const selectedStop = computed(() => {
    if (!selectedStopId.value) return null;
    return props.load.stops.find(stop => stop.id === selectedStopId.value);
});

const stops = computed(() => {
    let rows = props.load.stops.map(stop => {
        const commodityTypeDetails = getLocationCommodityTypeDetails(props.load.commodity_type, stop);

        return {
            id: stop.id,
            type: stop.type === 'pickup' ? 'PU' : 'DEL',
            location: getLocationCell(stop),
            time: stop.has_stop_window
                ? formatStopDate(stop.stop_window_begins_at) +
                  ' - ' +
                  formatStopDate(stop.stop_window_ends_at) +
                  ' ' +
                  (defaultTimezones[stop.stop_window_ends_at_timezone] || '')
                : formatStopDate(stop.stop_window_begins_at),
            appt: stop.appointment_number,
            po: props.load.po_number,
            miles: stop.miles_to_next_stop + ' mi',
            mode: stop.has_stop_window ? 'FCFS' : 'Appt',
            'hours-operation': commodityTypeDetails.hours_of_operation,
            'hours-dock': commodityTypeDetails.dock_hours
        };
    });

    if (props.hidesRows && !isListExpanded.value && rows.length > 4) {
        rows = rows.map((stop, index) => ({ ...stop, hidden: index > 3 }));
    }

    if (!selectedStop.value) {
        return rows;
    }

    return rows.flatMap(stop =>
        stop.id === selectedStopId.value ? [stop, { ...selectedStop.value, open: true }] : [stop]
    );
});

const hiddenStopsCount = computed(() => stops.value.filter(stop => stop.hidden).length);

function selectStopId(stopId) {
    if (stopId === selectedStopId.value) return (selectedStopId.value = null);
    selectedStopId.value = stopId;
}

function addStop() {
    useModal('LoadStopCreateModal').open({
        load: props.load
    });
}

function sortStops({ moved }) {
    if (ratecon.isSent.value && !confirm(ratecon.confirmChangeMessage)) {
        return;
    }

    router.put(
        route('loads.stops.reorder', [props.load, moved.element.id]),
        {
            // Order numbers are 1-based
            toOrderNumber: moved.newIndex + 1
        },
        {
            preserveScroll: true,
            only: ['load']
        }
    );
}

function deleteStop(stop) {
    const message = ratecon.isSent.value ? ratecon.confirmChangeMessage : t('Delete stop?');

    if (confirm(message)) {
        router.delete(route('stops.destroy', stop), {
            preserveScroll: true,
            only: ['load']
        });
    }
}

function getLocationCell(stop) {
    return `
        <a href="${route('locations.show', stop.location)}" target="_blank" class="underline font-bold">${stop.location.name}</a>
        ${stop.location.address.city}, ${stop.location.address.region}
        ${stop.location.address.postal_code}
    `;
}

function getLocationCommodityTypeDetails(commodityType, stop) {
    const commodityTypeDetails = get(
        stop,
        ['location', stop.type === 'pickup' ? 'receivingDetails' : 'shippingDetails', 'commodityTypeDetails'],
        []
    );

    return commodityTypeDetails.find(item => item.commodity_type === commodityType) ?? {};
}

function goToSetupStops() {
    useLoadStepsStore(props.load.id).setStep('setup');
    setTimeout(() => {
        const element = document.getElementById('stops_panel');
        element.scrollIntoView({ behavior: 'smooth' });
    });
}
</script>

<style scoped>
.pending-drop-target {
    @apply bg-iel-light-blue;
}
.pending-drop-target > * {
    @apply opacity-0;
}
</style>
