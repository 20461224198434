<template>
    <AppCard
        title="Carrier Details"
        theme="gray"
        content-class="p-4">
        <template #nav>
            <div class="flex gap-2">
                <AppButton
                    :id="`${load.id}-manage-carrier-button`"
                    :disabled="loadDisabled"
                    permission="update_load_carrier_info"
                    @click="addCarrier">
                    {{ load.carrier ? $t('Edit Carrier') : $t('Book Carrier') }}
                </AppButton>
                <form @submit.prevent="confirmDelete">
                    <AppButton
                        v-if="load.carrier"
                        class="mr-auto bg-iel-dark-red text-white"
                        permission="update_load_carrier_info"
                        @click="confirmDelete">
                        {{ $t('Remove Carrier') }}
                    </AppButton>
                </form>
            </div>
        </template>

        <div v-if="!load.carrier">
            {{ $t('A carrier has not yet been booked for this load.') }}
        </div>
        <div v-else>
            <div class="mb-2 grid grid-cols-12 gap-2">
                <AppDisplayField
                    label="Carrier Name"
                    :value="load.carrier.name"
                    :href="route('carriers.show', load.carrier.id)"
                    class="col-span-4" />
                <AppDisplayField
                    label="MC Number"
                    :value="load.carrier.mc_number"
                    class="col-span-1" />
                <AppDisplayField
                    label="DOT Number"
                    :value="load.carrier.dot_number"
                    class="col-span-1" />
                <AppAutocomplete
                    name="tractor_number_id"
                    label="Tractor #"
                    :model-value="load.tractorNumber?.id"
                    permission="update_load_carrier_info"
                    :initial-options="
                        load.tractorNumber
                            ? [
                                  {
                                      value: load.tractorNumber.id,
                                      display: load.tractorNumber.identifier
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :options-url="route('carriers.equipment.autocomplete', load.carrier)"
                    :placeholder="$t('Select a Tractor Number...')"
                    :query-params="{ type: 'tractor' }"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    allow-adding
                    class="col-span-2"
                    @add="query => addEquipment(query, 'tractor')" />
                <AppAutocomplete
                    name="trailer_number_id"
                    label="Trailer #"
                    :model-value="load.trailerNumber?.id"
                    permission="update_load_carrier_info"
                    :initial-options="
                        load.trailerNumber
                            ? [
                                  {
                                      value: load.trailerNumber.id,
                                      display: load.trailerNumber.identifier
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :options-url="route('carriers.equipment.autocomplete', load.carrier)"
                    :placeholder="$t('Select a Trailer Number...')"
                    :query-params="{ type: 'trailer' }"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    allow-adding
                    class="col-span-2"
                    @add="query => addEquipment(query, 'trailer')" />
                <AppAutocomplete
                    name="plate_number_id"
                    label="Plate #"
                    :model-value="load.plateNumber?.id"
                    permission="update_load_carrier_info"
                    :initial-options="
                        load.plateNumber
                            ? [
                                  {
                                      value: load.plateNumber.id,
                                      display: load.plateNumber.identifier
                                  }
                              ]
                            : []
                    "
                    :url="url"
                    :options-url="load.carrier && route('carriers.equipment.autocomplete', load.carrier)"
                    :placeholder="$t('Select a Plate Number...')"
                    :query-params="{ type: 'plate' }"
                    :confirmation="confirmation"
                    :disabled="loadDisabled"
                    allow-adding
                    class="col-span-2"
                    @add="query => addEquipment(query, 'plate')" />
            </div>

            <div class="grid grid-cols-12 items-center gap-3">
                <div class="col-span-3 flex items-end">
                    <AppAutocomplete
                        name="dispatcher_id"
                        label="Dispatcher"
                        :model-value="load.dispatcher?.id"
                        permission="update_load_carrier_info"
                        :url="url"
                        :initial-options="
                            load.dispatcher
                                ? [
                                      {
                                          value: load.dispatcher.id,
                                          display: `${truncatedDispatcherName}  - ${load.dispatcher.phone_number}`
                                      }
                                  ]
                                : []
                        "
                        :options-url="load.carrier && route('carriers.contacts.autocomplete', load.carrier)"
                        :placeholder="$t('Select a dispatcher...')"
                        :query-params="{ role: 'dispatcher' }"
                        :confirmation="confirmation"
                        class="w-full"
                        :disabled="loadDisabled"
                        allow-adding
                        @add="query => addContact(query, 'dispatcher', 'dispatcher')" />
                    <AppButton
                        icon="fal fa-comment-sms"
                        icon-size="xl"
                        variant="invisible"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="scrollToText('Dispatcher')" />
                    <AppButton
                        v-if="load.dispatcher"
                        icon="fal fa-pen"
                        icon-size="lg"
                        variant="invisible"
                        class="mt-2"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="editContact(load.dispatcher, 'dispatcher')" />
                </div>
                <div class="col-span-3 flex items-end">
                    <AppAutocomplete
                        name="driver_id"
                        label="Driver"
                        :model-value="load.driver?.id"
                        permission="update_load_carrier_info"
                        :url="url"
                        :initial-options="
                            load.driver
                                ? [
                                      {
                                          value: load.driver.id,
                                          display: `${truncatedDriver1Name} - ${load.driver.phone_number}`
                                      }
                                  ]
                                : []
                        "
                        :options-url="load.carrier && route('carriers.contacts.autocomplete', load.carrier)"
                        :placeholder="$t('Select a driver...')"
                        :query-params="{ role: 'driver' }"
                        :confirmation="confirmation"
                        class="w-full"
                        :disabled="loadDisabled"
                        allow-adding
                        @add="query => addContact(query, 'driver', 'driver')" />
                    <AppButton
                        icon="fal fa-comment-sms"
                        icon-size="xl"
                        variant="invisible"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="scrollToText('Driver')" />

                    <AppButton
                        v-if="load.driver"
                        icon="fal fa-pen"
                        icon-size="lg"
                        variant="invisible"
                        class="mt-2"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="editContact(load.driver, 'driver')" />
                </div>

                <div class="col-span-6 flex items-end">
                    <AppCheckbox
                        label="Add Second Driver"
                        name="team_load"
                        :model-value="load.team_load"
                        permission="update_load_carrier_info"
                        :url="url"
                        :disabled="loadDisabled"
                        :confirmation="confirmation"
                        class="mb-1 ml-4 basis-1/2 justify-self-center" />
                    <AppAutocomplete
                        name="driver2_id"
                        label="Driver 2"
                        :model-value="load.driver2?.id"
                        permission="update_load_carrier_info"
                        :url="url"
                        :initial-options="
                            load.driver2
                                ? [
                                      {
                                          value: load.driver2.id,
                                          display: `${truncatedDriver2Name}  - ${load.driver2.phone_number}`
                                      }
                                  ]
                                : []
                        "
                        :options-url="load.carrier && route('carriers.contacts.autocomplete', load.carrier)"
                        :placeholder="$t('Select a driver...')"
                        :query-params="{ role: 'driver' }"
                        :confirmation="confirmation"
                        class="w-full"
                        :disabled="loadDisabled || !load.team_load"
                        allow-adding
                        :class="{ 'opacity-0': !load.team_load }"
                        @add="query => addContact(query, 'driver', 'driver2')" />
                    <AppButton
                        v-if="load.driver2?.phone_number"
                        class="self-end"
                        icon="fal fa-comment-sms"
                        icon-size="xl"
                        variant="invisible"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="scrollToText('Driver 2')" />
                    <AppButton
                        v-if="load.driver2"
                        icon="fal fa-pen"
                        icon-size="lg"
                        variant="invisible"
                        class="mt-2"
                        :disabled="loadDisabled"
                        permission="update_load_carrier_info"
                        @click="editContact(load.driver2, 'driver2')" />
                </div>
            </div>
        </div>
    </AppCard>
</template>

<script setup>
import { usePage, router, useForm } from '@inertiajs/vue3';
import { useLoadDisabled } from '@/helpers';
import { useRateCon } from '@/Composables/useRateCon';
import { useI18n } from 'vue-i18n';

defineProps({
    stop: Object
});

const form = useForm({
    carrier_id: null
});

provide('form', form);

const { t } = useI18n();

const page = usePage();
const load = computed(() => page.props.load);

const url = route('loads.carrier-info.update', page.props.load);

const ratecon = useRateCon(load);
const confirmation = ratecon.confirmChange;

const { open: carrierModal } = useModal('LoadCarrierBookModal');

function addCarrier() {
    carrierModal({
        mode: 'Create',
        title: 'Edit Carrier',
        load: load
    });
}

function addEquipment(query, equipmentType) {
    router.post(
        route('carrierEquipment.store', load.value.carrier),
        { identifier_type: equipmentType, identifier: query, load_id: load.value.id },
        { preserveScroll: true }
    );
}

const { open: openCarrierContactModal } = useModal('CarrierContactModal');

function addContact(query, role, relationship) {
    openCarrierContactModal({
        mode: 'create',
        carrier: load.value.carrier,
        query: query,
        role: role,
        load_id: load.value.id,
        load_relationship: relationship,
        onClose() {
            // TODO after creating a new contact, set the original field to that contact
        }
    });
}

function editContact(contact, relationship) {
    openCarrierContactModal({
        mode: 'edit',
        carrier: load.value.carrier,
        contact: contact,
        load_id: load.value.id,
        load_relationship: relationship
    });
}

function confirmDelete() {
    const message = ratecon.isSent.value
        ? ratecon.confirmChangeMessage
        : t('Are you sure you would like to remove this carrier?');

    if (confirm(message)) {
        router.delete(route('loads.carrier.destroy', page.props.load), {
            preserveScroll: true,
            onSuccess() {
                form.carrier_id = null;
                page.props.load.carrier = null;
                router.reload({ only: ['load'] });
            }
        });
    }
}

function truncateString(str) {
    const adjustedMaxLength = window.innerWidth >= 1024 ? 20 : 5;

    return str.length > adjustedMaxLength ? str.substring(0, adjustedMaxLength) + '...' : str;
}

const truncatedDriver1Name = computed(() => truncateString(load.value.driver?.name));
const truncatedDriver2Name = computed(() => truncateString(load.value.driver2?.name));
const truncatedDispatcherName = computed(() => truncateString(load.value.dispatcher?.name));

const emits = defineEmits(['startMessage']);

function scrollToText(tag) {
    emits('startMessage', tag);
    const element = document.getElementById('load-text-section');
    element.scrollIntoView({ behavior: 'smooth' });
}

const loadDisabled = useLoadDisabled(page.props.load);
</script>
