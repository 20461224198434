const store = reactive({});

export function useLoadStepsStore(id) {
    if (!store[id]) {
        setStep();
    }

    function setStep(key = 'summary') {
        const [phase, task] = key.split('.');
        store[id] = { phase, task, key };
    }

    const step = computed(() => {
        return store[id];
    });

    return {
        step,
        setStep
    };
}
